import React from 'react'
import Table from 'react-bootstrap/Table'
import SmallExemptBadge from 'students/badges/small-exempt-badge'
import { formatDate } from 'helpers/datetime'
import { displayGradeLevel, displayTerm } from 'helpers/presenters'
import { fetchAssessmentScores } from 'api/assessment-scores'
import { useQuery } from 'react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { audience } from 'api/helpers'
import LoadingStatus from 'helpers/loading-status'
import { riskColor } from 'helpers/risk-status'

function AssessmentTable({ assessment, student }) {
  const { getAccessTokenSilently } = useAuth0()
  const { isSuccess, isLoading, data: studentScores } = useQuery(
    `students/${student.id}/assessments`,
    async () => {
      const params = { studentId: student.id }
      const token = await getAccessTokenSilently({ audience })
      const data = await fetchAssessmentScores({ params, token })
      return data
    }
  )

  return (
    <Table className="my-2 mb-5 border bg-white shadow-sm">
      <thead>
        <tr>
          <th className="text-info">Assessment</th>
          <th className="text-info">Date</th>
          <th className="text-info text-center">Grade</th>
          <th className="text-info text-center">Percentile</th>
          <th className="text-info text-center">Score</th>
        </tr>
      </thead>
      <tbody>
        {isLoading && (
          <tr>
            <td colSpan={5} className="py-5">
              <LoadingStatus status={isLoading} />
            </td>
          </tr>
        )}
        {isSuccess && studentScores.length === 0 && (
          <tr>
            <td colSpan={5} className="py-5 text-center">
              No student scores to display
            </td>
          </tr>
        )}

        {isSuccess &&
          studentScores.map((s) => (
            <tr key={`assessment-score-${s.id}`}>
              <td>
                {s.assessment.name}
                <span className="ml-2">
                  {s.met_exemption ? <SmallExemptBadge /> : null}
                </span>
              </td>
              <td>
                {formatDate(s.date)}
                <small className="ml-1">{displayTerm(s.testing_window)}</small>
              </td>
              <td className="text-center">
                {displayGradeLevel(s.grade_level)}
              </td>
              <td className="text-center">{riskColor(s.percentile)}</td>
              <td className="text-center">{s.score}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  )
}

export default AssessmentTable
