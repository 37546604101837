import { audience, baseUrl } from 'api/helpers'

export async function fetchAcademicPlanConferences({ plan, token }) {
  const url = `${baseUrl}/academic_progress_plans/${plan.id}/conferences`
  const response = await fetch(url, {
    audience,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const data = await response.json()
  return data
}

export async function createAcademicPlanConference({ params, plan, token }) {
  const url = `${baseUrl}/academic_progress_plans/${plan.id}/conferences`
  const response = await fetch(url, {
    method: 'POST',
    audience,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({academic_plan_conference: params}),
  })
  const data = await response.json()
  return data
}

export async function updateAcademicPlanConference({ params, token }) {
  const url = `${baseUrl}/conferences/${params.id}`
  const response = await fetch(url, {
    method: 'PATCH',
    audience,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({academic_plan_conference: params}),
  })
  const data = await response.json()
  return data
}

export async function destroyAcademicPlanConference({ id, token }) {
  const url = `${baseUrl}/conferences/${id}`
  const response = await fetch(url, {
    method: 'DELETE',
    audience,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  const data = await response.json()
  return data
}
