export const pathwayText = (id, exemption_id) => {
  if (id === 1) {
    return 'Oklahoma State Testing Program (OSTP)'
  } else if (id === 2) {
    return 'Screening Assessments'
  } else if (id === 3) {
    if (exemption_id === 1) {
      return 'Good-cause exemption - EL Proficiency'
    } else if (exemption_id === 2) {
      return 'Good-cause exemption - Alternate Assessment'
    } else if (exemption_id === 3) {
      return 'Good-cause exemption - Alternative Standardized Test'
    } else if (exemption_id === 4) {
      return 'Good-cause exemption - Portfolio'
    } else if (exemption_id === 5) {
      return 'Good-cause exemption - IEP Goals'
    } else if (exemption_id === 6) {
      return 'Good-cause exemption - Previously Retained'
    } else if (exemption_id === 7) {
      return 'Good-cause exemption - Exceptional Emergency'
    } else {
      return 'Good-cause exemption - Specific exemption not selected'
    }
  } else if (id === 4) {
    return 'Probationally Promote'
  } else if (id === 5) {
    return 'Retain'
  } else {
    return 'Not Exempt'
  }
}
