import React from 'react'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import SrptBadgeComplete from 'shared/badges/srpt-complete'

function SrptMeeting({ student }) {
  return (
    <Card className="mt-4 shadow-sm">
      <Card.Body>
        <div className="text-info mb-2">
          <strong>SRPT Meetings</strong>
        </div>{' '}
        <Table responsive size="sm">
          <tbody>
            {student.meetings.map((m) => (
              <tr>
                <td>{m.date}</td>
                <td>
                  <SrptBadgeComplete student={student} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

export default SrptMeeting
