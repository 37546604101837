import React, { useState, useRef, useContext, useEffect } from 'react'
import Popover from 'react-bootstrap/Popover'
import Form from 'react-bootstrap/Form'
import ListGroup from 'react-bootstrap/ListGroup'
import Overlay from 'react-bootstrap/Overlay'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button'
import Fuse from 'fuse.js'
import UserContext from 'context/user-context'
import SchoolContext from 'context/school-context'
import { truncate } from 'lodash'

function SchoolSelect() {
  const currentUser = useContext(UserContext)
  const { currentSchool, setCurrentSchool } = useContext(SchoolContext)
  const [showSelect, setShowSelect] = useState(false)
  const [search, setSearch] = useState('')
  const target = useRef(null)
  const [schoolOptions, setSchoolOptions] = useState([])

  useEffect(() => {
    setSchoolOptions(currentUser.schools)
  }, [currentUser])

  const searchList = (value) => {
    if (value === '') {
      setSchoolOptions(schoolOptions)
    } else {
      const fuse = new Fuse(schoolOptions, {
        keys: ['id', 'name'],
        isCaseSensitive: false,
      })
      setSearch(value)
      const searchedData = fuse.search(value).map((r) => r.item)
      setSchoolOptions(searchedData)
    }
  }

  const selectSchool = (school) => {
    setCurrentSchool(school)
    setShowSelect(false)
    setSearch('')
    const currentUrl = window.location.pathname
    if (currentUrl === '/schools') {
      window.history.pushState(null, '', `${school.id}`)
    }
  }

  return (
    <>
      <Button
        className="float-right text-left mt-5 bg-white"
        variant="outline-primary"
        ref={target}
        onClick={() => setShowSelect(!showSelect)}
        block
      >
        <span className="text-primary">
          {truncate(currentSchool.name, { length: '26'})}
          <span className="ml-2 float-right">
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        </span>
      </Button>
      <Overlay target={target.current} show={showSelect} placement="bottom-end">
        <Popover
          id="popover-basic"
          style={{ maxHeight: 400, overflowY: 'auto' }}
        >
          <Popover.Title>
            <Form.Control
              type="text"
              placeholder="Search Schools"
              value={search}
              onChange={(e) => searchList(e.target.value)}
            />
          </Popover.Title>
          <ListGroup variant="flush">
            {schoolOptions.map((s) => (
              <ListGroup.Item
                action
                active={s.id === currentSchool.id ? true : false}
                onClick={() => selectSchool(s)}
                key={`school-${s.id}`}
              >
                {s.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Popover>
      </Overlay>
    </>
  )
}

export default SchoolSelect
