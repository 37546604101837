import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Badge from 'react-bootstrap/Badge'
import Tooltip from 'react-bootstrap/Tooltip'

function SpedBadge() {
  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="top"
      overlay={
        <Tooltip>
          Student may qualify for IEP Goals good cause exemption
        </Tooltip>
      }
    >
      <Badge pill variant="light" className="text-info ml-1" style={{cursor: 'pointer'}}>
        IEP
      </Badge>
    </OverlayTrigger>
  )
}

export default SpedBadge
