import React, { useState, useContext } from 'react'
import Page from 'containers/page'
import Title from 'helpers/title'
import Table from 'react-bootstrap/Table'
import TableFooter from 'shared/tables/footer'
import LoadingStatus from 'helpers/loading-status'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from 'react-query'
import { fetchStudents } from 'api/students'
import { audience } from 'api/helpers'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SchoolSelect from 'schools/school-select'
import UserContext from 'context/user-context'
import SchoolContext from 'context/school-context'

function Promotion() {
  const currentUser = useContext(UserContext)
  const { currentSchool } = useContext(SchoolContext)
  const [search] = useState('')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(25)
  const [orderBy] = useState('last_name')
  const [orderAsc] = useState(true)

  const params = {
    search: search,
    gradeLevels: 3,
    page: page,
    school_id: currentSchool.id || '',
    perPage: perPage,
    orderBy: orderBy,
    orderAsc: orderAsc ? 'asc' : 'desc',
  }

  const { getAccessTokenSilently } = useAuth0()
  const { isLoading, isSuccess, data: students } = useQuery(
    ['students', params],
    async () => {
      const token = await getAccessTokenSilently({ audience })
      const data = await fetchStudents(params, token)
      return data
    }
  )

  return (
    <Page>
      <Row>
        <Col sm={9}>
          <Title
            content={'3rd Grade Promotion Report'}
            subcontent={'Use this page to help fill out the state report'}
          />
        </Col>
        <Col sm={3}>
          {currentUser.schoolCount > 1 ? <SchoolSelect /> : null}
        </Col>
      </Row>
      <hr class="mt-1 mb-4" />

      <Table bordered size="sm" className="shadow-sm">
        <thead>
          <tr className="bg-light">
            <th className="text-center" colSpan="3">
              Student
            </th>
            {/* TODO:
            <th className="text-center" colSpan="4">
              Retention/Promotion Timeframes
            </th>
             */}
            <th className="text-center" colSpan="10">
              Reason for Promotion
            </th>
          </tr>
          <tr className="bg-light">
            <th>First</th>
            <th>Last</th>
            <th>STN</th>

            {/* TODO:
            <th className="text-center">Ret</th>
            <th className="text-center">EOY</th>
            <th className="text-center">Sum</th>
            <th className="text-center">Mid</th>
            */}

            <th className="text-center">P2</th>
            <th className="text-center">E1</th>
            <th className="text-center">E2</th>
            <th className="text-center">E3</th>
            <th className="text-center">E4</th>
            <th className="text-center">E5</th>
            <th className="text-center">E6</th>
            <th className="text-center">E7</th>
            <th className="text-center">SRPT</th>
            <th className="text-center">NT</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan="12" style={{ minHieght: '400px' }}>
                <LoadingStatus status={isLoading} />
              </td>
            </tr>
          )}

          {isSuccess &&
            students.data.map((s) => (
              <tr>
                <td className="bg-light">{s.first_name}</td>
                <td className="bg-light">{s.last_name}</td>
                <td className="bg-light">{s.state_id}</td>

                {/* TODO:
                <td className="text-center"></td>
                <td className="text-center"></td>
                <td className="text-center"></td>
                <td className="text-center"></td>
                */}

                <td className="text-center">
                  {s.student_pathway?.pathway_id === 2 ? (
                    <FontAwesomeIcon icon={faCheck} className="text-success" />
                  ) : null}
                </td>
                <td className="text-center">
                  {' '}
                  {s.student_pathway?.pathway_id === 3 &&
                  s.student_pathway?.exemption_id === 1 ? (
                    <FontAwesomeIcon icon={faCheck} className="text-success" />
                  ) : null}
                </td>
                <td className="text-center">
                  {' '}
                  {s.student_pathway?.pathway_id === 3 &&
                  s.student_pathway?.exemption_id === 2 ? (
                    <FontAwesomeIcon icon={faCheck} className="text-success" />
                  ) : null}
                </td>
                <td className="text-center">
                  {' '}
                  {s.student_pathway?.pathway_id === 3 &&
                  s.student_pathway?.exemption_id === 3 ? (
                    <FontAwesomeIcon icon={faCheck} className="text-success" />
                  ) : null}
                </td>
                <td className="text-center">
                  {' '}
                  {s.student_pathway?.pathway_id === 3 &&
                  s.student_pathway?.exemption_id === 4 ? (
                    <FontAwesomeIcon icon={faCheck} className="text-success" />
                  ) : null}
                </td>
                <td className="text-center">
                  {' '}
                  {s.student_pathway?.pathway_id === 3 &&
                  s.student_pathway?.exemption_id === 5 ? (
                    <FontAwesomeIcon icon={faCheck} className="text-success" />
                  ) : null}
                </td>
                <td className="text-center">
                  {' '}
                  {s.student_pathway?.pathway_id === 3 &&
                  s.student_pathway?.exemption_id === 6 ? (
                    <FontAwesomeIcon icon={faCheck} className="text-success" />
                  ) : null}
                </td>
                <td className="text-center">
                  {' '}
                  {s.student_pathway?.pathway_id === 3 &&
                  s.student_pathway?.exemption_id === 7 ? (
                    <FontAwesomeIcon icon={faCheck} className="text-success" />
                  ) : null}
                </td>
                <td className="text-center"></td>
                <td className="text-center"></td>
              </tr>
            ))}
        </tbody>
      </Table>
      {isSuccess && (
        <TableFooter
          data={students}
          perPage={perPage}
          setPerPage={setPerPage}
          page={page}
          setPage={setPage}
        />
      )}
    </Page>
  )
}

export default Promotion
