import { audience, baseUrl } from 'api/helpers'

export async function fetchAcademicPlanInterventions({ planId, token }) {
  const url = `${baseUrl}/academic_progress_plans/${planId}/interventions`
  const response = await fetch(url, {
    audience,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const data = await response.json()
  return data
}

export async function createAcademicPlanIntervention({ params, plan, token }) {
  const url = `${baseUrl}/academic_progress_plans/${plan.id}/interventions`
  const response = await await fetch(url, {
    method: 'POST',
    audience,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({intervention: params}),
  })
  const data = await response.json()
  return data
}

export async function updateAcademicPlanIntervention({ params, token }) {
  const url = `${baseUrl}/interventions/${params.id}`
  const response = await fetch(url, {
    method: 'PATCH',
    audience,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({intervention: params}),
  })
  const data = await response.json()
  return data
}

export async function destroyAcademicPlanIntervention({ id, token }) {
  const url = `${baseUrl}/interventions/${id}`
  const response = await fetch(url, {
    method: 'DELETE',
    audience,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  const data = await response.json()
  return data
}
