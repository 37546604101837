import React from 'react'
import AssessmentTable from './table'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function Tabs({ student }) {

  return (
    <>
      <Row>
        <Col sm={12}>
          <AssessmentTable student={student} />
        </Col>
      </Row>
    </>
  )
}
