import React, { useState, useContext } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faSave,
  faSignature,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import Form from 'react-bootstrap/Form'
import LongPathway from 'helpers/long-pathway'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useAuth0 } from '@auth0/auth0-react'
import { toast } from 'react-toastify'
import { currentSchoolYear } from 'helpers/school-dates'
import { useMutation, useQueryClient } from 'react-query'
import { audience } from 'api/helpers'
import { createApproval, updateApproval, destroyApproval } from 'api/approvals'
import Table from 'react-bootstrap/Table'
import SrptBadge from 'shared/badges/srpt'
import { isInteger, isEmpty, isNull, isUndefined } from 'lodash'
import Card from 'react-bootstrap/Card'
import UserContext from 'context/user-context'

function ApprovalModal({ show, setShow, student, oldApproval }) {
  const currentUser = useContext(UserContext)
  const { getAccessTokenSilently } = useAuth0()
  const queryClient = useQueryClient()
  const [accepted, setAccepted] = useState(oldApproval?.is_accepted || false)
  const [signature, setSignature] = useState(oldApproval?.signature || '')

  const handleSubmit = () => {
    const params = {
      id: oldApproval?.id,
      is_accepted: accepted,
      signature: signature,
      year: currentSchoolYear(),
      student_id: student.id,
    }

    if (isNull(oldApproval?.id) || isUndefined(oldApproval?.id)) {
      createNewApproval(params)
    } else {
      updateOldApproval(params)
    }
  }

  const updateOldApproval = async (params) => {
    const token = await getAccessTokenSilently({ audience })

    updateApprovalMutation.mutate({ params, token })
    toast.warning('Approval updated')
  }

  const updateApprovalMutation = useMutation(
    ({ params, token }) => updateApproval({ params, token }),
    {
      onSuccess: () => {
        setShow(false)
        queryClient.invalidateQueries(`students`)
      },
    }
  )

  const createNewApproval = async (params) => {
    const token = await getAccessTokenSilently({ audience })
    newApprovalMutation.mutate({ params, token })

    toast.success('Approval saved in Documents tab')
  }

  const newApprovalMutation = useMutation(
    ({ params, token }) => createApproval({ params, token }),
    {
      onSuccess: () => {
        setShow(false)
        queryClient.invalidateQueries(`students`)
      },
    }
  )

  const deleteApprovalMutation = useMutation(
    ({ id, token }) => destroyApproval({ id, token }),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(`students`)
        setShow(false)
      },
    }
  )

  const handleDelete = async (id) => {
    const token = await getAccessTokenSilently({ audience })
    deleteApprovalMutation.mutate({
      id: id,
      token: token,
    })
  }

  return (
    <React.Fragment>
      <Modal show={show} size="lg" onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Assurance of Approval - {student.full_name}{' '}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LongPathway
            id={student.student_pathway.pathway_id}
            exemptionId={student.student_pathway.exemption_id}
          />

          {student.student_pathway.pathway_id === 3 && (
            <p className="text-danger font-weight-light mt-4">
              Record of written approval from 3rd Grade Teacher, Principal, and
              Superintendent required for all Good-Cause Exemptions.
            </p>
          )}

          {student.student_pathway.pathway_id === 4 && (
            <Row>
              <Col>
                <Table
                  responsive
                  size="sm"
                  className="mt-4 border border-muted"
                  style={{ background: '#f4f5f7' }}
                >
                  <tbody>
                    <tr>
                      <td className="p-4 h5 align-middle">
                        <SrptBadge />
                      </td>
                      <td className="p-4 align-middle">
                        <span
                          className="text-muted font-weight-light"
                          style={{ fontSize: '0.8rem' }}
                        >
                          Record agreement or disagreement from each member of
                          the SRPT (third-grade teacher, fourth-grade teacher,
                          parent/guardian, reading specialist if available) and
                          document approval of principal and superintendent.
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}

          <Card bg="light">
            <Card.Body>
              <Row>
                <Col sm={4}>
                  {accepted ? (
                    <>
                      <Form.Control
                        size="sm"
                        type="text"
                        value={signature}
                        onChange={(e) => setSignature(e.target.value)}
                        placeholder="Type to Sign"
                        className="mt-4 mb-2"
                      />
                      <div>
                        {isEmpty(signature) ? (
                          <Button
                            variant="link"
                            onClick={() =>
                              setSignature(
                                `${currentUser.first_name} ${currentUser.last_name}`
                              )
                            }
                          >
                            <FontAwesomeIcon icon={faSignature} /> Sign my name
                          </Button>
                        ) : (
                          <div className="font-weight-light float-left ml-2">
                            Signature
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <Col>
                      <Button
                        variant="info"
                        size="sm"
                        onClick={() => setAccepted(true)}
                        className="mt-4"
                      >
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-primary mr-1"
                        />
                        Accept
                      </Button>
                      <div className="ml-2 small font-weight-light my-2">
                        I agree to sign this document electronically
                      </div>
                    </Col>
                  )}
                </Col>
                <Col sm={8}>
                  <p className="font-weight-light p-2">
                    By signing this document, I verify that the appropriate
                    individuals have provided approval for the decision
                    indicated on this document, and that all documentation
                    required for the decision will be placed in the student’s
                    permanent record.
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal.Body>

        <Modal.Footer>
          {isInteger(oldApproval?.id) && (
            <Button
              variant="light"
              className="text-danger mr-2"
              onClick={() => handleDelete(oldApproval?.id)}
            >
              <FontAwesomeIcon icon={faTrash} /> Delete
            </Button>
          )}
          <Button
            variant="primary"
            disabled={!accepted || isEmpty(signature)}
            onClick={handleSubmit}
          >
            <FontAwesomeIcon icon={faSave} /> Save
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default ApprovalModal
