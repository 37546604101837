import React from 'react'
import DefaultSpinner from 'helpers/default-spinner'

function LoadingStatus({ status }) {
  return (
    <div>{(status === 'loading' || status === true) && <DefaultSpinner />}</div>
  )
}

export default LoadingStatus
