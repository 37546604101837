import React, { useContext } from 'react'
import Page from 'containers/page'
import { fetchDistrictSchools } from 'api/district-schools'
import { useQuery } from 'react-query'
import SchoolsTable from 'schools/table'
import Title from 'helpers/title'
import LoadingStatus from 'helpers/loading-status'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import UserContext from 'context/user-context'
import { useAuth0 } from '@auth0/auth0-react'
import { audience } from 'api/helpers'

function Schools() {
  const currentUser = useContext(UserContext)
  const districtId = currentUser.district.id
  const { getAccessTokenSilently } = useAuth0()
  const { isSuccess, isLoading, data: schools } = useQuery(
    `districts/${districtId}/schools`,
    async () => {
      const token = await getAccessTokenSilently({ audience })
      const data = await fetchDistrictSchools({ districtId, token })
      return data
    }
  )

  return (
    <Page>
      <div>
        <Title content={'Schools'} />
        <hr className="mt-1 mb-4" />
        <Row>
          <Col sm={12}>
            {isLoading && <LoadingStatus status={isLoading} />}
            {isSuccess && <SchoolsTable schools={schools} />}
          </Col>
        </Row>
      </div>
    </Page>
  )
}

export default Schools
