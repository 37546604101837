import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Sidebar from './sidebar'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Zendesk from 'react-zendesk'
import { useAuth0 } from '@auth0/auth0-react'
const ZENDESK_KEY = 'bba64e04-4a12-4222-b69f-b523da64532b'

function Page({ children }) {
  const { user } = useAuth0()
  const setting = {
    launcher: {
      chatLabel: {
        'en-US': 'Help/Feedback',
      },
    },
    contactForm: {
      fields: [
        {
          id: 'email',
          prefill: { '*': user?.email, readOnly: true },
        },
      ],
    },
  }

  return (
    <div>
      <ToastContainer />
      <Container className="content" expand="md" fluid>
        <Row>
          <Sidebar />
          <Col
            className="pt-3 px-5 h-100"
            id="content"
            style={{ overflow: 'visible' }}
          >
            {children}
          </Col>
        </Row>
      </Container>
      <Zendesk defer zendeskKey={ZENDESK_KEY} {...setting} />
    </div>
  )
}

export default Page
