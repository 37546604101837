import React from 'react'
import Form from 'react-bootstrap/Form'

function TextField({ label, accessor, setParams, params, placeholder }) {
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        id='first-name'
        type="text"
        placeholder={placeholder}
        value={params[accessor]}
        onChange={(e) =>
          setParams({
            ...params,
            [accessor]: e.target.value,
          })
        }
      />
    </Form.Group>
  )
}

export default TextField
