import React from 'react'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Overlay from 'react-bootstrap/Overlay'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Popover from 'react-bootstrap/Popover'
import ToggleButton from 'react-bootstrap/ToggleButton'


function AppPlanFilter({ showFilters, filterTarget, setShowFilters, setPlanFilter, planFilter, toggleAppPopover }) {

  return (
    <Overlay
      show={showFilters}
      target={filterTarget}
      rootClose
      onHide={(_) => setShowFilters(!showFilters)}
      placement="bottom"
    >
      <Popover style={{ minWidth: '25%' }}>
        <Popover.Title as="h3">APP/IPRI</Popover.Title>
        <Popover.Content>
          <Row className="mt-2">
            <Col sm={12}>
              <ButtonGroup
                className="mt-2"
                toggle
                style={{ width: '100%' }}
              >
              <ToggleButton
                name="app-filter"
                onClick={(e) => setPlanFilter('both')}
                checked={planFilter === 'both' ? true : false}
                value="false"
                type="radio"
                variant="outline-primary"
              >
                {'All'}
              </ToggleButton>
                <ToggleButton
                  name="app-filter"
                  onClick={(e) => setPlanFilter('incomplete')}
                  checked={planFilter === 'incomplete' ? true : false}
                  value="false"
                  type="radio"
                  variant="outline-primary"
                >
                  {'Incomplete'}
                </ToggleButton>
                <ToggleButton
                  name="app-filter"
                  onClick={(e) => setPlanFilter('complete')}
                  checked={planFilter === 'complete' ? true : false}
                  value="true"
                  type="radio"
                  variant="outline-primary"
                >
                  {'Complete'}
                </ToggleButton>
              </ButtonGroup>
            </Col>
          </Row>

          <hr />

          <Row className="mt-2 mb-2">
            <Col xs={6} md={5}>
              <Button variant="link" onClick={() => setPlanFilter('')}>
                Clear
              </Button>
            </Col>
            <Col xs={0} md={2} />
            <Col xs={6} md={5}>
              <Button
                variant="primary"
                onClick={toggleAppPopover}
                block
              >
                Done
              </Button>
            </Col>
          </Row>
        </Popover.Content>
      </Popover>
    </Overlay>
  )
}

export default AppPlanFilter
