import React from 'react'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

function General({ district }) {
  return (
    <Card className="mt-4">
      <Card.Body>
        <Row>
          <Col sm={3}>
            <span className="float-right font-weight-bold">Logo</span>
          </Col>
          <Col sm={3}>
            <Form.Group>
              <input name="file-input" id="file-input" type="file" />
            </Form.Group>
          </Col>
          <Col sm={6}>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col sm={3}>
            <span className="float-right font-weight-bold">
              Midyear Promotion Policy
            </span>
          </Col>
          <Col sm={9}>
            <Form.Group>
              <Form.Control
                as="textarea"
                rows="10"
                value={district.setting?.midyear_promotion_policy || ''}
                placeholder="This will go on the Academic Progress Plan"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col sm={3}>
            <span className="float-right font-weight-bold">
              District Interventions
            </span>
          </Col>
          <Col sm={9}>
            <Row>
              <Col>
                <Table bordered size="sm">
                  <tbody>
                    <tr>
                      <td className="bg-light text-center font-weight-bold">
                        Tier 2
                      </td>
                    </tr>
                    {district.interventions
                      .filter((i) => i.tier === 2)
                      .map((i) => (
                        <tr>
                          <td>{i.name}</td>
                        </tr>
                      ))}
                    <tr className="text-center">
                      <td>
                        <Button variant="light" size="sm">
                          <FontAwesomeIcon
                            icon={faPlus}
                            className="text-primary"
                          />{' '}
                          Add
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col>
                <Table bordered size="sm">
                  <tbody>
                    <tr>
                      <td className="bg-light text-center font-weight-bold">
                        Tier 3
                      </td>
                    </tr>
                    {district.interventions
                      .filter((i) => i.tier === 3)
                      .map((i) => (
                        <tr>
                          <td>{i.name}</td>
                        </tr>
                      ))}
                    <tr className="text-center">
                      <td>
                        <Button variant="light" size="sm">
                          <FontAwesomeIcon
                            icon={faPlus}
                            className="text-primary"
                          />{' '}
                          Add
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default General
