import { audience, baseUrl } from 'api/helpers'

export const fetchSchoolAcademicPlans = async (schoolId, token) => {
  const url = `${baseUrl}/school_academic_plans?school_id=${schoolId}`
  const response = await fetch(url, {
    audience,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const data = await response.json()
  return data
}
