import React from 'react'
import Card from 'react-bootstrap/Card'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { displayGradeLevel } from 'helpers/presenters'

function ExemptionsChart({ exemptionData }) {
  const categories = exemptionData.map((d) => displayGradeLevel(d.grade_level))
  const exempt = exemptionData.map((d) => d.exemptions_count)
  const nonExempt = exemptionData.map((d) => d.non_exempts_count)
  const probations = exemptionData.map((d) => d.probations_count)

  const chartOptions = {
    chart: {
      type: 'column',
      marginBottom: 75,
      animation: false
    },
    credits: {
      enabled: false,
    },
    title: {
      text: 'Exemptions by Grade',
    },
    xAxis: {
      categories: categories,
      labels: {
        rotation: 330,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: '% Hit Exemption',
      },
    },
    legend: {
      align: 'right',
      x: -15,
      verticalAlign: 'bottom',
      y: 10,
      floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || 'white',
      borderColor: '#CCC',
      borderWidth: 1,
      shadow: false,
    },
    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y}<br />',
      shared: true,
    },
    plotOptions: {
      series: {
        stacking: 'percent',
      },
    },
    series: [
      {
        name: 'Not Exempt',
        data: nonExempt,
        color: '#d7d7d7',
        animation: {
          duration: 0
        }
      },
      {
        name: 'Probationally Promoted',
        data: probations,
        color: '#e2af0b',
        animation: {
          duration: 0
        }
      },
      {
        name: 'Exempt',
        data: exempt,
        color: '#45a877',
        animation: {
          duration: 0
        }
      },
    ],
  }

  return (
    <Card>
      <Card.Body>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </Card.Body>
    </Card>
  )
}

export default ExemptionsChart
