import { titleize } from 'helpers/utils'

export const displayGradeLevel = (gradeLevel) => {
  switch (gradeLevel) {
    case -1:
      return 'Pre-K'
    case 0:
      return 'K'
    case 1:
      return '1st'
    case 2:
      return '2nd'
    case 3:
      return '3rd'
    case 4:
      return '4th'
    case 5:
      return '5th'
    case 6:
      return '6th'
    default:
      return 'N/A'
  }
}

export const displayTerm = (term) => {
  return titleize(term)
}

export const humanizeExemptBoolean = (bool) => {
  return bool ? 'Exempt' : 'Not Exempt'
}
