import { useContext } from 'react'
import { useMutation } from 'react-query'
import { apiPost, apiDelete } from 'api'
import AuthContext from 'context/auth-context'

const createUserSchool = async (token, params) => {
  const objectName = 'user_school'
  const url = '/user_schools'

  apiPost({ url, token, objectName, params })
}

export function useCreateUserSchool({ params }) {
  const token = useContext(AuthContext)
  return useMutation(() => {
    return createUserSchool(token, params)
  })
}

const destroyUserSchool = async (token, params) => {
  const objectName = 'user_school'
  const url = `user_schools/${params.id}`

  apiDelete({ url, token, objectName, params, method: 'DELETE' })
}

export function useDestroyUserSchool({ params }) {
  const token = useContext(AuthContext)
  console.log('params', params)
  return useMutation(() => {
    return destroyUserSchool(token, params)
  })
}