import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Badge from 'react-bootstrap/Badge'
import Tooltip from 'react-bootstrap/Tooltip'

function NeedApp() {
  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="top"
      overlay={
        <Tooltip>
          Student score below the benchmark after previously scoring above the benchmark or not having a previous score. These students need an IPRI/APP.
        </Tooltip>
      }
    >
      <Badge
        pill
        variant="light"
        className="text-info"
        style={{ cursor: 'pointer' }}
      >
        📃 Needs IPRI/APP
      </Badge>
    </OverlayTrigger>

  )
}

export default NeedApp
