import React from 'react'
import Form from 'react-bootstrap/Form'

function TestingWindow({ testingWindow, setTestingWindow }) {
  return (
    <Form.Group className="mr-2">
      <Form.Control
        as="select"
        value={testingWindow}
        onChange={(e) => setTestingWindow(e.target.value)}
      >
        <option>Testing Window</option>
        <option value="fall">Fall</option>
        <option value="winter">Winter</option>
        <option value="spring">Spring</option>
      </Form.Control>
    </Form.Group>
  )
}

export default TestingWindow
