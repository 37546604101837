import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useAuth0 } from '@auth0/auth0-react'
import { audience } from 'api/helpers'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { fetchPathways } from 'api/pathways'
import {
  fetchStudentPathways,
  updateStudentPathway,
  createStudentPathway,
  destroyStudentPathway,
} from 'api/student_pathways'
import { fetchExemptions } from 'api/exemptions'
import Form from 'react-bootstrap/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'

function UpdateModal({ showModal, handleClose, student }) {
  const { getAccessTokenSilently } = useAuth0()
  const queryClient = useQueryClient()
  const emptyState = {
    id: null,
    student_id: student.id,
    pathway_id: '',
    exemption_id: '',
  }

  const { isSuccess: pathwaySuccess, data: pathways } = useQuery(
    'pathways',
    async () => {
      const token = await getAccessTokenSilently({ audience })
      const data = await fetchPathways(token)
      return data
    }
  )

  const { isSuccess: exemptionSuccess, data: exemptions } = useQuery(
    `exemptions`,
    async () => {
      const token = await getAccessTokenSilently({ audience })
      const data = await fetchExemptions(token)
      return data
    }
  )

  const { isSuccess: studentPathwaySuccess, data: currentStudentPathway } =
    useQuery(`students/${student.id}/student_pathways`, async () => {
      const token = await getAccessTokenSilently({ audience })
      const data = await fetchStudentPathways(student.id, token)
      return data
    })

  const [studentPathway, setStudentPathway] = useState(emptyState)

  useEffect(() => {
    if (studentPathwaySuccess) {
      setStudentPathway({ ...currentStudentPathway, student_id: student.id })
    }
  }, [
    setStudentPathway,
    studentPathwaySuccess,
    currentStudentPathway,
    student.id,
  ])

  const newStudentPathwayMutation = useMutation(
    (token) => createStudentPathway({ studentPathway, token }),
    {
      onSuccess: () => {
        handleClose()
        toast.success('Student pathway created')
        queryClient.refetchQueries(`students/${student.id}/student_pathways`)
        queryClient.invalidateQueries(`students/${student.id}`)
      },
    }
  )
  const studentPathwayMutation = useMutation(
    (token) => updateStudentPathway({ studentPathway, token }),
    {
      onSuccess: () => {
        handleClose()
        queryClient.refetchQueries(`students/${student.id}/student_pathways`)
        queryClient.invalidateQueries(`students/${student.id}`)
        toast.info('Student pathway updated')
      },
    }
  )
  const deleteStudentPathwayMutation = useMutation(
    ({ id, token }) => {
      handleClose()
      destroyStudentPathway({ id, token })
    },
    {
      onSuccess: async () => {
        handleClose()
        queryClient.refetchQueries(`students/${student.id}/student_pathways`)
        queryClient.invalidateQueries(`students/${student.id}`)
        toast.info('Updated student pathway')
      },
    }
  )

  const updateStudentExemption = async () => {
    const token = await getAccessTokenSilently({ audience })
    if (studentPathway.id === null || studentPathway.id === undefined) {
      newStudentPathwayMutation.mutate(token)
    } else if (studentPathway.pathway_id === null) {
      deleteStudentPathwayMutation.mutate({
        id: studentPathway.id,
        token: token,
      })
    } else {
      studentPathwayMutation.mutate(token)
    }
  }

  const updatePathwayState = (e) => {
    const optionValue = parseInt(e.target.value)
    const pathwayId = isNaN(optionValue) ? null : optionValue
    if (pathwayId !== 3) {
      setStudentPathway((prevState) => ({
        ...prevState,
        pathway_id: pathwayId,
        exemption_id: '',
      }))
    } else {
      setStudentPathway((prevState) => ({
        ...prevState,
        pathway_id: pathwayId,
      }))
    }
  }

  const updateExemptionState = (e) => {
    const optionValue = parseInt(e.target.value)
    const exemptionId = isNaN(optionValue) ? null : optionValue
    setStudentPathway((prevState) => ({
      ...prevState,
      exemption_id: exemptionId,
    }))
  }

  const displayPathway = (number) => {
    if (number !== 5) {
      return `Pathway ${number} -`
    } else {
      return ''
    }
  }

  return (
    <Modal show={showModal} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{student.full_name} RSA Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={3}>Pathways</Col>
          <Col sm={9}>
            {studentPathwaySuccess && (
              <Form.Group>
                <Form.Control
                  as="select"
                  value={studentPathway?.pathway_id}
                  onChange={(e) => updatePathwayState(e)}
                >
                  <option value="">None</option>
                  {pathwaySuccess &&
                    pathways.map((p) => (
                      <option key={`pathway-option-${p.id}`} value={p.id}>
                        {[1, 2, 3, 4, 5].includes(p.number)
                          ? displayPathway(p.number)
                          : ''}{' '}
                        {p.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            )}

            {studentPathwaySuccess && studentPathway?.pathway_id === 3 && (
              <Form.Group>
                <Form.Control
                  as="select"
                  value={studentPathway?.exemption_id}
                  onChange={(e) => updateExemptionState(e)}
                >
                  <option value="">Select Good Cause Exemption</option>
                  {exemptionSuccess &&
                    exemptions.map((e) => (
                      <option key={`exemption-${e.id}`} value={e.id}>
                        Exemption {e.number} - {e.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => updateStudentExemption()}>
          <FontAwesomeIcon icon={faSave} /> Save
        </Button>
        <Button variant="link" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default UpdateModal
