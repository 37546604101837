import React, { useContext } from 'react'
import Page from 'containers/page'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Title from 'helpers/title'
import { useQuery } from 'react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { audience } from 'api/helpers'
import { fetchReportBoy } from 'api/report-beginning-of-years'
import { displayGradeLevel } from 'helpers/presenters'
import Card from 'react-bootstrap/Card'
import UserContext from 'context/user-context'
import SchoolContext from 'context/school-context'
import SchoolSelect from 'schools/school-select'
import LoadingStatus from 'helpers/loading-status'

function BeginningOfYear() {
  const { getAccessTokenSilently } = useAuth0()
  const currentUser = useContext(UserContext)
  const { currentSchool } = useContext(SchoolContext)
  const { isSuccess, isLoading, data } = useQuery(
    ['report_beginning_of_year', currentSchool.id],
    async () => {
      const params = { school_id: currentSchool.id }
      const token = await getAccessTokenSilently({ audience })
      const data = await fetchReportBoy({ token, params })
      return data
    }
  )

  return (
    <Page>
      <Row>
        <Col sm={9}>
          <Title
            content={'Beginning of Year Report'}
            subcontent={'Use this page to help fill out the state report'}
          />
        </Col>
        <Col sm={3}>
          {currentUser.schoolCount > 1 ? <SchoolSelect /> : null}
        </Col>
      </Row>
      <hr className="mt-1 mb-4" />
      <div className="mt-4">
        <Card className="shadow-sm">
          <Card.Body>
            {isLoading && (
              <div style={{ minHeight: 300 }} className="mt-5 pt-5">
                <LoadingStatus status={isLoading} />
              </div>
            )}
            {isSuccess &&
              data.map((d) => (
                <React.Fragment key={`${d.grade_level}-tested`}>
                  <Row>
                    <Col sm={6}>
                      <p className="float-right mt-1">
                        Number of{' '}
                        <strong>
                          {`${displayGradeLevel(d.grade_level)}`} students
                        </strong>{' '}
                        tested:
                      </p>
                    </Col>
                    <Col sm={2}>
                      <Form.Control
                        type="text"
                        data-testid={`${d.grade_level}-tested`}
                        value={`${d.students_tested}`}
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <p className="float-right mt-1">
                        Number of{' '}
                        <strong>
                          {`${displayGradeLevel(d.grade_level)}`} students
                        </strong>{' '}
                        identified for reading difficulties:
                      </p>
                    </Col>
                    <Col sm={2}>
                      <Form.Control
                        type="text"
                        data-testid={`${d.grade_level}-below`}
                        value={`${d.students_below}`}
                        disabled
                      />
                    </Col>
                  </Row>
                </React.Fragment>
              ))}
          </Card.Body>
        </Card>
      </div>
    </Page>
  )
}

export default BeginningOfYear
