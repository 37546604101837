import React, { useState, useContext } from 'react'
import Page from 'containers/page'
import Title from 'helpers/title'
import Table from 'react-bootstrap/Table'
import TableFooter from 'shared/tables/footer'
import LoadingStatus from 'helpers/loading-status'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from 'react-query'
import { fetchStudents } from 'api/students'
import { audience } from 'api/helpers'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SchoolSelect from 'schools/school-select'
import UserContext from 'context/user-context'
import SchoolContext from 'context/school-context'
import ApprovalRow from './approval-row'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSearch,
  faTimes,
  faCheck,
  faParking,
  faSortUp,
  faSortDown,
  faSort,
} from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button'

function Promotion() {
  const currentUser = useContext(UserContext)
  const { currentSchool } = useContext(SchoolContext)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(25)
  const [orderBy, setOrderBy] = useState('last_name')
  const [orderAsc, setOrderAsc] = useState(true)
  const [exemptFilterCheck, setExemptFilterCheck] = useState('')

  const params = {
    search: search,
    gradeLevels: 3,
    page: page,
    school_id: currentSchool.id || '',
    exempt: exemptFilterCheck,
    perPage: perPage,
    orderBy: orderBy,
    orderAsc: orderAsc ? 'asc' : 'desc',
  }

  const updateOrdering = (column) => {
    column === orderBy ? setOrderAsc(!orderAsc) : setOrderAsc('asc')
    setOrderBy(column)
  }

  const findIcon = (column) => {
    if (column === orderBy) {
      return orderAsc ? (
        <FontAwesomeIcon icon={faSortUp} />
      ) : (
        <FontAwesomeIcon icon={faSortDown} />
      )
    } else {
      return <FontAwesomeIcon icon={faSort} />
    }
  }

  const handleChange = (e) => {
    setSearch(e.target.value)
    setPage(1)
  }

  const { getAccessTokenSilently } = useAuth0()
  const { isLoading, isSuccess, data: students } = useQuery(
    ['students', params],
    async () => {
      const token = await getAccessTokenSilently({ audience })
      const data = await fetchStudents(params, token)
      return data
    }
  )

  return (
    <Page>
      <Row>
        <Col sm={9}>
          <Title
            content={'Documentation for Third Grade Promotion or Retention'}
          />
        </Col>
        <Col sm={3}>
          {currentUser.schoolCount > 1 ? <SchoolSelect /> : null}
        </Col>
      </Row>
      <hr className="mt-1 mb-4" />

      <Row className="mb-2">
        <Col sm={4}>
          <Form.Group>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                value={search}
                onChange={(e) => handleChange(e)}
                placeholder="Student Name/ID"
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={2}>
          <Button
            name="exemption-filter"
            onClick={(e) => setExemptFilterCheck('not_exempt')}
            checked={exemptFilterCheck === 'not_exempt'}
            type="radio"
            block
            variant={
              exemptFilterCheck === 'not_exempt' ? 'outline-primary' : 'light'
            }
          >
            <FontAwesomeIcon icon={faTimes} className={'text-danger mr-2'} />
            {'No Decision'}
          </Button>
        </Col>
        <Col sm={2}>
          <Button
            name="exemption-filter"
            onClick={(e) => setExemptFilterCheck('exempt')}
            checked={exemptFilterCheck === 'exempt'}
            type="radio"
            block
            variant={
              exemptFilterCheck === 'exempt' ? 'outline-primary' : 'light'
            }
          >
            <FontAwesomeIcon icon={faCheck} className={'text-success mr-2'} />
            {'Exempt'}
          </Button>
        </Col>
        <Col sm={2}>
          <Button
            name="exemption-filter"
            onClick={(e) => setExemptFilterCheck('probation')}
            checked={exemptFilterCheck === 'probation'}
            type="radio"
            block
            variant={
              exemptFilterCheck === 'probation' ? 'outline-primary' : 'light'
            }
          >
            <FontAwesomeIcon icon={faParking} className={'text-warning mr-2'} />
            {'Probation'}
          </Button>
        </Col>
        <Col sm={2}>
          {exemptFilterCheck !== '' && (
            <Button
              variant="link"
              name="exemption-filter"
              onClick={(e) => setExemptFilterCheck('')}
              block
            >
              Clear
            </Button>
          )}
        </Col>
      </Row>

      <Table bordered responsive size="sm" className="shadow-sm">
        <thead>
          <tr className="bg-light">
            <th onClick={() => updateOrdering('first_name')}>
              First{' '}
              <span
                className={`float-right ${
                  'first_name' === orderBy ? 'text-primary' : 'text-muted'
                }`}
              >
                {findIcon('first_name')}
              </span>
            </th>
            <th onClick={() => updateOrdering('last_name')}>
              Last{' '}
              <span
                className={`float-right ${
                  'last_name' === orderBy ? 'text-primary' : 'text-muted'
                }`}
              >
                {findIcon('last_name')}
              </span>
            </th>
            <th className="text-center">Pathway</th>
            <th className="text-center">Status</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan="12" style={{ minHieght: '400px' }}>
                <LoadingStatus status={isLoading} />
              </td>
            </tr>
          )}

          {isSuccess &&
            students.data.map((s) => (
              <ApprovalRow key={`approval-row-${s.id}`} student={s} />
            ))}
        </tbody>
      </Table>
      {isSuccess && (
        <TableFooter
          data={students}
          perPage={perPage}
          setPerPage={setPerPage}
          page={page}
          setPage={setPage}
        />
      )}
    </Page>
  )
}

export default Promotion
