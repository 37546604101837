import React, { useState } from 'react'
import Page from 'containers/page'
import Title from 'helpers/title'
import { fetchStudent } from 'api/students'
import { toInteger, split } from 'lodash'
import { useQuery } from 'react-query'
import ErrorStatus from 'helpers/error-status'
import LoadingStatus from 'helpers/loading-status'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Documents from './documents/index'
import AssessmentTabs from './assessments'
import AcademicProgressPlans from './academic-progress-plan'
import Actions from 'students/actions'
import { Link } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { audience } from 'api/helpers'
import { isNull } from 'lodash'
import StatusBox from 'students/status-box'
import SrptMeetings from 'students/sprt-meetings'
import CurrentYearAssessments from 'students/current-year-assessments'

function Students() {
  const { getAccessTokenSilently } = useAuth0()
  const path = window.location.pathname
  const studentId = toInteger(split(path, '/')[2])
  const tab = window.location.hash.replace('#', '')

  const {
    isSuccess,
    isError,
    isLoading,
    data: student,
  } = useQuery(`students/${studentId}`, async () => {
    const token = await getAccessTokenSilently({ audience })
    const data = await fetchStudent(studentId, token)
    return data
  })
  const [activeTab, setActiveTab] = useState(tab || 'academic-progress-plan')

  if (isLoading) {
    return (
      <Page>
        <LoadingStatus status={isLoading} />
      </Page>
    )
  }

  if (isError) {
    return (
      <Page>
        <ErrorStatus errorMessage="Unable to load student" />
      </Page>
    )
  }

  return (
    <Page>
      {isSuccess && (
        <div key={student.id}>
          <Row>
            <Col sm={11}>
              <Title
                content={student.full_name}
                subcontent={
                  <>
                    <Button
                      className="ml-1"
                      style={{ outline: 'none', boxShadow: 'none' }}
                      onClick={() => setActiveTab('academic-progress-plan')}
                      variant={
                        activeTab === 'academic-progress-plan'
                          ? 'link bg-white'
                          : 'light'
                      }
                    >
                      Overview
                    </Button>
                    <Button
                      className="ml-1"
                      style={{ outline: 'none', boxShadow: 'none' }}
                      onClick={() => setActiveTab('assessments')}
                      variant={
                        activeTab === 'assessments' ? 'link bg-white' : 'light'
                      }
                    >
                      Assessments
                    </Button>
                    <Button
                      className="ml-1"
                      style={{ outline: 'none', boxShadow: 'none' }}
                      onClick={() => setActiveTab('documents')}
                      variant={
                        activeTab === 'documents' ? 'link bg-white' : 'light'
                      }
                    >
                      Documents
                    </Button>
                    <Actions student={student} />
                  </>
                }
              />
            </Col>

            <Col sm={1}>
              <Link className="btn btn-link mt-5 float-right" to="/students">
                Back
              </Link>
            </Col>
          </Row>

          <div className="mb-2 pt-1 border-top align-self-stretch" />

          {activeTab === 'academic-progress-plan' ? (
            <>
              <Row className="my-4">
                <Col sm={4}>
                  <StatusBox student={student} />
                  {!isNull(student.student_pathway) &&
                    student.student_pathway.pathway_id === 4 && (
                      <SrptMeetings student={student} />
                    )}

                  <CurrentYearAssessments student={student} />
                </Col>

                <Col sm={8}>
                  <AcademicProgressPlans student={student} />
                </Col>
              </Row>
            </>
          ) : null}

          {activeTab === 'assessments' ? (
            <AssessmentTabs student={student} />
          ) : null}

          {activeTab === 'documents' ? <Documents student={student} /> : null}
        </div>
      )}
    </Page>
  )
}

export default Students
