import React, { useContext } from 'react'
import UserContext from 'context/user-context'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import Card from 'react-bootstrap/Card'
import { useQuery } from 'react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { audience } from 'api/helpers'
import { fetchDistrictSettings } from 'api/district-settings'
import { isNull, isUndefined, isEmpty } from 'lodash'

function PoliciesSection() {
  const currentUser = useContext(UserContext)
  const districtId = currentUser.district.id

  const { getAccessTokenSilently } = useAuth0()
  const { isSuccess, data: policy } = useQuery(
    `district/${districtId}/settings`,
    async () => {
      const token = await getAccessTokenSilently({ audience })
      const data = await fetchDistrictSettings({ id: districtId, token })
      return data
    }
  )

  return (
    <Row>
      <Col sm={3}>
        <h5>Policies</h5>
      </Col>
      <Col sm={9}>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col sm={12}>
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="first">No Social Promotion</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">
                    Midyear Promotion Policy
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">
                    Eligible for Promotion (4th)
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane className="my-4" eventKey="first">
                  <Card>
                    <Card.Body>
                      <Card.Text>
                        The Individualized Program of Reading Instruction shall
                        continue until the student is determined by the results
                        of an approved reading assessment to be reading on grade
                        level. The student will not be promoted to the fourth
                        grade if requirements for one of the following options
                        are not met:
                      </Card.Text>
                      <ul>
                        <li>
                          Meets RSA Criteria on the Oklahoma State Testing
                          Program (OSTP) at the end of third grade.
                        </li>
                        <li>
                          Meets the end-of-year grade-level target for third
                          grade.
                        </li>
                        <li>
                          Meets requirements for one of the good-cause
                          exemptions.
                        </li>
                        <li>
                          Promoted with probation by a unanimous decision of the
                          Student Reading Proficiency Team.
                        </li>
                      </ul>
                    </Card.Body>
                  </Card>
                </Tab.Pane>
                <Tab.Pane className="my-4" eventKey="second">
                  <Card>
                    <Card.Body>
                      {isSuccess && (
                        <React.Fragment>
                          {!isUndefined(policy) &&
                          !isEmpty(policy) &&
                          !isNull(policy.midyear_promotion_policy) ? (
                            policy.midyear_promotion_policy
                              .split('\n')
                              .map((t, key) => {
                                return <p key={key}>{t}</p>
                              })
                          ) : (
                            <span className="lead text-danger">
                              District administrator has not defined policy in
                              OK RSA application
                            </span>
                          )}
                        </React.Fragment>
                      )}
                    </Card.Body>
                  </Card>
                </Tab.Pane>
                <Tab.Pane className="my-4" eventKey="third">
                  <Card>
                    <Card.Body>
                      <Card.Text>
                        Any first-grade, second-grade or third-grade student who
                        demonstrates end of year proficiency in reading at the
                        third-grade level through an approved screening
                        instrument shall not be subject to the retention
                        guidelines. After a student has demonstrated proficiency
                        through a screening instrument, the district shall
                        provide notification to the parent or guardian that the
                        student has satisfied the requirements of the Reading
                        Sufficiency Act and will not be subject to retention.
                        (70 O.S. § 1210.508C.H.1)
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Col>
    </Row>
  )
}

export default PoliciesSection
