import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import RequiredField from 'helpers/required-field'
import { useAuth0 } from '@auth0/auth0-react'
import { audience } from 'api/helpers'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import Table from 'react-bootstrap/Table'
import SupportsModal from './supports-modal'
import {
  fetchAcademicPlanSupports,
  destroyAcademicPlanSupport,
} from 'api/academic_plan_supports'
import ConfirmDelete from 'helpers/confirm-delete'

function AcademicPlanSupports({ plan, readOnly }) {
  const queryClient = useQueryClient()
  const { getAccessTokenSilently } = useAuth0()

  const { isSuccess, data: supports } = useQuery(
    `academic_progress_plans/${plan.id}/supports`,
    async () => {
      const token = await getAccessTokenSilently({ audience })
      const data = await fetchAcademicPlanSupports({ plan, token })
      return data
    }
  )

  const deleteSupportMutation = useMutation(
    ({ id, token }) => destroyAcademicPlanSupport({ id, token }),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(
          `academic_progress_plans/${plan.id}/supports`
        )
      },
    }
  )

  const handleDelete = async (id) => {
    const token = await getAccessTokenSilently({ audience })
    deleteSupportMutation.mutate({
      id: id,
      token: token,
    })
    queryClient.invalidateQueries(`academic_progress_plans/${plan.id}/supports`)
  }

  return (
    <>
      <Row>
        <Col>
          <h5>Supplemental or Remedial Reading Services and Supports</h5>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col sm={3}>
          <p className="font-weight-light">
            Supports (add all that apply) <RequiredField />
          </p>
        </Col>
        <Col sm={9}>
          <Table responsive bordered size="sm">
            <thead>
              <tr>
                <th>Support</th>
                <th>Minutes</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {isSuccess &&
                supports.map((s) => (
                  <tr key={`support-${s.id}`}>
                    <td>{s.name}</td>
                    <td>{s.minutes}</td>
                    <td>
                      <ConfirmDelete
                        id={s.id}
                        handleDelete={handleDelete}
                        readOnly={readOnly}
                      />
                    </td>
                  </tr>
                ))}
              <tr>
                <td colSpan="3" className="text-center">
                  <SupportsModal plan={plan} readOnly={readOnly} />
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  )
}

export default AcademicPlanSupports
