import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Page from 'containers/page'
import Title from 'helpers/title'
import { Link } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { audience } from 'api/helpers'
import { toInteger, split } from 'lodash'
import { useQuery } from 'react-query'
import { fetchStudentAcademicPlan } from 'api/academic_progress_plans'
import AcademicPlanForm from './form'

function AcademicProgressPlan() {
  const { getAccessTokenSilently } = useAuth0()
  const path = window.location.pathname
  const planId = toInteger(split(path, '/')[2])

  const { isSuccess: isPlanSuccess, data: currentPlan } = useQuery(
    [`academic-progress-plan`, planId],
    async () => {
      const token = await getAccessTokenSilently({ audience })
      const data = await fetchStudentAcademicPlan({ planId: planId, token })
      return data
    }
  )

  const studentId = currentPlan?.student_id

  return (
    <Page>
      <>
        {isPlanSuccess && (
          <Row>
            <Col sm={11}>
              <Title
                content={
                  <>
                    <a className="btn btn-link" href={`/students/${studentId}`}>
                      {currentPlan.student_name}
                    </a>
                    <span className="text-info font-weight-light">/</span>{' '}
                    Academic Progress Plan
                  </>
                }
              />
            </Col>
            <Col sm={1}>
              <Link
                className="btn btn-link mt-5 float-right"
                to={`/students/${studentId}`}
              >
                Back
              </Link>
            </Col>
          </Row>
        )}
        {isPlanSuccess && <AcademicPlanForm currentPlan={currentPlan} />}
      </>
    </Page>
  )
}

export default AcademicProgressPlan
