import React from 'react'
import Form from 'react-bootstrap/Form'

function RoleDropdown({ params, setParams }) {
  return (
    <Form.Group>
      <Form.Label>Role</Form.Label>
      <Form.Control
        as="select"
        value={params.role}
        onChange={(e) => setParams({ ...params, role: e.target.value })}
      >
        <option>Select Role</option>
        <option value="admin" disabled>
          Admin (OK RSA only)
        </option>
        <option value="district_admin">District Admin</option>
        <option value="school_admin">School Admin</option>
        <option value="teacher">Teacher</option>
      </Form.Control>
    </Form.Group>
  )
}

export default RoleDropdown
