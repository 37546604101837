import moment from 'moment'

export const formatDateTime = (date) => {
  return moment(date).format('MMMM Do YYYY, h:mm:ss a')
}

export const formatDate = (date) => {
  return moment(date).format('MMM Do YYYY')
}

export const formatDateSlash = (date) => {
  return moment(date).format('MM/DD/YYYY')
}

export const datetimeAscending = (dates) => {
  return dates.sort((a, b) => a.date.localeCompare(b.date))
}
