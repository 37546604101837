import React from 'react'
import Table from 'react-bootstrap/Table'
import { riskColor } from 'helpers/risk-status'
import { isNaN } from 'lodash'

function CurrentYearAssessments({ student }) {
  const fallPercentile = student.fall?.percentile
  const winterPercentile = student.winter?.percentile
  const springPercentile = student.spring?.percentile
  const fallSpringGrowth = springPercentile - fallPercentile

  return (
    <Table bordered responsive size="sm" className="mt-4 shadow-sm">
      <thead>
        <tr className="bg-light">
          <th className="text-center" colSpan={4}>
            Current Year Assessments
          </th>
        </tr>
        <tr>
          <th className="text-center">Fall</th>
          <th className="text-center">Winter</th>
          <th className="text-center">Spring</th>
          <th className="text-center">Growth</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="text-center">{riskColor(fallPercentile)}</td>
          <td className="text-center">{riskColor(winterPercentile)}</td>
          <td className="text-center">{riskColor(springPercentile)}</td>
          <td className="text-center">
            {isNaN(fallSpringGrowth) ? 'N/A' : `${fallSpringGrowth}%`}
          </td>
        </tr>
      </tbody>
    </Table>
  )
}

export default CurrentYearAssessments
