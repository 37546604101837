import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Badge from 'react-bootstrap/Badge'
import Tooltip from 'react-bootstrap/Tooltip'

function AboveBenchmark() {
  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="top"
      overlay={
        <Tooltip>
          Student previously scored below the benchmark but is now above the benchmark. IPRI is no longer needed.
        </Tooltip>
      }
    >
      <Badge
        pill
        variant="light"
        className="text-info"
        style={{ cursor: 'pointer' }}
      >
        📈 Moved Above Benchmark
      </Badge>
    </OverlayTrigger>

  )
}

export default AboveBenchmark
