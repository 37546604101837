import { audience, baseUrl } from 'api/helpers'

export const fetchDistricts = async ({ params, token }) => {
  const url = new URL(`${baseUrl}/districts`)
  url.search = new URLSearchParams(params).toString()
  
  const response = await fetch(url, {
    ...params,
    audience,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  const data = await response.json()
  return data
}

export const fetchDistrict = async (id, token) => {
  const url = `${baseUrl}/districts/${id}`
  const response = await fetch(url, {
    audience,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  const data = await response.json()
  return data
}
