import React from 'react'
import { Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faParking,
  faTimes,
  faRedo,
} from '@fortawesome/free-solid-svg-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { pathwayText } from 'helpers/pathway-text'

function RsaStatusBadge({ studentPathway, tooltip = true }) {
  if (studentPathway === null || studentPathway === undefined) {
    return (
      <>
        {tooltip ? (
          <OverlayTrigger
            key="top"
            placement="bottom"
            trigger={['hover', 'focus']}
            overlay={
              <Tooltip id={'tooltip'}>
                Student has not met RSA Exemption
              </Tooltip>
            }
          >
            <Badge
              variant="white"
              className={'text-danger'}
              pill
              style={{ background: '#fdecea', cursor: 'pointer' }}
            >
              {<FontAwesomeIcon icon={faTimes} />} None
            </Badge>
          </OverlayTrigger>
        ) : (
          <Badge
            variant="white"
            className={'text-danger'}
            pill
            style={{ background: '#fdecea', cursor: 'pointer' }}
          >
            {<FontAwesomeIcon icon={faTimes} />} None
          </Badge>
        )}
      </>
    )
  }

  if (studentPathway.pathway_id === 4) {
    return (
      <>
        {tooltip ? (
          <OverlayTrigger
            key="top"
            placement="bottom"
            trigger={['hover', 'focus']}
            overlay={
              <Tooltip id={'tooltip'}>Pathway 4: Probational promotion</Tooltip>
            }
          >
            <Badge
              variant="white"
              className={'text-warning'}
              pill
              style={{ background: '#fcf7e6', cursor: 'pointer' }}
            >
              {<FontAwesomeIcon icon={faParking} />} Probation
            </Badge>
          </OverlayTrigger>
        ) : (
          <Badge
            variant="white"
            className={'text-warning'}
            pill
            style={{ background: '#fcf7e6', cursor: 'pointer' }}
          >
            {<FontAwesomeIcon icon={faParking} />} Probation
          </Badge>
        )}
      </>
    )
  }

  if (studentPathway.pathway_id === 5) {
    return (
      <>
        {tooltip ? (
          <OverlayTrigger
            key="top"
            placement="bottom"
            trigger={['hover', 'focus']}
            overlay={<Tooltip id={'tooltip'}>Student will be retained</Tooltip>}
          >
            <Badge
              variant="white"
              className={'text-secondary'}
              pill
              style={{ background: '#f4f5f7', cursor: 'pointer' }}
            >
              {<FontAwesomeIcon icon={faRedo} />} Retain
            </Badge>
          </OverlayTrigger>
        ) : (
          <Badge
            variant="white"
            className={'text-secondary'}
            pill
            style={{ background: '#f4f5f7', cursor: 'pointer' }}
          >
            {<FontAwesomeIcon icon={faRedo} />} Retain
          </Badge>
        )}
      </>
    )
  }

  return (
    <>
      {tooltip ? (
        <OverlayTrigger
          key="top"
          placement="bottom"
          trigger={['hover', 'focus']}
          overlay={
            <Tooltip id={'tooltip'}>
              Pathway
              {studentPathway.pathway_id}:{' '}
              {pathwayText(
                studentPathway.pathway_id,
                studentPathway.exemption_id
              )}
            </Tooltip>
          }
        >
          <Badge
            className={'text-success'}
            style={{ background: '#ecf6f1', cursor: 'pointer' }}
            pill
          >
            {<FontAwesomeIcon icon={faCheck} />} Pathway{' '}
            {studentPathway.pathway_id}
          </Badge>
        </OverlayTrigger>
      ) : (
        <Badge
          className={'text-success'}
          style={{ background: '#ecf6f1', cursor: 'pointer' }}
          pill
        >
          {<FontAwesomeIcon icon={faCheck} />} Pathway{' '}
          {studentPathway.pathway_id}
        </Badge>
      )}
    </>
  )
}

export default RsaStatusBadge
