import React, { useEffect } from 'react'
import Badge from 'react-bootstrap/Badge'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"
import { useCreateUserGradeLevel, useDestroyUserGradeLevel } from 'api/hooks/use-user-grade-levels'
import { useQueryClient } from 'react-query'

function GradeLevelAccess({ school, params, gradeLevel }) {
  const queryClient = useQueryClient()
  const schoolGradeLevels = params.user_grade_levels.filter(ugl => ugl.school_id === school.id)
  const gradeLevelIds = schoolGradeLevels.map(sgl => sgl.grade_level_id)

  const { mutate: create, isSuccess: createSuccess } = useCreateUserGradeLevel({
    params: {
      user_id: params.id,
      school_id: school.id,
      grade_level_id: gradeLevel.id,
    },
  })
  const { mutate: destroy, isSuccess: destroySuccess } = useDestroyUserGradeLevel({
    params: {
      id: params.user_grade_levels.find((ugl) => ugl.school_id === school.id && ugl.grade_level_id === gradeLevel.id)?.id
    },
  })

  useEffect(() => {
    if (createSuccess || destroySuccess) {
      queryClient.invalidateQueries(['user', params.id])
    }
  }, [createSuccess, destroySuccess])


  return (
    <span className="mr-2">
      {gradeLevelIds.includes(gradeLevel.id) ? (
        <span>
          <Badge variant="light text-secondary" onClick={() => destroy()} style={{cursor: 'pointer'}}>
            <FontAwesomeIcon icon={faCheck} className="mr-1 text-success" />
            {gradeLevel.name}
          </Badge>
        </span>
      ) : (
        <span>
          <Badge variant="light text-secondary" onClick={() => create()} style={{cursor: 'pointer'}}>
            <FontAwesomeIcon icon={faTimes} className="mr-1 text-danger" />
            {gradeLevel.name}
          </Badge>
        </span>
      )}
    </span>
  )
}

export default GradeLevelAccess
