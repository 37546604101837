import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAsterisk } from "@fortawesome/free-solid-svg-icons"

function RequiredField() {
  return (
    <small className='text-danger'>
      <FontAwesomeIcon icon={faAsterisk} />
    </small>
  )
}

export default RequiredField
