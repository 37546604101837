import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import { formatDateSlash } from 'helpers/datetime'
import Table from 'react-bootstrap/Table'
import { displayTerm } from 'helpers/presenters'
import { uniq, findLast, isNull, orderBy, isUndefined, isEmpty } from 'lodash'
import Datetime from 'react-datetime'
import { currentSchoolYear } from 'helpers/school-dates'
import { useMutation } from 'react-query'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { riskColor } from 'helpers/risk-status'
import RequiredField from 'helpers/required-field'
import { useAuth0 } from '@auth0/auth0-react'
import { updateStudentAcademicPlan } from 'api/academic_progress_plans'
import AcademicPlanInterventions from './interventions'
import AcademicPlanSupports from './supports'
import AcademicPlanConferences from './conferences'
import SmallExemptBadge from 'students/badges/small-exempt-badge'
import PoliciesSection from './policies'
import { audience } from 'api/helpers'
import humps from 'lodash-humps'
import moment from 'moment'
import { useQuery } from 'react-query'
import { fetchAssessmentScores } from 'api/assessment-scores'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StatusCard from './form/status-card'
import SkillCard from './form/skill-card'
import TierCard from './form/tier-card'
import ServicesCard from './form/services-card'
import Dyslexia from './form/dyslexia'

function AcademicPlanForm({ currentPlan }) {
  const { getAccessTokenSilently } = useAuth0()
  const [plan, setPlan] = useState(humps(currentPlan))
  const [firstRender, setFirstRender] = useState(true)
  const studentId = currentPlan.student_id
  const readOnly = currentSchoolYear() !== currentPlan.year

  const { isSuccess, data: scores } = useQuery(
    [`student-assessments`, studentId],
    async () => {
      const params = { studentId: studentId }
      const token = await getAccessTokenSilently({ audience })
      const data = await fetchAssessmentScores({ params, token })
      return data
    }
  )

  const exemptionScore =
    isSuccess &&
    findLast(scores, function (n) {
      return n.met_exemption === true
    })

  const [needsUpdate, setNeedsUpdate] = useState(false)
  const isBasicComplete = currentPlan.basic_info_complete
  const isAcademicPlanComplete = currentPlan.plan_complete
  const isSupportsComplete = currentPlan.supports_complete
  const isInterventionsComplete = currentPlan.interventions_complete
  const isConferencesComplete = currentPlan.conferences_complete

  const checkAll = (boolean) => {
    setPlan({
      ...plan,
      guardianIdentified: !plan.guardianIdentified,
      guardianConjoint: !plan.guardianConjoint,
      guardianInstructionSupport: !plan.guardianInstructionSupport,
      guardianNotPromoted: !plan.guardianNotPromoted,
      guardianFamilyStrategies: !plan.guardianFamilyStrategies,
      guardianPerformanceScores: !plan.guardianPerformanceScores,
      guardianPortfolio: !plan.guardianPortfolio,
      guardianMidyearPromotion: !plan.guardianMidyearPromotion,
    })
  }

  const allChecked =
    !!plan &&
    plan.guardianIdentified &&
    plan.guardianConjoint &&
    plan.guardianInstructionSupport &&
    plan.guardianNotPromoted &&
    plan.guardianFamilyStrategies &&
    plan.guardianPerformanceScores &&
    plan.guardianPortfolio &&
    plan.guardianMidyearPromotion

  const updatePlanMutation = useMutation(({ params, token }) =>
    updateStudentAcademicPlan({ params, token })
  )

  useEffect(() => {
    const debounceTextFields = setTimeout(() => {
      setNeedsUpdate(true)
    }, 500)

    return () => clearTimeout(debounceTextFields)
  }, [
    plan.conjointMeasurementModelLexileLevel,
    plan.conjointMeasurementModelOtherLevel,
    plan.diagnosticAssessments,
    plan.additionalServices,
    plan.homeStrategies,
    plan.date,
    plan.teacher,
    plan.guardian,
    plan.status,
    plan.homeStrategies,
    plan.additionalServices,
  ])

  useEffect(() => {
    setNeedsUpdate(true)
  }, [
    plan.id,
    plan.conjointMeasurementModelLexile,
    plan.conjointMeasurementModelOther,
    plan.readingSkillsPhonemic,
    plan.readingSkillsPhonics,
    plan.readingSkillsVocabulary,
    plan.readingSkillsComprehension,
    plan.readingSkillsFluency,
    plan.supportTier,
    plan.collaborativeServicesTitle1,
    plan.collaborativeServicesSped,
    plan.collaborativeServicesEll,
    plan.guardianIdentified,
    plan.guardianConjoint,
    plan.guardianInstructionSupport,
    plan.guardianNotPromoted,
    plan.guardianFamilyStrategies,
    plan.guardianPerformanceScores,
    plan.guardianPortfolio,
    plan.guardianMidyearPromotion,
    plan.guardianDenied,
    plan.date,
    plan.status,
    plan.dyslexiaScreener,
    plan.dyslexiaAtRisk,
    plan.dyslexiaOutsideServices,
  ])

  useEffect(() => {
    if (needsUpdate && !firstRender) {
      const railsParams = {
        id: plan.id,
        year: plan.year,
        date: plan.date,
        teacher: plan.teacher,
        guardian: plan.guardian,
        status: plan.status,
        conjoint_measurement_model_lexile: plan.conjointMeasurementModelLexile,
        conjoint_measurement_model_lexile_level:
          plan.conjointMeasurementModelLexileLevel,
        conjoint_measurement_model_other: plan.conjointMeasurementModelOther,
        conjoint_measurement_model_other_level:
          plan.conjointMeasurementModelOtherLevel,
        diagnostic_assessments: plan.diagnosticAssessments,
        reading_skills_phonemic: plan.readingSkillsPhonemic,
        reading_skills_phonics: plan.readingSkillsPhonics,
        reading_skills_vocabulary: plan.readingSkillsVocabulary,
        reading_skills_comprehension: plan.readingSkillsComprehension,
        reading_skills_fluency: plan.readingSkillsFluency,
        support_tier: plan.supportTier,
        additional_services: plan.additionalServices,
        home_strategies: plan.homeStrategies,
        collaborative_services_title1: plan.collaborativeServicesTitle1,
        collaborative_services_sped: plan.collaborativeServicesSped,
        collaborative_services_ell: plan.collaborativeServicesEll,
        guardian_identified: plan.guardianIdentified,
        guardian_conjoint: plan.guardianConjoint,
        guardian_instruction_support: plan.guardianInstructionSupport,
        guardian_not_promoted: plan.guardianNotPromoted,
        guardian_family_strategies: plan.guardianFamilyStrategies,
        guardian_performance_scores: plan.guardianPerformanceScores,
        guardian_portfolio: plan.guardianPortfolio,
        guardian_midyear_promotion: plan.guardianMidyearPromotion,
        guardian_denied: plan.guardianDenied,
        dyslexia_screener: plan.dyslexiaScreener,
        dyslexia_at_risk: plan.dyslexiaAtRisk,
        dyslexia_outside_services: plan.dyslexiaOutsideServices,
      }

      const update = async () => {
        const token = await getAccessTokenSilently({ audience })
        updatePlanMutation.mutate({ params: railsParams, token })
      }
      setNeedsUpdate(false)
      update()
    }
    setFirstRender(false)
  }, [
    needsUpdate,
    getAccessTokenSilently,
    updatePlanMutation,
    plan.id,
    plan.conjointMeasurementModelLexile,
    plan.conjointMeasurementModelLexileLevel,
    plan.conjointMeasurementModelOther,
    plan.conjointMeasurementModelOtherLevel,
    plan.diagnosticAssessments,
    plan.readingSkillsPhonemic,
    plan.readingSkillsPhonics,
    plan.readingSkillsVocabulary,
    plan.readingSkillsComprehension,
    plan.readingSkillsFluency,
    plan.supportTier,
    plan.additionalServices,
    plan.homeStrategies,
    plan.collaborativeServicesTitle1,
    plan.collaborativeServicesSped,
    plan.collaborativeServicesEll,
    plan.guardianIdentified,
    plan.guardianConjoint,
    plan.guardianInstructionSupport,
    plan.guardianNotPromoted,
    plan.guardianFamilyStrategies,
    plan.guardianPerformanceScores,
    plan.guardianPortfolio,
    plan.guardianMidyearPromotion,
    plan.date,
    plan.teacher,
    plan.guardian,
    plan.status,
    plan.dyslexiaScreener,
    plan.dyslexiaAtRisk,
    plan.dyslexiaOutsideServices,
    firstRender,
  ])

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId)
    element.scrollIntoView({
      behavior: 'smooth',
    })
  }

  const isSectionActive = (bool) => {
    return bool ? 'active' : ''
  }

  const latestLexileScore = () => {
    if (isUndefined(scores) || isEmpty(scores)) {
      return null
    } else {
      const latest = orderBy(
        scores.filter((s) => !isNull(s.lexile)),
        'date',
        'desc'
      )[0]
      return latest.lexile
    }
  }

  return (
    <>
      <Row className="sticky-top py-2 d-xs-none">
        <Col sm={12}>
          <Card>
            <Card.Body>
              <ul className="form-steps">
                <li
                  style={{ cursor: 'pointer' }}
                  className={`${isSectionActive(isBasicComplete)}`}
                  onClick={() => scrollToSection('basic-info')}
                >
                  Basic Info
                </li>
                <li
                  style={{ cursor: 'pointer' }}
                  className={`${isSectionActive(isAcademicPlanComplete)}`}
                  onClick={() => scrollToSection('academic-plan')}
                >
                  Academic Plan
                </li>
                <li
                  style={{ cursor: 'pointer' }}
                  className={`${isSectionActive(isSupportsComplete)}`}
                  onClick={() => scrollToSection('supports')}
                >
                  Supports
                </li>
                <li
                  style={{ cursor: 'pointer' }}
                  className={`${isSectionActive(isInterventionsComplete)}`}
                  onClick={() => scrollToSection('interventions')}
                >
                  Interventions
                </li>
                <li
                  style={{ cursor: 'pointer' }}
                  className={`${isSectionActive(isConferencesComplete)}`}
                  onClick={() => scrollToSection('conferences')}
                >
                  Conferences
                </li>
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <div id="basic-info" style={{ position: 'absolute', top: '-100px' }}>
        {readOnly && (
          <Alert className="mt-4" variant="warning">
            This is an APP/IPRI from a previous year and cannot be changed.
          </Alert>
        )}
      </div>
      <Row className="mt-4">
        <Col sm={3}>
          <h5>
            Basic Information <RequiredField />
          </h5>
        </Col>
        <Col sm={5}>
          <Form.Group>
            <Form.Control
              disabled={readOnly}
              type="text"
              placeholder="Teacher Name"
              value={plan.teacher || ''}
              onChange={(e) => setPlan({ ...plan, teacher: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              disabled={readOnly}
              type="text"
              value={plan.guardian || ''}
              placeholder="Parent/Guardian"
              onChange={(e) => setPlan({ ...plan, guardian: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Datetime
              inputProps={{ disabled: readOnly }}
              placeholder="mm/dd/yyyy"
              dateFormat="MM/DD/YYYY"
              value={formatDateSlash(plan.date) || moment()}
              onChange={(e) => setPlan({ ...plan, date: e })}
              timeFormat={false}
            />
          </Form.Group>
        </Col>
        <Col sm={2} />
      </Row>

      <Row className="py-3 mt-4">
        <Col sm={3}>
          <p className="font-weight-light">
            Status <RequiredField />
          </p>
        </Col>
        <Col sm={9}>
          <Row className="no-gutters">
            <Col>
              <StatusCard
                plan={plan}
                setPlan={setPlan}
                statusType="first_time"
                title="First Time"
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StatusCard
                plan={plan}
                setPlan={setPlan}
                statusType="previously_met_target"
                title="Previously Met"
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StatusCard
                plan={plan}
                setPlan={setPlan}
                statusType="previously_not_met"
                title="Not Met"
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row className="no-gutters mt-2">
            <Col>
              <StatusCard
                plan={plan}
                setPlan={setPlan}
                statusType="probationary_promoted"
                title="Probationary Promoted"
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StatusCard
                plan={plan}
                setPlan={setPlan}
                statusType="tested_out"
                title="Tested Out"
                readOnly={readOnly}
              />
            </Col>
            <Col></Col>
          </Row>
        </Col>
      </Row>

      <div className="py-3 mt-4 border-light border-top"></div>
      <Row>
        <Col sm={3}>
          <h5>Assessments</h5>
        </Col>
        <Col sm={9}>
          <p>
            The student has taken the following screening assessment(s):
            <span className="font-weight-bold">
              {isSuccess &&
                uniq(scores.map((s) => s.assessment.name)).join(', ')}
            </span>
          </p>
          <p>Student screening assessment scores are below:</p>

          <Table className="bg-white" bordered="bordered" size="sm">
            <thead>
              <tr>
                <th>Assessment</th>
                <th>
                  Date <small>Window</small>
                </th>
                {/* TODO: Add benchmark column */}
                <th className="text-center">Student Score</th>
                <th className="text-center">Percentile</th>
              </tr>
            </thead>
            <tbody>
              {isSuccess &&
                scores.map((s) => (
                  <tr key={s.id}>
                    <td>
                      {s.assessment.name}{' '}
                      <span className="ml-2">
                        {s.met_exemption ? <SmallExemptBadge /> : null}
                      </span>
                    </td>
                    <td>
                      {formatDateSlash(s.date)}{' '}
                      <small className="text-muted">
                        {displayTerm(s.testing_window)}
                      </small>
                    </td>
                    <td className="text-center">{s.score}</td>
                    <td className={`text-center`}>{riskColor(s.percentile)}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <div className="py-1 mt-2"></div>

      <Row>
        <Col sm={3}>
          <p className="font-weight-light">Conjoint Measurement Model</p>
        </Col>
        <Col>
          <Form.Check
            type="checkbox"
            label="Lexile Level"
            className="mt-1"
            disabled={readOnly}
            checked={plan.conjointMeasurementModelLexile}
            onChange={(e) =>
              setPlan({
                ...plan,
                conjointMeasurementModelLexile:
                  !plan.conjointMeasurementModelLexile,
              })
            }
          />
        </Col>
        <Col>
          <Form.Group>
            <Form.Control
              type="text"
              disabled={readOnly}
              placeholder="Lexile Level"
              value={plan.conjointMeasurementModelLexileLevel}
              onChange={(e) =>
                setPlan({
                  ...plan,
                  conjointMeasurementModelLexileLevel: e.target.value,
                })
              }
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Check
            type="checkbox"
            label="Other"
            disabled={readOnly}
            className="mt-1"
            checked={plan.conjointMeasurementModelOther}
            onChange={(e) =>
              setPlan({
                ...plan,
                conjointMeasurementModelOther:
                  !plan.conjointMeasurementModelOther,
              })
            }
          />
        </Col>
        <Col>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Insert Level"
              disabled={readOnly}
              value={plan.conjointMeasurementModelOtherLevel || ''}
              onChange={(e) =>
                setPlan({
                  ...plan,
                  conjointMeasurementModelOtherLevel: e.target.value,
                })
              }
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col sm={3}></Col>
        <Col>
          <div className="float-right">
            {isNull(latestLexileScore()) ||
              (!isNull(plan.conjointMeasurementModelLexileLevel) && !readOnly && (
                <Button
                  variant="link"
                  size="sm"
                  onClick={(e) =>
                    setPlan({
                      ...plan,
                      conjointMeasurementModelLexileLevel: latestLexileScore(),
                      conjointMeasurementModelLexile: true,
                    })
                  }
                >
                  Use lexile from latest assessment: {latestLexileScore()}
                </Button>
              ))}
          </div>
        </Col>
        <Col></Col>
      </Row>

      <div className="py-1 mt-2"></div>

      <Row>
        <Col sm={3}>
          <p className="font-weight-light mt-2">
            Other assessment(s) used for primary diagnostic purposes
          </p>
        </Col>
        <Col sm={9}>
          <Form.Group>
            <Form.Label></Form.Label>
            <Form.Control
              as="textarea"
              disabled={readOnly}
              rows=""
              placeholder=""
              value={plan.diagnosticAssessments || ''}
              onChange={(e) =>
                setPlan({ ...plan, diagnosticAssessments: e.target.value })
              }
            />
          </Form.Group>
        </Col>
      </Row>

      <div className="py-3 mt-4 border-light border-top"></div>
      <div
        id="academic-plan"
        style={{ top: '-125px', position: 'relative' }}
      ></div>

      <Row>
        <Col>
          <h5>Academic Plan</h5>
        </Col>
      </Row>

      <Row>
        <Col sm={3}>
          <p className="font-weight-light">
            Student goal of improving his/her reading skills in the area(s) of{' '}
            <RequiredField />
          </p>
        </Col>
        <Col sm={9}>
          <Row>
            <Col sm={6}>
              <SkillCard
                plan={plan}
                setPlan={setPlan}
                field="readingSkillsPhonemic"
                currentValue={plan.readingSkillsPhonemic}
                title={'Phonemic awareness'}
                readOnly={readOnly}
              />
            </Col>
            <Col sm={6}>
              <SkillCard
                plan={plan}
                setPlan={setPlan}
                field="readingSkillsPhonics"
                currentValue={plan.readingSkillsPhonics}
                title={'Phonics'}
                readOnly={readOnly}
              />
            </Col>
            <Col sm={6}>
              <SkillCard
                plan={plan}
                setPlan={setPlan}
                field="readingSkillsFluency"
                currentValue={plan.readingSkillsFluency}
                title={'Reading Fluency'}
                readOnly={readOnly}
              />
            </Col>

            <Col sm={6}>
              <SkillCard
                plan={plan}
                setPlan={setPlan}
                field="readingSkillsVocabulary"
                currentValue={plan.readingSkillsVocabulary}
                title={'Vocabulary'}
                readOnly={readOnly}
              />
            </Col>
            <Col sm={6}>
              <SkillCard
                plan={plan}
                setPlan={setPlan}
                field="readingSkillsComprehension"
                currentValue={plan.readingSkillsComprehension}
                title={'Comprehension'}
                readOnly={readOnly}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col sm={3}>
          <p className="font-weight-light">
            Based on the data, the student will receive supports at the
            following level of intensity <RequiredField />
          </p>
        </Col>
        <Col sm={9}>
          <Row>
            <Col>
              <TierCard
                plan={plan}
                setPlan={setPlan}
                tier={2}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <TierCard
                plan={plan}
                setPlan={setPlan}
                tier={3}
                readOnly={readOnly}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col sm={3}>
          <p className="font-weight-light">
            The student will receive collaborative services through: (check all
            that apply)
          </p>
        </Col>
        <Col sm={9}>
          <Row>
            <Col sm={6}>
              <ServicesCard
                plan={plan}
                setPlan={setPlan}
                field={'collaborativeServicesSped'}
                value={plan.collaborativeServicesSped}
                title={'Special Education (IDEA)'}
                readOnly={readOnly}
              />
            </Col>

            <Col sm={6}>
              <ServicesCard
                plan={plan}
                setPlan={setPlan}
                field={'collaborativeServicesEll'}
                value={plan.collaborativeServicesEll}
                title={'ELL/Title III'}
                readOnly={readOnly}
              />
            </Col>

            <Col sm={6}>
              <ServicesCard
                plan={plan}
                setPlan={setPlan}
                field={'collaborativeServicesTitle1'}
                value={plan.collaborativeServicesTitle1}
                title={'Title I'}
                readOnly={readOnly}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="py-3 mt-4 border-light border-top"></div>

      <div id="supports" style={{ top: '-125px', position: 'relative' }}></div>

      <AcademicPlanSupports plan={currentPlan} readOnly={readOnly} />
      <Row className="mt-4">
        <Col sm={3}>
          <p className="font-weight-light">
            Any additional proposed supplemental instructional services and
            supports that will be provided to the student
          </p>
        </Col>
        <Col sm={9}>
          <Form.Control
            as="textarea"
            disabled={readOnly}
            value={plan.additionalServices || ''}
            onChange={(e) =>
              setPlan({
                ...plan,
                additionalServices: e.target.value,
              })
            }
            rows="4"
            placeholder=""
          />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col sm={3}>
          <p className="font-weight-light">
            Strategies to be used at home to help your student succeed in
            reading proficiency
          </p>
        </Col>
        <Col sm={9}>
          <Form.Control
            as="textarea"
            rows="4"
            disabled={readOnly}
            value={plan.homeStrategies || ''}
            onChange={(e) =>
              setPlan({
                ...plan,
                homeStrategies: e.target.value,
              })
            }
            placeholder=""
          />
        </Col>
      </Row>

      <div className="py-3 mt-4 border-light border-top"></div>

      {currentSchoolYear() > 2022 && (
        <Dyslexia plan={plan} setPlan={setPlan} readOnly={readOnly} />
      )}

      <div
        id="interventions"
        style={{ top: '-125px', position: 'relative' }}
      ></div>

      <div className="py-3 mt-4 border-light border-top"></div>

      <AcademicPlanInterventions plan={currentPlan} readOnly={readOnly} />

      <div className="py-3 mt-4 border-light border-top"></div>

      <Row></Row>

      <PoliciesSection />

      <Row className="mt-4">
        <Col sm={3}>Pathway 2: Screener</Col>
        <Col sm={9}>
          <Alert variant="primary">
            This section will automatically populate from the student's
            assessments when the student hits the RSA Exemption score
          </Alert>
        </Col>
      </Row>

      <Row>
        <Col sm={3}></Col>
        <Col sm={9}>
          {!!exemptionScore ? (
            <Form.Check
              type="checkbox"
              label="Student has met the end-of-year grade-level target for 3rd grade and is eligible for promotion to fourth grade through Pathway 2."
              checked={true}
              disabled
            />
          ) : (
            <Form.Check
              type="checkbox"
              label="Student has met the end-of-year grade-level target for 3rd grade and is eligible for promotion to fourth grade through Pathway 2."
              checked={false}
              disabled
            />
          )}
        </Col>
      </Row>

      {!!exemptionScore ? (
        <Row className="mt-3">
          <Col sm={3} />
          <Col sm={3}>
            <Form.Group>
              <Form.Label>
                <b>Date target met</b>
              </Form.Label>
              <Form.Group>
                <Datetime
                  placeholder="mm/dd/yyyy"
                  dateFormat="MM/DD/YYYY"
                  inputProps={{ disabled: true }}
                  value={formatDateSlash(exemptionScore.date)}
                  timeFormat={false}
                />
              </Form.Group>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group>
              <Form.Label>
                <b>Name of screener</b>
              </Form.Label>
              <Form.Control
                disabled
                type="text"
                value={exemptionScore.assessment.name}
                placeholder=""
              />
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group>
              <Form.Label>
                <b>Student score</b>
              </Form.Label>
              <Form.Control
                disabled
                type="text"
                value={exemptionScore.score}
                placeholder=""
              />
            </Form.Group>
          </Col>
        </Row>
      ) : (
        <Row className="mt-3">
          <Col sm={3} />
          <Col sm={3}>
            <Form.Group>
              <Form.Label>
                <b>Date target met</b>
              </Form.Label>
              <Form.Group>
                <Datetime
                  placeholder="mm/dd/yyyy"
                  dateFormat="MM/DD/YYYY"
                  inputProps={{ disabled: true }}
                  timeFormat={false}
                />
              </Form.Group>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group>
              <Form.Label>
                <b>Name of screener</b>
              </Form.Label>
              <Form.Control disabled type="text" placeholder="" />
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group>
              <Form.Label>
                <b>Student score</b>
              </Form.Label>
              <Form.Control disabled type="text" placeholder="" />
            </Form.Group>
          </Col>
        </Row>
      )}

      <div id="conferences" className="my-4 py-2 border-light border-top">
        <AcademicPlanConferences plan={plan} readOnly={readOnly} />

        <Row className="mt-4">
          <Col sm={3}>
            <p className="font-weight-light mt-2">
              As the Parent/Guardian, I have been notified of the following
            </p>
          </Col>
          <Col sm={9}>
            <Form.Check
              type="checkbox"
              onClick={() => checkAll(!allChecked)}
              checked={allChecked}
              disabled={readOnly}
              label="Check All"
            />

            <Form.Check
              type="checkbox"
              checked={plan.guardianIdentified}
              disabled={readOnly}
              onChange={(e) =>
                setPlan({
                  ...plan,
                  guardianIdentified: !plan.guardianIdentified,
                })
              }
              label="The student has been identified as having a substantial deficiency in reading"
            />

            <Form.Check
              type="checkbox"
              checked={plan.guardianConjoint}
              disabled={readOnly}
              onChange={(e) =>
                setPlan({ ...plan, guardianConjoint: !plan.guardianConjoint })
              }
              label="A description of the current services that are provided to the student pursuant to a conjoint measurement model such that a reader and a text are placed on the same scale."
            />

            <Form.Check
              type="checkbox"
              checked={plan.guardianInstructionSupport}
              disabled={readOnly}
              onChange={(e) =>
                setPlan({
                  ...plan,
                  guardianInstructionSupport: !plan.guardianInstructionSupport,
                })
              }
              label="A description of the proposed supplemental instructional services and supports that will be provided to the student that are designed to remediate the identified area(s) of deficiency."
            />

            <Form.Check
              type="checkbox"
              checked={plan.guardianNotPromoted}
              disabled={readOnly}
              onChange={(e) =>
                setPlan({
                  ...plan,
                  guardianNotPromoted: !plan.guardianNotPromoted,
                })
              }
              label="That the student will not be promoted to the fourth grade if the reading deficiency is not remediated by the end of the third grade, unless the student meets the requirements for one of the pathways for promotion."
            />

            <Form.Check
              type="checkbox"
              checked={plan.guardianFamilyStrategies}
              disabled={readOnly}
              onChange={(e) =>
                setPlan({
                  ...plan,
                  guardianFamilyStrategies: !plan.guardianFamilyStrategies,
                })
              }
              label="Strategies for families to be used in helping their child succeed in reading proficiency"
            />

            <Form.Check
              type="checkbox"
              checked={plan.guardianPerformanceScores}
              disabled={readOnly}
              onChange={(e) =>
                setPlan({
                  ...plan,
                  guardianPerformanceScores: !plan.guardianPerformanceScores,
                })
              }
              label="The grade-level performance scores of the student."
            />

            <Form.Check
              type="checkbox"
              checked={plan.guardianPortfolio}
              disabled={readOnly}
              onChange={(e) =>
                setPlan({ ...plan, guardianPortfolio: !plan.guardianPortfolio })
              }
              label="While the results of the OSTP are the initial determinant, they are not the sole determiner of promotion and that portfolio reviews and assessments are available"
            />

            <Form.Check
              type="checkbox"
              checked={plan.guardianMidyearPromotion}
              disabled={readOnly}
              onChange={(e) =>
                setPlan({
                  ...plan,
                  guardianMidyearPromotion: !plan.guardianMidyearPromotion,
                })
              }
              label="Specific criteria and policies of the school district for midyear promotion."
            />

            <Form.Check
              type="checkbox"
              checked={plan.guardianDenied}
              disabled={readOnly}
              onChange={(e) =>
                setPlan({
                  ...plan,
                  guardianDenied: !plan.guardianDenied,
                })
              }
              label="The parent denied the student's participation for the reading plan"
            />
          </Col>
        </Row>
      </div>
      <Row className="my-5 py-5" />
    </>
  )
}

export default AcademicPlanForm
