import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Datetime from 'react-datetime'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import SmallExemptBadge from 'students/badges/small-exempt-badge'
import { displayTerm } from 'helpers/presenters'
import { formatDateSlash } from 'helpers/datetime'
import { riskColor } from 'helpers/risk-status'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { fetchMeetingRoles } from 'api/meeting-roles'
import { fetchAssessmentScores } from 'api/assessment-scores'
import { useAuth0 } from '@auth0/auth0-react'
import { audience } from 'api/helpers'
import RecommendationRow from 'students/meetings/recommendation-row'
import { complianceText } from 'students/meetings/compliance-text'
import { emptyDecision } from 'students/meetings/empty-meeting'
import { uniq, find, isNull, isUndefined, isInteger } from 'lodash'
import LoadingStatus from 'helpers/loading-status'
import { createMeeting, updateMeeting, destroyMeeting } from 'api/meetings'
import { toast } from 'react-toastify'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { currentSchoolYear } from 'helpers/school-dates'

function MeetingModal({ showModal, handleClose, student, oldMeeting }) {
  const { getAccessTokenSilently } = useAuth0()
  const queryClient = useQueryClient()

  const emptyMeeting = {
    id: oldMeeting?.id,
    date: oldMeeting?.date || moment(),
    year: oldMeeting?.year || currentSchoolYear(),
    reason: oldMeeting?.reason || '',
  }

  const {
    isSuccess: isRolesSuccess,
    isLoading: isRolesLoading,
    data: roles,
  } = useQuery(`meeting-roles`, async () => {
    const token = await getAccessTokenSilently({ audience })
    const data = await fetchMeetingRoles({ token })
    return data
  })

  const [meeting, setMeeting] = useState(oldMeeting || emptyMeeting)
  const [parentDecision, setParentDecision] = useState(
    find(oldMeeting?.decisions, { meeting_role_id: 1 }) || emptyDecision(1)
  )
  const [teacherDecision, setTeacherDecision] = useState(
    find(oldMeeting?.decisions, { meeting_role_id: 2 }) || emptyDecision(2)
  )
  const [nextTeacherDecision, setNextTeacherDecision] = useState(
    find(oldMeeting?.decisions, { meeting_role_id: 3 }) || emptyDecision(3)
  )
  const [readingSpecialistDecision, setReadingSpecialistDecision] = useState(
    find(oldMeeting?.decisions, { meeting_role_id: 4 }) || emptyDecision(4)
  )
  const [principalDecision, setPrincipalDecision] = useState(
    find(oldMeeting?.decisions, { meeting_role_id: 5 }) || emptyDecision(5)
  )
  const [superDecision, setSuperDecision] = useState(
    find(oldMeeting?.decisions, { meeting_role_id: 6 }) || emptyDecision(6)
  )

  const { isSuccess, data: scores } = useQuery(
    [`students/${student.id}/assessments`, 'current'],
    async () => {
      const params = { studentId: student.id, current: true }
      const token = await getAccessTokenSilently({ audience })
      const data = await fetchAssessmentScores({ params, token })
      return data
    }
  )

  const handleSubmit = () => {
    const params = {
      ...meeting,
      student_id: student.id,
      school_id: student.school_id,
      meeting_decisions_attributes: [
        parentDecision,
        teacherDecision,
        nextTeacherDecision,
        readingSpecialistDecision,
        principalDecision,
        superDecision,
      ],
    }
    if (isNull(oldMeeting?.id) || isUndefined(oldMeeting?.id)) {
      createNewMeeting(params)
    } else {
      updateOldMeeting(params)
    }
    handleClose()
  }

  const updateOldMeeting = async (params) => {
    const token = await getAccessTokenSilently({ audience })

    updateMeetingMutation.mutate({ params, token })
    toast.warning('Approval updated')
  }

  const updateMeetingMutation = useMutation(
    ({ params, token }) => updateMeeting({ params, token }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`students`)
      },
    }
  )

  const createNewMeeting = async (params) => {
    const token = await getAccessTokenSilently({ audience })
    newMeetingMutation.mutate({ params, token })
    toast.success('Meeting saved')
  }

  const newMeetingMutation = useMutation(
    ({ params, token }) => createMeeting({ params, token }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('students')
        handleClose()
      },
    }
  )

  const deleteMeetingMutation = useMutation(
    ({ id, token }) => destroyMeeting({ id, token }),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('students')
        handleClose()
      },
    }
  )

  const handleDelete = async (id) => {
    const token = await getAccessTokenSilently({ audience })
    deleteMeetingMutation.mutate({
      id: id,
      token: token,
    })
  }

  return (
    <React.Fragment>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Create Probationary Promotion SRPT Meeting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-4">
            <Col sm={3}>
              <div className="mt-4 font-weight-bold ">Student Information</div>
            </Col>
            <Col sm={9}>
              <Card className="bg-white">
                <Card.Body>
                  {student.first_name} {student.last_name}{' '}
                  <span className="text-muted">
                    Grade: {student.grade_level}
                  </span>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <span className="font-weight-bold">Date</span>
            </Col>
            <Col sm={9}>
              <Form.Group>
                <Datetime
                  placeholder="Date of Meeting"
                  dateFormat="MM/DD/YYYY"
                  timeFormat={false}
                  value={meeting.date}
                  onChange={(e) => setMeeting({ ...meeting, date: e })}
                  required={true}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <span className="font-weight-bold">District Assessment</span>
            </Col>
            <Col sm={9}>
              {isSuccess && (
                <Form.Group>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={uniq(scores.map((s) => s.assessment.name)).join(
                      ', '
                    )}
                  />
                </Form.Group>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <span className="font-weight-bold">Student Scores</span>
              <div className="text-muted font-weight-light">
                Current school year
              </div>
            </Col>
            <Col sm={9}>
              <Table className="bg-white" bordered="bordered" size="sm">
                <thead>
                  <tr>
                    <th>Assessment</th>
                    <th>
                      Date <small>Window</small>
                    </th>
                    {/* TODO: Add benchmark column */}
                    <th className="text-center">Score</th>
                    <th className="text-center">Percentile</th>
                  </tr>
                </thead>
                <tbody>
                  {isSuccess &&
                    scores.map((s) => (
                      <tr key={s.id}>
                        <td>
                          {s.assessment.name}{' '}
                          <span className="ml-2">
                            {s.met_exemption ? <SmallExemptBadge /> : null}
                          </span>
                        </td>
                        <td>
                          {formatDateSlash(s.date)}{' '}
                          <small className="text-muted">
                            {displayTerm(s.testing_window)}
                          </small>
                        </td>
                        <td className="text-center">{s.score}</td>
                        <td className={`text-center`}>
                          {riskColor(s.percentile)}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <span className="font-weight-bold">Reasons</span>
            </Col>
            <Col sm={9}>
              <Form.Control
                as="textarea"
                rows="3"
                value={meeting.reason}
                onChange={(e) =>
                  setMeeting({ ...meeting, reason: e.target.value })
                }
                placeholder="Reasons why student did not meet requirements for any Good Cause Exemption"
              />
            </Col>
          </Row>
          <hr className="mt-4 py-2" />
          <Row>
            <Col sm={12}>
              <span className="h5">
                Recommendation for Probationary Promotion
              </span>

              <p className="text-muted">
                Signatures declaring probationary promotion is best option for
                student
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Table size="sm">
                <thead>
                  <tr>
                    <th>Role</th>
                    <th>
                      Student should be Probationary Promoted to the next grade
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isRolesLoading && (
                    <tr>
                      <td colSpan="2">
                        <LoadingStatus status={isRolesLoading} />
                      </td>
                    </tr>
                  )}
                  {isRolesSuccess && (
                    <>
                      <RecommendationRow
                        key={`role-row-parent`}
                        setDecisionObject={setParentDecision}
                        decisionObject={parentDecision}
                        roles={roles}
                        roleName={'Parent'}
                      />
                      <RecommendationRow
                        key={`role-row-teacher`}
                        setDecisionObject={setTeacherDecision}
                        decisionObject={teacherDecision}
                        roles={roles}
                        roleName={'Current Teacher'}
                      />
                      <RecommendationRow
                        key={`role-row-next-teacher`}
                        setDecisionObject={setNextTeacherDecision}
                        decisionObject={nextTeacherDecision}
                        roles={roles}
                        roleName={'Next Teacher'}
                      />
                      <RecommendationRow
                        key={`role-row-reading-specialist`}
                        setDecisionObject={setReadingSpecialistDecision}
                        decisionObject={readingSpecialistDecision}
                        roles={roles}
                        roleName={'Reading Specialist'}
                      />
                      <RecommendationRow
                        key={`role-row-principal`}
                        setDecisionObject={setPrincipalDecision}
                        decisionObject={principalDecision}
                        roles={roles}
                        roleName={'Principal'}
                      />
                      <RecommendationRow
                        key={`role-row-super`}
                        setDecisionObject={setSuperDecision}
                        decisionObject={superDecision}
                        roles={roles}
                        roleName={'Superintendent'}
                      />
                    </>
                  )}
                </tbody>
              </Table>

              <Card bg={'light'}>
                <Card.Body>
                  <Card.Text
                    className="font-weight-light text-secondary"
                    style={{ fontSize: '0.85rem' }}
                  >
                    {complianceText}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Modal.Footer>
            {isInteger(oldMeeting?.id) && (
              <Button
                variant="light"
                className="text-danger mr-2"
                onClick={() => handleDelete(oldMeeting?.id)}
              >
                <FontAwesomeIcon icon={faTrash} /> Delete
              </Button>
            )}
            <Button variant="primary" onClick={handleSubmit}>
              Save
            </Button>
          </Modal.Footer>{' '}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default MeetingModal
