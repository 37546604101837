import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Badge from 'react-bootstrap/Badge'
import Tooltip from 'react-bootstrap/Tooltip'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserSlash, faLaptop } from "@fortawesome/free-solid-svg-icons"

function InfoBadge({ tooltipText, icon }) {

  const findIcon = (icon) => {
    if (icon === 'user') {
      return <FontAwesomeIcon icon={faUserSlash} className='text-info' />
    } else if (icon === 'laptop') {
      return <FontAwesomeIcon icon={faLaptop} className='text-info' />
    }
  }

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="top"
      overlay={
        <Tooltip>
          {tooltipText}
        </Tooltip>
      }
    >
      <Badge
        pill
        variant="light"
        className="text-info ml-1"
        style={{ cursor: 'pointer' }}
      >
        {findIcon(icon)}
      </Badge>
    </OverlayTrigger>
  )
}

export default InfoBadge
