import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import {
  useDestroyUserSchool,
  useCreateUserSchool,
} from 'api/hooks/use-user-schools'
import { useQueryClient } from 'react-query'

function UserSchoolAction({ userId, schoolId, params }) {
  const queryClient = useQueryClient()
  const { isSuccess: isMutationSuccess, mutate: destroy } = useDestroyUserSchool({
    params: {id: params.user_schools.find((us) => us.school_id === schoolId)?.id}
  })

  const { isSuccess: isCreateSuccess, mutate: create } = useCreateUserSchool({
    params: {
      user_id: userId,
      school_id: schoolId,
    },
  })

  useEffect(() => {
    if (isMutationSuccess || isCreateSuccess) {
      queryClient.invalidateQueries(['user', userId])
    }
  }, [isMutationSuccess, isCreateSuccess])

  return (
    <>
      {params.user_schools.find((us) => us.school_id === schoolId) ? (
        <Button variant="light" onClick={() => destroy()}>
          <FontAwesomeIcon icon={faMinus} className="text-danger" /> Remove
        </Button>
      ) : (
        <Button variant="light" onClick={() => create()}>
          <FontAwesomeIcon icon={faPlus} className="text-success" /> Add
        </Button>
      )}
    </>
  )
}

export default UserSchoolAction
