import React from 'react'
import Table from 'react-bootstrap/Table'
import { Link } from 'react-router-dom'
import RsaStatusBadge from './badges/rsa-status'
import ProgressPlanIcon from './badges/progress-plan'
import { riskColor } from 'helpers/risk-status'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SpedBadge from './badges/sped'
import EllBadge from './badges/ell'
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'
import { displayGradeLevel } from 'helpers/presenters'
import Actions from 'students/actions'
import SrptBadge from 'shared/badges/srpt'
import SrptCompleteBadge from 'shared/badges/srpt-complete'
import { isNull } from 'lodash'
import moment from 'moment'

function StudentsTable({ data, setOrderBy, orderBy, setOrderAsc, orderAsc }) {
  const updateOrdering = (column) => {
    column === orderBy ? setOrderAsc(!orderAsc) : setOrderAsc('asc')
    setOrderBy(column)
  }

  const findIcon = (column) => {
    if (column === orderBy) {
      return orderAsc ? (
        <FontAwesomeIcon icon={faSortUp} />
      ) : (
        <FontAwesomeIcon icon={faSortDown} />
      )
    } else {
      return <FontAwesomeIcon icon={faSort} />
    }
  }

  return (
    <Table responsive className="border bg-white">
      <thead>
        <tr>
          <th
            style={{ cursor: 'pointer' }}
            className="text-info"
            onClick={() => updateOrdering('last_name')}
          >
            Name{' '}
            <span
              className={`float-right ${
                'last_name' === orderBy ? 'text-primary' : 'text-muted'
              }`}
            >
              {findIcon('last_name')}
            </span>
          </th>
          <th
            style={{ cursor: 'pointer' }}
            className="text-info text-center"
            onClick={() => updateOrdering('sis_id')}
          >
            ID
            <span
              className={`float-right ${
                'sis_id' === orderBy ? 'text-primary' : 'text-muted'
              }`}
            >
              {findIcon('sis_id')}
            </span>
          </th>
          <th
            style={{ cursor: 'pointer' }}
            className="text-info text-center"
            onClick={() => updateOrdering('grade_level')}
          >
            Grade{' '}
            <span
              className={`float-right ${
                'grade_level' === orderBy ? 'text-primary' : 'text-muted'
              }`}
            >
              {findIcon('grade_level')}
            </span>
          </th>
          <th className="text-center">Latest Score</th>
          <th className="text-info text-center">APP/IPRI</th>
        </tr>
      </thead>
      <tbody>
        {!!data &&
          data.map((student) => (
            <tr key={`student-row-${student.id}`}>
              <td>
                <Link to={`/students/${student.id}`}>
                  {student.last_name}, {student.first_name}
                </Link>
                <span className="ml-1">
                  <RsaStatusBadge studentPathway={student.student_pathway} />
                </span>
                {student.sped && <SpedBadge />}
                {student.ell && <EllBadge />}
                {!isNull(student.student_pathway) &&
                  [5, 6, 7].includes(moment().month()) &&
                  student.student_pathway.pathway_id === 4 && (
                    <span className="float-right">
                      {student.meetings.length === 0 ? (
                        <SrptBadge student={student} />
                      ) : (
                        <SrptCompleteBadge student={student} />
                      )}
                    </span>
                  )}
              </td>

              <td>
                <span className="text-center text-muted font-weight-light ml-2">
                  {student.sis_id}
                </span>
              </td>

              <td>
                <span className="text-center text-muted font-weight-light">
                  {displayGradeLevel(student.grade_level)}
                </span>
              </td>
              <td className="text-center">
                {riskColor(student.latest_percentile)}
              </td>
              <td className="text-center">
                <ProgressPlanIcon student={student} />
              </td>
              <td className="text-center">
                <Actions student={student} size="sm" />
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  )
}

export default StudentsTable
