import React from 'react'
import Badge from 'react-bootstrap/Badge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

function SmallExemptBadge() {
  return (
    <OverlayTrigger
      key="top"
      placement="top"
      trigger={['hover', 'focus']}
      overlay={<Tooltip id={'tooltip'}>Above RSA Exemption score</Tooltip>}
    >
      <Badge
        variant="white"
        className={'text-success'}
        style={{background: '#ecf6f1', cursor: 'pointer'}}
        pill
      >
        {<FontAwesomeIcon icon={faCheck} />}
      </Badge>
    </OverlayTrigger>
  )
}

export default SmallExemptBadge
