import React from 'react'
import { Redirect } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

function UnauthenticatedApp() {
  const { loginWithRedirect } = useAuth0()

  return (
    <Redirect to={loginWithRedirect()} />
  )
}

export default UnauthenticatedApp
