import React from 'react'
import Table from 'react-bootstrap/Table'
import { useHistory } from 'react-router-dom'
import { displayGradeLevel } from 'helpers/presenters'

function ExemptionsTable({ exemptionData }) {
  const history = useHistory()

  const redirectToStudentsWithGradeFilter = (grade) => {
    history.push(`/students?grade_levels=${grade}`)
  }

  const redirectToStudentsWithGradeAndExemptStatus = (grade, exemptStatus) => {
    history.push(`/students?grade_levels=${grade}&exempt=${exemptStatus}`)
  }

  return (
    <Table
      responsive
      id="clickable-table"
      className="border bg-white shadow-sm"
    >
      <thead>
        <tr>
          <th className="text-info border-bottom-0">Grade</th>
          <th className="text-info text-center border-bottom-0">Exempt</th>
          <th className="text-info text-center border-bottom-0">Not Exempt</th>
          <th className="text-info text-center border-bottom-0">Probation</th>
        </tr>
      </thead>
      <tbody>
        {exemptionData.map((d) => (
          <tr key={`exemptions-grade-${d.grade_level}`}>
            <td
              onClick={() => redirectToStudentsWithGradeFilter(d.grade_level)}
              style={{ cursor: 'pointer' }}
            >
              {displayGradeLevel(d.grade_level)}
            </td>
            <td
              className="text-center"
              onClick={() =>
                redirectToStudentsWithGradeAndExemptStatus(
                  d.grade_level,
                  'exempt'
                )
              }
              style={{ cursor: 'pointer' }}
            >
              {d.exemptions_count}
            </td>
            <td
              className="text-center"
              onClick={() =>
                redirectToStudentsWithGradeAndExemptStatus(
                  d.grade_level,
                  'not_exempt'
                )
              }
              style={{ cursor: 'pointer' }}
            >
              {d.non_exempts_count}
            </td>
            <td
              className="text-center"
              onClick={() =>
                redirectToStudentsWithGradeAndExemptStatus(
                  d.grade_level,
                  'probation'
                )
              }
              style={{ cursor: 'pointer' }}
            >
              {d.probations_count}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default ExemptionsTable
