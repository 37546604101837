import { audience, baseUrl } from 'api/helpers'

export async function createApproval({ params, token }) {
  const url = `${baseUrl}/students/${params.student_id}/approvals`
  const response = await fetch(url, {
    method: 'POST',
    audience,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
  const data = await response.json()
  return data
}

export async function destroyApproval({ id, token }) {
  const url = `${baseUrl}/approvals/${id}`
  const response = await fetch(url, {
    method: 'DELETE',
    audience,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  const data = await response.json()
  return data
}

export async function updateApproval({ params, token }) {
  const url = `${baseUrl}/approvals/${params.id}`
  const response = await fetch(url, {
    method: 'PATCH',
    audience,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({approval: params}),
  })
  const data = await response.json()
  return data
}