import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faEdit, faCheck } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Datetime from 'react-datetime'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import {
  createAcademicPlanConference,
  updateAcademicPlanConference,
} from 'api/academic_plan_conferences'
import { useMutation, useQueryClient } from 'react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { audience } from 'api/helpers'
import { toast } from 'react-toastify'
import moment from 'moment'
import { formatDateSlash } from 'helpers/datetime'
import { isNull } from 'lodash'
import { currentTerm } from 'helpers/school-dates'

function ConferenceModal({ plan, oldConference, readOnly }) {
  const queryClient = useQueryClient()
  const { getAccessTokenSilently } = useAuth0()
  const [show, setShow] = useState(false)
  const [validated, setValidated] = useState(false)
  const [noSignatures, setNoSignatures] = useState(true)
  const [virtualBlank, setVirtualBlank] = useState(false)
  const [parentAbsent, setParentAbsent] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const emptyState = {
    id: null,
    date: moment(),
    term: currentTerm(),
    guardian: '',
    guardian_accepted: false,
    current_teacher: '',
    current_teacher_accepted: false,
    next_grade_teacher: '',
    next_grade_teacher_accepted: false,
    principal: '',
    principal_accepted: false,
    reading_specialist: '',
    reading_specialist_accepted: false,
    other: '',
    other_accepted: false,
    parent_unable_signature: '',
    is_parent_absent: false,
    virtual_conference: false,
    signature_virtual_role: '',
    signature_virtual_string: '',
    mailed_on: '',
  }

  const [conference, setConference] = useState(oldConference || emptyState)

  const invalidateQueriesAndForm = (_) => {
    queryClient.invalidateQueries(`academic_progress_plans/${plan.id}`)
    queryClient.invalidateQueries(
      `academic_progress_plans/${plan.id}/conferences`
    )
    setValidated(false)
  }

  const newConference = useMutation(
    ({ params, plan, token }) =>
      createAcademicPlanConference({ params, plan, token }),
    {
      onSuccess: () => {
        invalidateQueriesAndForm()
      },
    }
  )

  const createNewConference = async () => {
    const token = await getAccessTokenSilently({ audience })
    newConference.mutate({ params: conference, plan: plan, token })
    toast.success('Added conference to academic plan')
  }

  const updateOldConference = async () => {
    const token = await getAccessTokenSilently({ audience })

    updateConference.mutate({ params: conference, plan: plan, token })
    toast.warning('Updated conference')
  }

  const updateConference = useMutation(
    ({ params, plan, token }) =>
      updateAcademicPlanConference({ params, token }),
    {
      onSuccess: async () => {
        invalidateQueriesAndForm()
      },
    }
  )

  const handleSubmit = (e) => {
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    } else {
      e.preventDefault()
      if (isNull(conference.id)) {
        createNewConference()
        setConference(emptyState)
      } else {
        updateOldConference()
      }
      handleClose()
    }
    setValidated(true)
  }

  useEffect(() => {
    if (
      !!conference.current_teacher ||
      !!conference.next_grade_teacher ||
      !!conference.principal ||
      !!conference.reading_specialist ||
      !!conference.other
    ) {
      setNoSignatures(false)
    }
  }, [
    conference.current_teacher,
    conference.next_grade_teacher,
    conference.principal,
    conference.reading_specialist,
    conference.other,
  ])

  useEffect(() => {
    if (
      conference.virtual_conference &&
      (conference.signature_virtual_role === '' ||
        conference.signature_virtual_string)
    ) {
      setVirtualBlank(true)
    }
  }, [
    conference.virtual_conference,
    conference.signature_virtual_role,
    conference.signature_virtual_string,
  ])

  useEffect(() => {
    if (
      conference.is_parent_absent &&
      conference.parent_unable_signature === ''
    ) {
      setParentAbsent(true)
    }
  }, [conference.is_parent_absent, conference.parent_unable_signature])

  return (
    <React.Fragment>
      {isNull(conference.id) && !readOnly && (
        <Button variant="light" size="sm" onClick={handleShow}>
          <span className="text-info">
            <FontAwesomeIcon icon={faPlus} /> Add Conference
          </span>
        </Button>
      )}
      {!isNull(conference.id) && (
        <Button
          className="text-warning mx-2"
          variant="light"
          size="sm"
          onClick={handleShow}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      )}
      <Modal show={show} onHide={handleClose} size="lg">
        <Form
          className="needs-validation"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <Modal.Header closeButton>
            <Modal.Title>Conference</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mt-2">
              <Col sm={6}>
                <Form.Label className="mt-1">Date</Form.Label>
                <Datetime
                  placeholder="Date of Conference"
                  dateFormat="MM/DD/YYYY"
                  required
                  inputProps={{ disabled: readOnly }}
                  timeFormat={false}
                  value={formatDateSlash(conference.date)}
                  onChange={(e) => setConference({ ...conference, date: e })}
                />
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label className="mt-1">Term</Form.Label>
                  <Form.Control
                    as="select"
                    disabled={readOnly}
                    required
                    value={conference.term}
                    onChange={(e) =>
                      setConference({ ...conference, term: e.target.value })
                    }
                  >
                    <option value="">Select Term</option>
                    <option value="fall">Fall</option>
                    <option value="winter">Winter</option>
                    <option value="spring">Spring</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col>
                {validated && noSignatures && (
                  <div className="text-danger">
                    At least one staff signature is required
                  </div>
                )}
                <Table responsive size="sm">
                  <thead>
                    <tr>
                      <th>Role</th>
                      <th>Signature</th>
                      <th style={{ minWidth: '65px' }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Parent/Guardian</td>
                      <td>
                        {conference.guardian_accepted ? (
                          <Form.Control
                            size="sm"
                            type="text"
                            value={conference.guardian}
                            disabled={readOnly}
                            onChange={(e) =>
                              setConference({
                                ...conference,
                                guardian: e.target.value,
                              })
                            }
                            placeholder="Type to Sign"
                          />
                        ) : (
                          <>
                            <Button
                              variant="light"
                              size="sm"
                              disabled={readOnly}
                              onClick={(e) =>
                                setConference({
                                  ...conference,
                                  guardian_accepted: true,
                                })
                              }
                            >
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="text-primary mr-1"
                              />
                              Accept
                            </Button>
                            <small className="ml-2">
                              I agree to sign this document electronically
                            </small>
                          </>
                        )}
                      </td>
                      <td>
                        {conference.guardian_accepted ? (
                          <Button
                            variant="light"
                            size="sm"
                            disabled={readOnly}
                            onClick={(e) =>
                              setConference({
                                ...conference,
                                guardian: '',
                                guardian_accepted: false,
                              })
                            }
                          >
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              className="text-danger mr-1"
                            />
                            Remove
                          </Button>
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <td>Teacher</td>
                      <td>
                        {conference.current_teacher_accepted ? (
                          <Form.Control
                            size="sm"
                            type="text"
                            disabled={readOnly}
                            required={noSignatures}
                            value={conference.current_teacher}
                            onChange={(e) =>
                              setConference({
                                ...conference,
                                current_teacher: e.target.value,
                              })
                            }
                            placeholder="Type to Sign"
                          />
                        ) : (
                          <>
                            <Button
                              variant="light"
                              size="sm"
                              disabled={readOnly}
                              onClick={(e) =>
                                setConference({
                                  ...conference,
                                  current_teacher_accepted: true,
                                })
                              }
                            >
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="text-primary mr-1"
                              />
                              Accept
                            </Button>
                            <small className="ml-2">
                              I agree to sign this document electronically
                            </small>
                          </>
                        )}
                      </td>
                      <td>
                        {conference.current_teacher_accepted ? (
                          <Button
                            variant="light"
                            size="sm"
                            disabled={readOnly}
                            onClick={(e) =>
                              setConference({
                                ...conference,
                                current_teacher: '',
                                current_teacher_accepted: false,
                              })
                            }
                          >
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              className="text-danger mr-1"
                            />
                            Remove
                          </Button>
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <td>Next Grade Teacher</td>
                      <td>
                        {conference.next_grade_teacher_accepted ? (
                          <Form.Control
                            size="sm"
                            disabled={readOnly}
                            value={conference.next_grade_teacher}
                            onChange={(e) =>
                              setConference({
                                ...conference,
                                next_grade_teacher: e.target.value,
                              })
                            }
                            type="text"
                            required={noSignatures}
                            placeholder="Type to Sign"
                          />
                        ) : (
                          <>
                            <Button
                              variant="light"
                              size="sm"
                              disabled={readOnly}
                              onClick={(e) =>
                                setConference({
                                  ...conference,
                                  next_grade_teacher_accepted: true,
                                })
                              }
                            >
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="text-primary mr-1"
                              />
                              Accept
                            </Button>
                            <small className="ml-2">
                              I agree to sign this document electronically
                            </small>
                          </>
                        )}
                      </td>
                      <td>
                        {conference.next_grade_teacher_accepted ? (
                          <Button
                            variant="light"
                            size="sm"
                            disabled={readOnly}
                            onClick={(e) =>
                              setConference({
                                ...conference,
                                next_grade_teacher: '',
                                next_grade_teacher_accepted: false,
                              })
                            }
                          >
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              className="text-danger mr-1"
                            />
                            Remove
                          </Button>
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <td>Principal</td>
                      <td>
                        {conference.principal_accepted ? (
                          <Form.Control
                            size="sm"
                            disabled={readOnly}
                            value={conference.principal}
                            onChange={(e) =>
                              setConference({
                                ...conference,
                                principal: e.target.value,
                              })
                            }
                            type="text"
                            required={noSignatures}
                            placeholder="Type to Sign"
                          />
                        ) : (
                          <>
                            <Button
                              variant="light"
                              size="sm"
                              disabled={readOnly}
                              onClick={(e) =>
                                setConference({
                                  ...conference,
                                  principal_accepted: true,
                                })
                              }
                            >
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="text-primary mr-1"
                              />
                              Accept
                            </Button>
                            <small className="ml-2">
                              I agree to sign this document electronically
                            </small>
                          </>
                        )}
                      </td>
                      <td>
                        {conference.principal_accepted ? (
                          <Button
                            variant="light"
                            size="sm"
                            disabled={readOnly}
                            onClick={(e) =>
                              setConference({
                                ...conference,
                                principal: '',
                                principal_accepted: false,
                              })
                            }
                          >
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              className="text-danger mr-1"
                            />
                            Remove
                          </Button>
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <td>Reading Specialist</td>
                      <td>
                        {conference.reading_specialist_accepted ? (
                          <Form.Control
                            size="sm"
                            disabled={readOnly}
                            value={conference.reading_specialist}
                            onChange={(e) =>
                              setConference({
                                ...conference,
                                reading_specialist: e.target.value,
                              })
                            }
                            type="text"
                            required={noSignatures}
                            placeholder="Type to Sign"
                          />
                        ) : (
                          <>
                            <Button
                              variant="light"
                              size="sm"
                              disabled={readOnly}
                              onClick={(e) =>
                                setConference({
                                  ...conference,
                                  reading_specialist_accepted: true,
                                })
                              }
                            >
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="text-primary mr-1"
                              />
                              Accept
                            </Button>
                            <small className="ml-2">
                              I agree to sign this document electronically
                            </small>
                          </>
                        )}
                      </td>
                      <td>
                        {conference.reading_specialist_accepted ? (
                          <Button
                            variant="light"
                            size="sm"
                            disabled={readOnly}
                            onClick={(e) =>
                              setConference({
                                ...conference,
                                reading_specialist: '',
                                reading_specialist_accepted: false,
                              })
                            }
                          >
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              className="text-danger mr-1"
                            />
                            Remove
                          </Button>
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <td>Other</td>
                      <td>
                        {conference.other_accepted ? (
                          <Form.Control
                            size="sm"
                            disabled={readOnly}
                            value={conference.other}
                            onChange={(e) =>
                              setConference({
                                ...conference,
                                other: e.target.value,
                              })
                            }
                            type="text"
                            required={noSignatures}
                            placeholder="Type to Sign"
                          />
                        ) : (
                          <>
                            <Button
                              variant="light"
                              size="sm"
                              disabled={readOnly}
                              onClick={(e) =>
                                setConference({
                                  ...conference,
                                  other_accepted: true,
                                })
                              }
                            >
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="text-primary mr-1"
                              />
                              Accept
                            </Button>
                            <small className="ml-2">
                              I agree to sign this document electronically
                            </small>
                          </>
                        )}
                      </td>
                      <td>
                        {conference.other_accepted ? (
                          <Button
                            variant="light"
                            size="sm"
                            disabled={readOnly}
                            onClick={(e) =>
                              setConference({
                                ...conference,
                                other: '',
                                other_accepted: false,
                              })
                            }
                          >
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              className="text-danger mr-1"
                            />
                            Remove
                          </Button>
                        ) : null}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col>
                {validated && virtualBlank && (
                  <div className="text-danger">
                    Please complete all fields for a virtual conference
                  </div>
                )}
                <Card bg="light">
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          checked={conference.virtual_conference}
                          disabled={readOnly}
                          onChange={(e) =>
                            setConference({
                              ...conference,
                              virtual_conference:
                                !conference.virtual_conference,
                            })
                          }
                          label="Conference was held virtually. I attest that the undersigned were all present for this conference"
                        />
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            value={conference.signature_virtual_string}
                            disabled={readOnly}
                            onChange={(e) =>
                              setConference({
                                ...conference,
                                signature_virtual_string: e.target.value,
                              })
                            }
                            required={conference.virtual_conference}
                            placeholder="Type to Sign"
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            required={conference.virtual_conference}
                            disabled={readOnly}
                            value={conference.signature_virtual_role}
                            onChange={(e) =>
                              setConference({
                                ...conference,
                                signature_virtual_role: e.target.value,
                              })
                            }
                            placeholder="Job Title/Role"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col>
                {validated && parentAbsent && (
                  <div className="text-danger">
                    Please type your name to sign
                  </div>
                )}
                <Card bg="light">
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          disabled={readOnly}
                          checked={conference.is_parent_absent}
                          onChange={(e) =>
                            setConference({
                              ...conference,
                              is_parent_absent: !conference.is_parent_absent,
                            })
                          }
                          label="A parent/guardian was unable to attend parent-teacher conference. A written reading progress update was mailed to the parent/guardian."
                        />
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            placeholder="Type to Sign"
                            disabled={readOnly}
                            value={conference.parent_unable_signature}
                            required={conference.is_parent_absent}
                            onChange={(e) =>
                              setConference({
                                ...conference,
                                parent_unable_signature: e.target.value,
                              })
                            }
                          />
                        </Form.Group>

                        <Datetime
                          placeholder="Mailed on"
                          dateFormat="MM/DD/YYYY"
                          inputProps={{ disabled: readOnly }}
                          timeFormat={false}
                          value={formatDateSlash(conference.mailed_on)}
                          onChange={(e) =>
                            setConference({ ...conference, mailed_on: e })
                          }
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={handleClose}>
              Close
            </Button>
            {noSignatures ? (
              <Button variant="outline-danger" disabled>
                Need at least one signature to save
              </Button>
            ) : (
              <Button
                variant={readOnly ? 'light' : 'primary'}
                type="submit"
                disabled={readOnly}
              >
                Save
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default ConferenceModal
