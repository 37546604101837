import React, { useState, useContext, useEffect } from 'react'
import Nav from 'react-bootstrap/Nav'
import Accordion from 'react-bootstrap/Accordion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDown,
  faAngleUp,
  faSchool,
  faUserGraduate,
  faUserCircle,
  faBars,
  faSignOutAlt,
  faChartLine,
  faCrown,
  faFolder,
} from '@fortawesome/free-solid-svg-icons'
import Logo from 'logo-dark.svg'
import { useAuth0 } from '@auth0/auth0-react'
import UserContext from 'context/user-context'
import { isEmpty } from 'lodash'
import {
  isSuperAdmin,
  hasSchoolLevelAccess,
  hasDistrictLevelAccess,
} from 'helpers/user-access'

function Sidebar() {
  const currentUser = useContext(UserContext)
  const [activeReports, setActiveReports] = useState(false)
  const [current, setCurrent] = useState('')
  const [collapsed, setCollapsed] = useState(false)
  const { logout } = useAuth0()

  useEffect(() => {
    const currentPath = window.location.pathname
    if (currentPath.includes('schools')) {
      setCurrent('schools')
    } else if (currentPath.includes('students')) {
      setCurrent('students')
    } else if (currentPath.includes('documentation')) {
      setCurrent('documentation')
    } else if (currentPath.includes('reports')) {
      setCurrent('reports')
    }
  }, [])

  return (
    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar" className={`bg-info ${collapsed ? 'active' : ''}`}>
        <div className="custom-menu sticky-top">
          <button
            type="button"
            className="btn btn-primary"
            onClick={(_) => setCollapsed(!collapsed)}
          >
            <FontAwesomeIcon icon={faBars} />
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <h1>
            {' '}
            <a className="navbar-brand m-4 px-3 pt-2" href="/">
              <img src={Logo} className="img-fluid w-100" alt="" />
            </a>
          </h1>
          <button
            className="btn btn-light btn-block text-left mb-3 rounded"
            style={{ borderRadius: '0px' }}
          >
            <FontAwesomeIcon className="text-info" icon={faUserCircle} />
            <span className="text-info">
              {' '}
              {currentUser.first_name || '...'} {currentUser.last_name || ''}
            </span>
          </button>

          <ul className="list-unstyled components mb-5">
            <li className="active">
              {!isEmpty(currentUser) && hasSchoolLevelAccess(currentUser.role) && (
                <a
                  className={`rounded pl-2 nav-link ${
                    current === 'schools' ? 'text-white' : 'text-light'
                  }`}
                  style={{ background: current === 'schools' ? '#1f5a9b' : '' }}
                  href={
                    hasDistrictLevelAccess(currentUser.role)
                      ? '/schools'
                      : `/schools/${currentUser.defaultSchool.id}`
                  }
                >
                  <span data-feather="file"></span>
                  <FontAwesomeIcon icon={faSchool} /> Schools
                </a>
              )}
            </li>
            <li>
              <a
                className={`rounded pl-2 nav-link ${
                  current === 'students' ? 'text-white' : 'text-light'
                }`}
                style={{ background: current === 'students' ? '#1f5a9b' : '' }}
                href="/students"
              >
                <span data-feather="file"></span>
                <FontAwesomeIcon icon={faUserGraduate} /> Students
              </a>
            </li>
            <li>
              {!isEmpty(currentUser) && hasSchoolLevelAccess(currentUser.role) && (
                <a
                  className={`rounded pl-2 nav-link ${
                    current === 'third' ? 'text-white' : 'text-light'
                  }`}
                  style={{ background: current === 'third' ? '#1f5a9b' : '' }}
                  href="/approval"
                >
                  <span data-feather="file"></span>
                  <FontAwesomeIcon icon={faFolder} /> Documentation (3rd)
                </a>
              )}
            </li>

            {!isEmpty(currentUser) && hasSchoolLevelAccess(currentUser.role) && (
              <li>
                <Accordion>
                  <Accordion.Toggle
                    className="text-white pl-2"
                    as={Nav.Link}
                    eventKey="reports"
                    onClick={() => setActiveReports(!activeReports)}
                  >
                    <FontAwesomeIcon icon={faChartLine} /> Reports
                    {activeReports ? (
                      <span className="float-right">
                        <FontAwesomeIcon icon={faAngleUp} />
                      </span>
                    ) : (
                      <span className="float-right">
                        <FontAwesomeIcon icon={faAngleDown} />
                      </span>
                    )}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="reports">
                    <div>
                      <a
                        className="nav-link text-white ml-4"
                        href="/reports/beginning"
                      >
                        Beginning of Year
                      </a>
                      <a
                        className="nav-link text-white ml-4"
                        href="/reports/end"
                      >
                        End of Year
                      </a>
                      <a
                        className="nav-link text-white ml-4"
                        href="/reports/testing-window"
                      >
                        Testing Window
                      </a>
                    </div>
                  </Accordion.Collapse>
                </Accordion>
              </li>
            )}

            <li className="active">
              {!isEmpty(currentUser) &&
                isSuperAdmin(currentUser.role) &&
                currentUser.email === 'htmitchell90@gmail.com' && (
                  <a
                    className={`rounded pl-2 nav-link ${
                      current === 'districts' ? 'text-white' : 'text-light'
                    }`}
                    style={{
                      background: current === 'districts' ? '#1f5a9b' : '',
                    }}
                    href={`/districts`}
                  >
                    <FontAwesomeIcon icon={faCrown} /> Admin
                  </a>
                )}
            </li>
            <li>
              <button
                className="nav-link btn-link btn-block btn pl-2 text-left"
                style={{ color: '#DBDCDE' }}
                onClick={() => logout({ returnTo: window.location.origin })}
                href="#"
              >
                <FontAwesomeIcon icon={faSignOutAlt} /> Log Out
              </button>
            </li>
          </ul>
          <div className="footer"></div>
        </div>
      </nav>
    </div>
  )
}

export default Sidebar
