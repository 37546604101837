import React from 'react'
import AuthenticatedApp from './authenticated-app'
import UnauthenticatedApp from './unauthenticated-app'
import { useAuth0 } from '@auth0/auth0-react'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { split } from 'lodash'
import { Redirect } from 'react-router-dom'
import Page from 'containers/page'
import LoadingStatus from 'helpers/loading-status'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://f6dcf534bd5d486db951ae0b97ec641c@o1003066.ingest.sentry.io/5963424",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.5
  });
}

function App() {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()
  const queryClient = new QueryClient()
  const fullPath = window.location.pathname
  const path = split(fullPath, '/')[1]

  if (isLoading) {
    return (
      <Page>
        <LoadingStatus status={isLoading} />
      </Page>
    )
  }

  if (path === 'login') {
    <Redirect to={loginWithRedirect()} />
  }

  return (
    <div>
      {isAuthenticated ? (
        <QueryClientProvider client={queryClient}>
          <AuthenticatedApp />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      ) : (
        <UnauthenticatedApp />
      )}
    </div>
  )
}

export default App
