import React from 'react'
import Form from 'react-bootstrap/Form'

function Powerschool() {
  return (
    <div>
      <Form.Group>
        <Form.Control type="text" placeholder="PowerSchool URL (https://example.powerschool.org)" />
      </Form.Group>
      <Form.Group>
        <Form.Control type="text" placeholder="Client ID" />
      </Form.Group>
      <Form.Group>
        <Form.Control type="text" placeholder="Client Secret" />
      </Form.Group>
    </div>
  )
}

export default Powerschool
