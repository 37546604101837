import React from 'react'
import Card from 'react-bootstrap/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

function StatusCard({ plan, setPlan, statusType, title, readOnly }) {
  const helperText = () => {
    if (statusType === 'first_time') {
      return 'This is the first time since public school enrollment that the student has been placed on an IPRI.'
    } else if (statusType === 'previously_met_target') {
      return 'The K-3 student has previously had an IPRI, but successfully met the grade-level targets and was removed.'
    } else if (statusType === 'previously_not_met') {
      return 'The K-3 student has previously had an IPRI, but has not yet met the grade-level targets.'
    } else if (statusType === 'probationary_promoted') {
      return 'The 4th or 5th grade student did not meet RSA criteria, was promoted with a good-cause exemption or with probation by the SRPT.'
    } else if (statusType === 'tested_out') {
      return 'The current IPRI is no longer needed because the student met the benchmark'
    }
  }

  return (
    <React.Fragment>
      {readOnly ? (
        <Card
          border={plan.status === statusType ? 'primary' : ''}
          className="h-100 mr-2"
          style={{
            background: plan.status === statusType ? '#eaf4fa' : '#efefef',
          }}
        >
          <Card.Body>
            <span className="font-weight-bold float-left">{title}</span>{' '}
            {plan.status === statusType ? (
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="float-right text-primary"
              />
            ) : null}
            <div className="mt-2 float-left font-weight-light">
              {helperText()}
            </div>
          </Card.Body>
        </Card>
      ) : (
        <Card
          onClick={(e) => setPlan({ ...plan, status: statusType })}
          border={plan.status === statusType ? 'primary' : ''}
          className="h-100 mr-2"
          style={{
            cursor: 'pointer',
            background: plan.status === statusType ? '#eaf4fa' : '',
          }}
        >
          <Card.Body>
            <span className="font-weight-bold float-left">{title}</span>{' '}
            {plan.status === statusType ? (
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="float-right text-primary"
              />
            ) : null}
            <div className="mt-2 float-left font-weight-light">
              {helperText()}
            </div>
          </Card.Body>
        </Card>
      )}
    </React.Fragment>
  )
}

export default StatusCard
