import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import ConferenceModal from './conference-modal'
import {
  fetchAcademicPlanConferences,
  destroyAcademicPlanConference,
} from 'api/academic_plan_conferences'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery, useQueryClient, useMutation } from 'react-query'
import { audience } from 'api/helpers'
import { titleize } from 'helpers/utils'
import { formatDate } from 'helpers/datetime'
import RequiredField from 'helpers/required-field'
import InfoBadge from 'students/badges/info-badge'
import ConfirmDelete from 'helpers/confirm-delete'

function AcademicPlanConferences({ plan, readOnly }) {
  const queryClient = useQueryClient()
  const { getAccessTokenSilently } = useAuth0()
  const { isSuccess, data: conferences } = useQuery(
    `academic_progress_plans/${plan.id}/conferences`,
    async () => {
      const token = await getAccessTokenSilently({ audience })
      const data = await fetchAcademicPlanConferences({ plan, token })
      return data
    }
  )

  const deleteConferenceMutation = useMutation(
    ({ id, token }) => destroyAcademicPlanConference({ id, token }),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(
          `academic_progress_plans/${plan.id}/conferences`
        )
      },
    }
  )

  const handleDelete = async (id) => {
    const token = await getAccessTokenSilently({ audience })
    deleteConferenceMutation.mutate({
      id: id,
      token: token,
    })
  }

  return (
    <>
      <Row>
        <Col sm={3}>
          <h5>
            Conferences <RequiredField />
          </h5>
        </Col>
        <Col sm={9}>
          <Table responsive bordered size="sm">
            <thead>
              <tr>
                <th>Term</th>
                <th>Date</th>
                <th>Attendees</th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {isSuccess &&
                conferences.map((c) => (
                  <tr key={`conference-${c.id}`}>
                    <td>
                      {titleize(c.term)}{' '}
                      {c.virtual_conference ? (
                        <InfoBadge
                          tooltipText="Meeting was virtual"
                          icon="laptop"
                        />
                      ) : null}{' '}
                      {c.is_parent_present ? (
                        <InfoBadge
                          tooltipText="Parent was not present"
                          icon="user"
                        />
                      ) : null}
                    </td>
                    <td className="text-center">{formatDate(c.date)}</td>
                    <td className="text-center">{c.attendees}</td>
                    <td className="text-center">
                      <ConferenceModal
                        plan={plan}
                        oldConference={c}
                        readOnly={readOnly}
                      />
                      <ConfirmDelete
                        id={c.id}
                        handleDelete={handleDelete}
                        readOnly={readOnly}
                      />
                    </td>
                  </tr>
                ))}
              <tr>
                <td colSpan="4" className="text-center">
                  <ConferenceModal plan={plan} readOnly={readOnly} />
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  )
}

export default AcademicPlanConferences
