import React from 'react'
import { Alert } from 'react-bootstrap'

function ErrorAlert({errorMessage}) {
  return (
    <div className="text-center">
      <Alert variant="danger">Uh oh, you got an error: {errorMessage}</Alert>
    </div>
  )
}

export default ErrorAlert
