import { audience, baseUrl } from 'api/helpers'

export const fetchPathways = async (token) => {
  const url = `${baseUrl}/pathways`
  const response = await fetch(url, {
    audience,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const data = await response.json()
  return data
}
