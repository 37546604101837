import React from 'react'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'


function ActiveToggle({ params, setParams }) {
  return (
    <ButtonGroup>
      <Button
        onClick={(e) => setParams({ ...params, active: true })}
        variant={params.active ? 'primary' : 'light'}
      >
        Active
      </Button>
      <Button
        onClick={(e) => setParams({ ...params, active: false })}
        variant={params.active ? 'light' : 'secondary'}
      >
        Inactive
      </Button>
    </ButtonGroup>
  )
}

export default ActiveToggle