import { useContext } from 'react'
import { useMutation } from 'react-query'
import { apiPatch, apiPost } from 'api'
import AuthContext from 'context/auth-context'

const updateUser = async (token, params) => {
  const objectName = 'user'
  const url = `users/${params.id}`

  apiPatch({ url, token, objectName, params })
}

export function useUpdateUser({ params, options }) {
  const token = useContext(AuthContext)

  return useMutation(
    () => {
      return updateUser(token, params)
    },
    { ...options }
  )
}

const createUser = async (token, params) => {
  const objectName = 'user'
  const url = `users`

  apiPost({ url, token, objectName, params })
}

export function useCreateUser({ params }) {
  const token = useContext(AuthContext)
  return useMutation(() => {
    return createUser(token, params)
  })
}
