import { audience, baseUrl } from 'api/helpers'

export const fetchSchoolExemptions = async (schoolId, token) => {
  const url = `${baseUrl}/school_exemptions?school_id=${schoolId}`
  const response = await fetch(url, {
    audience,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const data = await response.json()
  return data
}
