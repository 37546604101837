import React from 'react'
import Card from 'react-bootstrap/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

function SkillCard({ plan, setPlan, field, currentValue, title, readOnly }) {
  return (
    <React.Fragment>
      {readOnly ? (
        <Card
          border={currentValue ? 'primary' : '#efefef'}
          className="mr-2 mt-2"
          style={{
            cursor: 'pointer',
            background: currentValue ? '#eaf4fa' : '#efefef',
          }}
        >
          <Card.Body>
            <span className="font-weight-bold float-left">{title}</span>{' '}
            {currentValue && (
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="float-right text-primary"
              />
            )}
          </Card.Body>
        </Card>
      ) : (
        <Card
          onClick={(e) =>
            setPlan({
              ...plan,
              [field]: !currentValue,
            })
          }
          border={currentValue ? 'primary' : ''}
          className="mr-2 mt-2"
          style={{
            cursor: 'pointer',
            background: currentValue ? '#eaf4fa' : '',
          }}
        >
          <Card.Body>
            <span className="font-weight-bold float-left">{title}</span>{' '}
            {currentValue && (
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="float-right text-primary"
              />
            )}
          </Card.Body>
        </Card>
      )}
    </React.Fragment>
  )
}

export default SkillCard
