export function titleize(sentence) {
  if (!sentence || !sentence.split) return sentence

  const _titleizeWord = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()

  sentence = sentence.replace(/_/g, ' ')
  sentence = sentence.replace('/', ' / ')
  const result = sentence.split(' ').map((word) => _titleizeWord(word))

  return result.join(' ')
}

export const mapObjectsToOptionsWithSubLabel = (
  arrayOfObj,
  labelKey = 'name',
  valKey = 'id',
  subLabelKey = 'content',
  category = 'category'
) =>
  arrayOfObj.map((obj) => ({
    label: obj[labelKey],
    value: obj[valKey],
    subLabel: obj[subLabelKey],
    category: obj[category],
  }))

export const calculatePercent = (num, denom) => {
  if (denom === 0 || num === null || denom === null) {
    return 0
  } else {
    return Math.round((100 * num) / denom)
  }
}
