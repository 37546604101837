import React from 'react'
import { Col, Row } from 'react-bootstrap'
import TextField from './text-field'
import ActiveToggle from './active-toggle'
import RoleDropdown from './role-dropdown'
import SchoolsDropdown from './schools'
import { Form } from 'react-bootstrap'

function UserForm({ formType, params, setParams, schools, user }) {
  return (
    <>
      <Row>
        <Col>
          <TextField
            label="First Name"
            accessor="first_name"
            setParams={setParams}
            params={params}
            placeholder="First Name"
          />
        </Col>
        <Col>
          <TextField
            label="Last Name"
            accessor="last_name"
            setParams={setParams}
            params={params}
            placeholder="Last Name"
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <TextField
            label="Email"
            accessor="email"
            setParams={setParams}
            params={params}
            placeholder="Email"
          />
        </Col>
        <Col>
          <TextField
            label="Position Title"
            accessor="position_title"
            setParams={setParams}
            params={params}
            placeholder="Position Title"
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <TextField
            label="Auth0 User ID"
            accessor="auth0_user_id"
            setParams={setParams}
            params={params}
            placeholder="Auth0 User ID"
          />
        </Col>
        <Col>
          <Form.Label>User Account Status</Form.Label>
          <div>
            <ActiveToggle params={params} setParams={setParams} />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <RoleDropdown params={params} setParams={setParams} />
        </Col>
      </Row>

      {formType === 'Edit' && (
        <SchoolsDropdown params={params} schools={schools} />
      )}
    </>
  )
}

export default UserForm
