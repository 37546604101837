import React from 'react'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faSortUp, faSort } from '@fortawesome/free-solid-svg-icons'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { calculatePercent } from 'helpers/utils'

function SchoolsTable({ schools }) {
  const TableRow = ({ school }) => {
    const inProgress = calculatePercent(school.plans_in_progress, school.plans_needed)
    const complete = calculatePercent(school.plans_complete, school.plans_needed)

    return (
      <tr key={`school-${school.id}`}>
        <td>
          <Link to={`/schools/${school.id}`}>{school.name}</Link>
        </td>
        <td className="text-center">{`${calculatePercent(school.exemptions_count, school.students_count)}%`}</td>
        <td className="text-center">
        <ProgressBar>
          <ProgressBar now={inProgress} label={`${inProgress}%`} variant="warning" />
          <ProgressBar now={complete} label={`${complete}%`} variant="success" />
        </ProgressBar>
        </td>
      </tr>
    )
  }

  return (
    <Table bordered responsive className="clickable-table bg-white">
      <thead>
        <tr>
          <th className="bold text-info">
            Schools
            <span className="float-right text-primary">
              <FontAwesomeIcon icon={faSortUp} />
            </span>
          </th>
          <th className="bold text-info text-center">
            <FontAwesomeIcon icon={faCheck} className="text-success mr-1" />
            Exempt <small>(3rd)</small>
            <span className="float-right text-muted">
              <FontAwesomeIcon icon={faSort} />
            </span>
          </th>
          <th className="bold text-info text-center">
            APP/IPRI Progress
            <span className="float-right text-muted">
              <FontAwesomeIcon icon={faSort} />
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        {schools.length === 0 &&
          <tr>
            <td colSpan='3'><p className='text-center'>No schools found...</p></td>
          </tr>
        }

        {schools.map((school) => (
          <TableRow key={`school-${school.id}`} school={school} />
        ))}
      </tbody>
    </Table>
  )
}

export default SchoolsTable
