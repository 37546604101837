import { audience, baseUrl } from 'api/helpers'

export const fetchDistrictSettings = async ({id, token}) => {
  const url = `${baseUrl}/districts/${id}/settings`
  const response = await fetch(url, {
    audience,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  const data = await response.json()
  return data
}
