import { audience, baseUrl } from 'api/helpers'

export const fetchDistrictUsers = async ({ districtId, token, params }) => {
  const url = new URL(`${baseUrl}/districts/${districtId}/users`)
  url.search = new URLSearchParams(params).toString()

  const response = await fetch(url, {
    ...params,
    audience,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })

  const data = await response.json()
  return data
}
