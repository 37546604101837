import { useContext } from 'react'
import { useMutation } from 'react-query'
import { apiPost, apiDelete } from 'api'
import AuthContext from 'context/auth-context'

const createUserGradeLevel = async (token, params) => {
  const objectName = 'user_grade_level'
  const url = '/user_grade_levels'

  apiPost({ url, token, objectName, params })
}

export function useCreateUserGradeLevel({ params }) {
  const token = useContext(AuthContext)
  return useMutation(() => {
    return createUserGradeLevel(token, params)
  })
}

const destroyUserGradeLevel = async (token, params) => {
  const objectName = 'user_grade_level'
  const url = `user_grade_levels/${params.id}`

  apiDelete({ url, token, objectName, params, method: 'DELETE' })
}

export function useDestroyUserGradeLevel({ params }) {
  const token = useContext(AuthContext)
  console.log('params', params)
  return useMutation(() => {
    return destroyUserGradeLevel(token, params)
  })
}
