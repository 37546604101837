import React, { useState, useContext, useEffect } from 'react'
import { fetchStudents } from 'api/students'
import { useQuery } from 'react-query'
import Page from 'containers/page'
import Title from 'helpers/title'
import ErrorStatus from 'helpers/error-status'
import LoadingStatus from 'helpers/loading-status'
import StudentsTable from './table'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { titleize } from 'helpers/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSearch,
  faCheck,
  faTimes,
  faParking,
} from '@fortawesome/free-solid-svg-icons'
import InputGroup from 'react-bootstrap/InputGroup'
import Popover from 'react-bootstrap/Popover'
import Overlay from 'react-bootstrap/Overlay'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import { displayGradeLevel } from 'helpers/presenters'
import SchoolSelect from 'schools/school-select'
import UserContext from 'context/user-context'
import SchoolContext from 'context/school-context'
import TableFooter from 'shared/tables/footer'
import AppPlanFilter from './filters/app-plan'
import { useAuth0 } from '@auth0/auth0-react'
import { audience } from 'api/helpers'

function Students() {
  const currentUser = useContext(UserContext)
  const { currentSchool } = useContext(SchoolContext)
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  /* TODO: Change to be an object with state */
  const [filterTarget, setFilterTarget] = useState(null)
  const [showFilters, setShowFilters] = useState(false)
  const [showRiskPopover, setShowRiskPopover] = useState(false)
  const [showGradeLevelFilters, setShowGradeLevelFilters] = useState(false)
  const [showExemptFilter, setShowExemptFilter] = useState(false)

  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(25)
  const [planFilter, setPlanFilter] = useState('')
  const [orderBy, setOrderBy] = useState('last_name')
  const [orderAsc, setOrderAsc] = useState(true)
  const [exemptFilterCheck, setExemptFilterCheck] = useState(
    urlParams.has('exempt') ? urlParams.get('exempt') : ''
  )

  const [gradeLevelFilter, setGradeLevelFilter] = useState(
    urlParams.has('grade_levels') ? [urlParams.get('grade_levels')] : []
  )

  const [riskFilter, setRiskFilter] = useState('')

  const [gradeLevelString, setGradeLevelString] = useState(
    gradeLevelFilter.join(',')
  )

  useEffect(() => {
    setGradeLevelString(
      gradeLevelFilter.map((g) => displayGradeLevel(g)).join(',')
    )
  }, [gradeLevelFilter])

  const params = {
    search: search,
    gradeLevels: gradeLevelFilter,
    exempt: exemptFilterCheck,
    risk: riskFilter,
    page: page,
    perPage: perPage,
    orderBy: orderBy,
    school_id: currentSchool?.id || '',
    orderAsc: orderAsc ? 'asc' : 'desc',
  }

  const { getAccessTokenSilently } = useAuth0()
  const {
    isLoading,
    isSuccess,
    isError,
    data: students,
  } = useQuery(['students', params], async () => {
    const token = await getAccessTokenSilently({ audience })
    const data = await fetchStudents(params, token)
    return data
  })

  const handleChange = (e) => {
    setSearch(e.target.value)
    setPage(1)
  }

  const toggleAppPopover = (e) => {
    const open = showFilters
    setShowFilters(!showFilters)
    open ? setFilterTarget(null) : setFilterTarget(e.target)
  }

  const toggleGradeLevelPopover = (e) => {
    const open = showGradeLevelFilters
    setShowGradeLevelFilters(!showGradeLevelFilters)
    open ? setFilterTarget(null) : setFilterTarget(e.target)
  }

  const toggleExemptPopover = (e) => {
    const open = showExemptFilter
    setShowExemptFilter(!showExemptFilter)
    open ? setFilterTarget(null) : setFilterTarget(e.target)
  }

  const toggleRiskPopover = (e) => {
    const open = showRiskPopover
    setShowRiskPopover(!showRiskPopover)
    open ? setFilterTarget(null) : setFilterTarget(e.target)
  }

  const filterButtonClass = (bool) => {
    return bool ? 'outline-primary bg-white' : 'light'
  }

  return (
    <Page>
      <Row>
        <Col sm={9}>
          <Title content="Students" />
        </Col>
        <Col sm={3}>
          {currentUser.schoolCount > 1 ? <SchoolSelect /> : null}
        </Col>
      </Row>

      <hr className="mt-1 mb-4" />

      <div className="mb-5 pb-5">
        <Row className="mb-2">
          <Col sm={4}>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  value={search}
                  onChange={(e) => handleChange(e)}
                  placeholder="Student Name/ID"
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col sm={8}>
            <Button
              className="mr-2"
              onClick={toggleGradeLevelPopover}
              variant={
                showGradeLevelFilters
                  ? 'primary'
                  : gradeLevelFilter.length > 0
                  ? 'outline-primary bg-white'
                  : 'light'
              }
            >
              {gradeLevelString === ''
                ? 'Grade Level'
                : `Grade Level: ${gradeLevelString}`}
            </Button>
            <Button
              className="mr-2"
              onClick={toggleExemptPopover}
              variant={filterButtonClass(exemptFilterCheck !== '')}
            >
              {exemptFilterCheck === ''
                ? 'RSA Status'
                : titleize(exemptFilterCheck)}
            </Button>
            {/*
            <Button
              className="mr-2"
              onClick={toggleAppPopover}
              variant={planFilter ? 'outline-primary bg-white' : 'light'}
            >
              {titleize(planFilter) || 'APP/IPRI'}
            </Button>
             */}
            <Button
              onClick={toggleRiskPopover}
              className="mr-2"
              variant={
                showRiskPopover || riskFilter !== ''
                  ? 'outline-primary bg-white'
                  : 'light'
              }
            >
              {titleize(riskFilter) || 'Risk'}
            </Button>

            {/* Academic Progress Plan Popover */}

            <AppPlanFilter
              showFilters={showFilters}
              filterTarget={filterTarget}
              setShowFilters={setShowFilters}
              setPlanFilter={setPlanFilter}
              planFilter={planFilter}
              toggleAppPopover={toggleAppPopover}
            />

            {/* TODO: Pull into own component */}
            {/* Grade Level Popover */}

            <Overlay
              show={showGradeLevelFilters}
              target={filterTarget}
              rootClose
              onHide={(_) => setShowGradeLevelFilters(!showGradeLevelFilters)}
              placement="bottom"
            >
              <Popover style={{ minWidth: '25%' }}>
                <Popover.Title as="h3">Filter Grade Level</Popover.Title>
                <Popover.Content>
                  <Row className="mt-4 mb-2">
                    <Col sm={12}>
                      <ToggleButtonGroup
                        type="checkbox"
                        toggle
                        defaultValue={gradeLevelFilter}
                        onChange={(val) => setGradeLevelFilter(val)}
                        style={{ width: '100%' }}
                      >
                        <ToggleButton
                          name="grade-level-filter"
                          value={0}
                          variant="outline-primary"
                        >
                          K
                        </ToggleButton>
                        <ToggleButton
                          name="grade-level-filter"
                          value={1}
                          variant="outline-primary"
                        >
                          1
                        </ToggleButton>
                        <ToggleButton
                          name="grade-level-filter"
                          value={2}
                          variant="outline-primary"
                        >
                          2
                        </ToggleButton>
                        <ToggleButton
                          name="grade-level-filter"
                          value={3}
                          type="checkbox"
                          variant="outline-primary"
                        >
                          3
                        </ToggleButton>
                        <ToggleButton
                          name="grade-level-filter"
                          value={4}
                          type="checkbox"
                          variant="outline-primary"
                        >
                          4
                        </ToggleButton>
                        <ToggleButton
                          name="grade-level-filter"
                          value={5}
                          type="checkbox"
                          variant="outline-primary"
                        >
                          5
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Col>
                  </Row>
                  <hr />
                  <Row className="mt-2 mb-2">
                    <Col xs={6} md={5}>
                      <Button
                        variant="link"
                        onClick={() => setGradeLevelFilter([])}
                      >
                        Clear
                      </Button>
                    </Col>
                    <Col xs={0} md={2} />
                    <Col xs={6} md={5}>
                      <Button
                        variant="primary"
                        onClick={toggleGradeLevelPopover}
                        block
                      >
                        Done
                      </Button>
                    </Col>
                  </Row>
                </Popover.Content>
              </Popover>
            </Overlay>

            {/* TODO: Pull into own component */}
            {/* Exmept Filter Popover */}

            <Overlay
              show={showExemptFilter}
              target={filterTarget}
              rootClose
              onHide={(_) => setShowExemptFilter(!showExemptFilter)}
              placement="bottom"
            >
              <Popover style={{ minWidth: '18%' }}>
                <Popover.Title as="h3">Filter Exemption Status</Popover.Title>
                <Popover.Content>
                  <Row className="mt-2">
                    <Col sm={1}></Col>
                    <Col sm={10}>
                      <div>
                        <Button
                          name="exemption-filter"
                          onClick={(e) => setExemptFilterCheck('not_exempt')}
                          checked={exemptFilterCheck === 'not_exempt'}
                          value="false"
                          type="radio"
                          block
                          className="mt-2"
                          variant={
                            exemptFilterCheck === 'not_exempt'
                              ? 'outline-primary'
                              : 'light'
                          }
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            className={'text-danger mr-2'}
                          />
                          {'Not Exempt'}
                        </Button>
                      </div>
                      <div>
                        <Button
                          name="exemption-filter"
                          onClick={(e) => setExemptFilterCheck('exempt')}
                          checked={exemptFilterCheck === 'exempt'}
                          value="true"
                          type="radio"
                          block
                          className="mt-2"
                          variant={
                            exemptFilterCheck === 'exempt'
                              ? 'outline-primary'
                              : 'light'
                          }
                        >
                          <FontAwesomeIcon
                            icon={faCheck}
                            className={'text-success mr-2'}
                          />
                          {'Exempt'}
                        </Button>
                      </div>
                      <div>
                        <Button
                          name="exemption-filter"
                          onClick={(e) => setExemptFilterCheck('probation')}
                          checked={exemptFilterCheck === 'probation'}
                          value="true"
                          type="radio"
                          block
                          className="mt-2"
                          variant={
                            exemptFilterCheck === 'probation'
                              ? 'outline-primary'
                              : 'light'
                          }
                        >
                          <FontAwesomeIcon
                            icon={faParking}
                            className={'text-warning mr-2'}
                          />
                          {'Probation'}
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <hr />

                  <Row className="mt-2 mb-2">
                    <Col xs={6} md={5}>
                      <Button
                        variant="link"
                        onClick={() => setExemptFilterCheck('')}
                      >
                        Clear
                      </Button>
                    </Col>
                    <Col xs={0} md={2} />
                    <Col xs={6} md={5}>
                      <Button
                        variant="primary"
                        onClick={toggleExemptPopover}
                        block
                      >
                        Done
                      </Button>
                    </Col>
                  </Row>
                </Popover.Content>
              </Popover>
            </Overlay>
          </Col>
        </Row>

        {/* TODO: Pull into own component */}
        {/* Risk Filter */}

        <Overlay
          show={showRiskPopover}
          target={filterTarget}
          rootClose
          onHide={(_) => setShowRiskPopover(!showRiskPopover)}
          placement="bottom"
        >
          <Popover style={{ minWidth: '25%' }}>
            <Popover.Title as="h3">Risk</Popover.Title>
            <Popover.Content>
              <Row className="mt-2">
                <Col sm={12}>
                  <ButtonGroup
                    className="mt-2"
                    toggle
                    style={{ width: '100%' }}
                  >
                    <ToggleButton
                      name="grade-level-filter"
                      onClick={(e) => setRiskFilter(e.target.value)}
                      checked={riskFilter === 'critical'}
                      value="critical"
                      type="radio"
                      variant="outline-primary"
                    >
                      {'Critical'}
                      <br />
                      {'(< 10%)'}
                    </ToggleButton>
                    <ToggleButton
                      name="grade-level-filter"
                      onClick={(e) => setRiskFilter(e.target.value)}
                      checked={riskFilter === 'high'}
                      value="high"
                      type="radio"
                      variant="outline-primary"
                    >
                      {'High Risk'}
                      <br />
                      {'(< 25%)'}
                    </ToggleButton>
                    <ToggleButton
                      name="grade-level-filter"
                      onClick={(e) => setRiskFilter(e.target.value)}
                      checked={riskFilter === 'at_risk'}
                      value="at_risk"
                      type="radio"
                      variant="outline-primary"
                    >
                      {'At Risk'}
                      <br />
                      {'(< 40%)'}
                    </ToggleButton>
                  </ButtonGroup>
                </Col>
              </Row>

              <hr />

              <Row className="mt-1 mb-1">
                <Col xs={6} md={5}>
                  <Button variant="link" onClick={() => setRiskFilter('')}>
                    Clear
                  </Button>
                </Col>
                <Col xs={0} md={2} />
                <Col xs={6} md={5}>
                  <Button variant="primary" onClick={toggleRiskPopover} block>
                    Done
                  </Button>
                </Col>
              </Row>
            </Popover.Content>
          </Popover>
        </Overlay>
        {isError && <ErrorStatus errorMessage="Unable to load students" />}

        {isLoading && <LoadingStatus status={isLoading} />}

        {isSuccess && (
          <div key={students.data.id}>
            <StudentsTable
              data={students.data}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              orderAsc={orderAsc}
              setOrderAsc={setOrderAsc}
            />
            <TableFooter
              data={students}
              perPage={perPage}
              setPerPage={setPerPage}
              page={page}
              setPage={setPage}
            />
          </div>
        )}
      </div>
    </Page>
  )
}

export default Students
