import { audience, baseUrl } from 'api/helpers'

export const fetchStudents = async (params, token) => {
  /* TODO: Extract Auth0 logic into helpers  */
  const url = new URL(`${baseUrl}/students`)

  url.search = new URLSearchParams(params).toString()
  const response = await fetch(url, {
    ...params,
    audience,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  const data = await response.json()
  return data
}

export const fetchStudent = async (id, token) => {
  const response = await fetch(`${baseUrl}/students/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  const data = await response.json()
  return data
}
