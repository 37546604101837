import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faThumbsUp,
  faThumbsDown,
  faCheck,
} from '@fortawesome/free-solid-svg-icons'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { startCase, camelCase } from 'lodash'

function RecommendationRow({
  setDecisionObject,
  decisionObject,
  roles,
  roleName,
}) {
  const updateObject = (key, value) => {
    setDecisionObject({ ...decisionObject, [key]: value })
  }

  return (
    <tr>
      <td>
        {startCase(camelCase(roleName))}{' '}
        {/*
        <OverlayTrigger
          trigger={['focus', 'hover']}
          placement="right"
          overlay={<Tooltip></Tooltip>}
        >
          <FontAwesomeIcon icon={faInfoCircle} className="text-muted" />
        </OverlayTrigger>
        */}
      </td>
      <td>
        {decisionObject.esignature_accepted ? (
          <Row>
            <Col sm={5}>
              <Button
                variant={decisionObject.vote === 'agree' ? 'primary' : 'light'}
                size="sm"
                className="ml-2"
                onClick={(e) => updateObject('vote', 'agree')}
              >
                <FontAwesomeIcon
                  icon={faThumbsUp}
                  className={`${
                    decisionObject.vote === 'agree'
                      ? 'text-white'
                      : 'text-primary'
                  } mr-1`}
                />
                Agree
              </Button>
              <Button
                variant={
                  decisionObject.vote === 'disagree' ? 'secondary' : 'light'
                }
                size="sm"
                className="ml-2"
                onClick={(e) => updateObject('vote', 'disagree')}
              >
                <FontAwesomeIcon
                  icon={faThumbsDown}
                  className="text-warning mr-1"
                />
                Disagree
              </Button>
            </Col>
            <Col sm={7}>
              <Form.Group>
                <Form.Control
                  size="sm"
                  type="text"
                  value={decisionObject.signature}
                  onChange={(e) => updateObject('signature', e.target.value)}
                  placeholder="Type Name to Sign"
                />
              </Form.Group>
            </Col>
          </Row>
        ) : (
          <>
            <Button
              variant="light"
              size="sm"
              onClick={() => updateObject('esignature_accepted', true)}
            >
              <FontAwesomeIcon icon={faCheck} className="text-primary mr-1" />
              Accept
            </Button>
            <small className="ml-2">
              I agree to sign this document electronically
            </small>
          </>
        )}
      </td>
    </tr>
  )
}

export default RecommendationRow
