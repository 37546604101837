import React, { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { audience } from 'api/helpers'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { createAcademicPlanSupport } from 'api/academic_plan_supports'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import useSupportCategories from 'api/hooks/use-academic-plan-support-categories'

function SupportsModal({ plan, readOnly }) {
  const { getAccessTokenSilently } = useAuth0()
  const queryClient = useQueryClient()
  const [validated, setValidated] = useState(false)
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const emptyState = {
    academic_plan_support_category_id: '',
    minutes: '',
  }

  const [support, setSupport] = useState(emptyState)

  const { isSuccess: categoriesIsSuccess, data: categories } =
    useSupportCategories()

  const newSupport = useMutation(
    ({ params, plan, token }) =>
      createAcademicPlanSupport({ params, plan, token }),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(`academic_progress_plans/${plan.id}`)
        queryClient.invalidateQueries(
          `academic_progress_plans/${plan.id}/supports`
        )
      },
    }
  )

  const createNewSupport = async () => {
    const token = await getAccessTokenSilently({ audience })

    newSupport.mutate({ params: support, plan: plan, token })
    toast.success('Added support to academic plan')
  }

  const handleSubmit = (e) => {
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    } else {
      e.preventDefault()
      createNewSupport()
      handleClose()
      setSupport(emptyState)
    }
    setValidated(true)
  }

  return (
    <React.Fragment>
      <Button
        className="text-info"
        disabled={readOnly}
        variant="light"
        size="sm"
        onClick={handleShow}
      >
        <FontAwesomeIcon icon={faPlus} /> Add Support
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Form
          class="needs-validation"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Support</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Control
                    as="select"
                    required
                    onChange={(e) =>
                      setSupport({
                        ...support,
                        academic_plan_support_category_id: e.target.value,
                      })
                    }
                  >
                    <option value="">None selected</option>
                    {categoriesIsSuccess &&
                      categories.map((c) => (
                        <option value={c.id} key={`category-${c.id}`}>
                          {c.name}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="number"
                    required
                    onChange={(e) =>
                      setSupport({ ...support, minutes: e.target.value })
                    }
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>mins</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default SupportsModal
