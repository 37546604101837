import React from 'react'
import { displayGradeLevel } from 'helpers/presenters'
import { calculatePercent } from 'helpers/utils'

function AcademicPlanProgressBars({ isSuccess, data }) {
  return (
    <div className="card card-header-actions h-100">
      <div className="card-header text-info bg-white">
        <strong>Academic Progress Plan Tracker</strong>
      </div>
      <div className="card-body">
        {isSuccess &&
          data.map((d) => (
            <React.Fragment key={`progress-bar-grade-${d.grade_level}`}>
              <div className="h6">
                {displayGradeLevel(d.grade_level)}
                <span className="float-right font-weight-bold">
                  {calculatePercent(d.apps_complete, d.apps_needed)}%
                </span>
              </div>
              <div className="progress mb-3">
                <div
                  className="progress-bar bg-warning"
                  role="progressbar"
                  style={{
                    width: `${calculatePercent(d.in_progress, d.apps_needed)}%`,
                  }}
                  aria-valuenow={calculatePercent(d.in_progress, d.apps_needed)}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
                <div
                  className="progress-bar bg-success"
                  role="progressbar"
                  style={{
                    width: `${calculatePercent(
                      d.apps_complete,
                      d.apps_needed
                    )}%`,
                  }}
                  aria-valuenow={calculatePercent(
                    d.apps_complete,
                    d.apps_needed
                  )}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </React.Fragment>
          ))}
      </div>
    </div>
  )
}

export default AcademicPlanProgressBars
