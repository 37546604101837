import React, { useState } from 'react'
import RsaStatusBadge from 'students/badges/rsa-status'
import ApprovalButton from './approval-button'
import UpdateModal from 'students/update-modal'
import { Link } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import SrptCompleteBadge from 'shared/badges/srpt-complete'
import SrptBadge from 'shared/badges/srpt'
import { isNull } from 'lodash'

function ApprovalRow({ student }) {
  const [hover, setHover] = useState(false)
  const [showUpdateModal, setShowUpdateModal] = useState(false)

  const queryClient = useQueryClient()

  const handleCloseUpdate = () => {
    queryClient.invalidateQueries(`students`)
    setShowUpdateModal(false)
  }

  return (
    <tr>
      <td>
        <Link
          to={`/students/${student.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {student.first_name}
        </Link>
      </td>
      <td>
        {student.last_name}
        {!isNull(student.student_pathway) &&
          student.student_pathway.pathway_id === 4 && (
            <span className="ml-2">
              {student.meetings.length === 0 ? (
                <SrptBadge student={student} />
              ) : (
                <SrptCompleteBadge student={student} />
              )}
            </span>
          )}
      </td>
      <td
        className="text-center"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => setShowUpdateModal(true)}
        style={{
          cursor: 'pointer',
          backgroundColor: hover ? '#eaf4fa' : '#FFF',
        }}
      >
        <RsaStatusBadge
          studentPathway={student.student_pathway}
          tooltip={false}
        />{' '}
        {hover ? (
          <FontAwesomeIcon
            icon={faEdit}
            className="text-primary mt-1 float-right"
          />
        ) : (
          <FontAwesomeIcon
            icon={faEdit}
            className="text-white mt-1 float-right"
          />
        )}
      </td>
      <td className="text-center">
        <ApprovalButton student={student} />
      </td>

      {showUpdateModal && (
        <UpdateModal
          showModal={showUpdateModal}
          handleClose={handleCloseUpdate}
          student={student}
        />
      )}
    </tr>
  )
}

export default ApprovalRow
