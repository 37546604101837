import React, { useState, useContext } from 'react'
import Page from 'containers/page'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import SchoolAcademicPlan from './academic-plans/index'
import Exemptions from './exemptions/index'
import SchoolSelect from './school-select'
import Title from 'helpers/title'
import Col from 'react-bootstrap/Col'
import UserContext from 'context/user-context'
import Card from 'react-bootstrap/Card'
import moment from 'moment'

function School() {
  const currentUser = useContext(UserContext)
  const [activeTab, setActiveTab] = useState('exemptions')

  const isActive = (tabName) => {
    return tabName === activeTab ? 'outline-primary' : 'light'
  }

  return (
    <Page>
      {[5, 6, 7].includes(moment().month()) && (
        <Row>
          <Col>
            <Card
              style={{
                backgroundColor: '#e5f2f9',
                border: '1px solid #a5d1eb',
              }}
            >
              <Card.Body>
                3rd grade Documentation for Promotion or Retention decisions is
                ready{' '}
                <a className="btn btn-info btn-sm ml-2" href="/approval">
                  Fill out now
                </a>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      <div>
        <Row>
          <Col sm={9}>
            <Title
              content="Overview"
              subcontent={
                <>
                  <Button
                    className="ml-1"
                    style={{ outline: 'none', boxShadow: 'none' }}
                    onClick={() => setActiveTab('exemptions')}
                    variant={isActive('exemptions')}
                  >
                    Exemptions
                  </Button>
                  <Button
                    className="ml-1"
                    style={{ outline: 'none', boxShadow: 'none' }}
                    onClick={() => setActiveTab('academic-plans')}
                    variant={isActive('academic-plans')}
                  >
                    APP/IPRI
                  </Button>
                  {/* TODO:
                  <Button
                    className="ml-1"
                    style={{ outline: 'none', boxShadow: 'none' }}
                    onClick={() => setActiveTab('interventions')}
                    variant={
                      activeTab === 'interventions' ? 'link bg-white' : 'light'
                    }
                  >
                    Interventions
                  </Button>
                   */}
                </>
              }
            />
          </Col>
          <Col sm={3}>
            {currentUser.schoolCount > 1 ? <SchoolSelect /> : null}
          </Col>
        </Row>
        <hr style={{ marginTop: 8 }} />
        <div className="mb-3">
          {activeTab === 'exemptions' ? <Exemptions /> : null}
          {activeTab === 'academic-plans' ? <SchoolAcademicPlan /> : null}
          {/* TODO:
              {activeTab === 'interventions' ? <Interventions /> : null}
            */}
        </div>
        <Row className="my-5"></Row>
      </div>
    </Page>
  )
}

export default School
