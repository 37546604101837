import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'

const getAcademicPlanSupportCategories = async(token) => {
  const url = 'academic_plan_support_categories'

  const data = await apiGet({ url, token })
  return data
}

export default function useSupportCategories() {
  const token = useContext(AuthContext)

  return useQuery('academic_plan_support_categories', () => getAcademicPlanSupportCategories(token))
}
