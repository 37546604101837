import React from 'react'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'

function Uploads() {
  return (
    <Card className="mt-4">
      <Card.Body>
        <Form.Group>
          <Form.Control as="select">
            <option>Select Asssessment</option>
            <option>NWEA Map</option>
            <option>STAR</option>
            <option>IStation</option>
          </Form.Control>
        </Form.Group>

        <h3>Uploads</h3>
        <ul>
          <li>Link to upload instructions</li>
          <li>Upload Stats</li>
        </ul>
      </Card.Body>
    </Card>
  )
}

export default Uploads
