import { audience, baseUrl } from 'api/helpers'
import axios from 'axios'
import { toast } from 'react-toastify'
import { isEmpty } from 'lodash'

export const apiGet = async ({ url, token, params = {} }) => {
  const fullUrl = new URL(`${baseUrl}/${url}`)
  if (!isEmpty(params)) {
    fullUrl.search = new URLSearchParams(params).toString()
  }

  return axios.get(fullUrl, {
      audience,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(response => {
      return response.data
    })
    .catch(() => {
      toast.error('An error occured')
      return []
    })
}

export const apiPatch = async ({ url, token, objectName, params = {} }) => {
  const fullUrl = `${baseUrl}/${url}`
  const jsonParams = JSON.stringify({ [objectName]: params })

  const data = await axios.patch(fullUrl, jsonParams, {
      audience,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    .then(response => {
      return response.data
    })
    .catch(() => {
      toast.error('An error occured')
      return []
    })

  return data
}

export const apiPost = ({ url, token, objectName, params = {} }) => {
  const fullUrl = `${baseUrl}/${url}`
  const jsonParams = JSON.stringify({ [objectName]: params })

  return axios.post(fullUrl, jsonParams, {
    audience,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
  .then(response => {
    return response.data
  })
  .catch(() => {
    toast.error('An error occured')
    return []
  })
}

export const apiDelete = async ({ url, token }) => {
  const fullUrl = `${baseUrl}/${url}`

  const data = await axios.delete(fullUrl, {
      audience,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(response => {
      return response.data
    })
    .catch(() => {
      toast.error('An error occured')
      return []
    })
  return data
}
