import React, { useContext } from 'react'
import Table from './table'
import Chart from './chart'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useQuery } from 'react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { audience } from 'api/helpers'
import { fetchSchoolExemptions } from 'api/school_exemptions'
import LoadingStatus from 'helpers/loading-status'
import ErrorStatus from 'helpers/error-status'
import SchoolContext from 'context/school-context'

function Exemptions() {
  const { currentSchool } = useContext(SchoolContext)
  const schoolId = currentSchool.id
  const { getAccessTokenSilently } = useAuth0()
  const { isLoading, isError, isSuccess, data: exemptionData } = useQuery(
    ['schools-exemptions', schoolId],
    async () => {
      const token = await getAccessTokenSilently({ audience })
      const response = await fetchSchoolExemptions(schoolId, token)
      return response
    }
  )

  if (isLoading) {
    return <LoadingStatus status={isLoading} />
  }

  if (isError) {
    return <ErrorStatus errorMessage="Unable to load school data" />
  }

  if (isSuccess) {
    return (
      <>
        <Row className="mt-3">
          <Col sm={6}>
            <Chart exemptionData={exemptionData} />
          </Col>
          <Col sm={6}>
            <Table exemptionData={exemptionData} />
          </Col>
        </Row>
      </>
    )
  }
}

export default Exemptions
