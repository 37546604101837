import React, { useState } from 'react'
import PortfolioTable from './portfolio-table'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFolder } from "@fortawesome/free-solid-svg-icons"

function Portfolio({ student }) {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
    <Button variant="light" onClick={handleShow} className="mb-2 float-left">
      <FontAwesomeIcon icon={faFolder} className='text-primary' /> <span className='text-info'>Porfolio Progress</span></Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className='text-info'><FontAwesomeIcon icon={faFolder} className="mr-2" />Portfolio Progress</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PortfolioTable student={student} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Portfolio
