import { audience, baseUrl } from 'api/helpers'
import axios from 'axios'
import { toast } from 'react-toastify'

export const fetchDistrictSchools = async ({districtId, token}) => {
  const url = `${baseUrl}/districts/${districtId}/schools`
  const data = await axios.get(url, {
    audience,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((response) => {
    return response.data
  }).catch((error) => {
    toast.error('An error occured while loading the schools')
    return []
  })
  return data
}
