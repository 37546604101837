import React from 'react'
import Card from 'react-bootstrap/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

function TierCard({ plan, setPlan, tier, readOnly }) {
  const tierDescription = () => {
    if (tier === 1) {
      return ''
    } else if (tier === 2) {
      return 'Supplemental Instruction (time in addition to core instruction as fits the needs of the student required; 15-30 minutes of reading intervention recommended)'
    } else if (tier === 3) {
      return 'Intensive Intervention (time in addition to core instruction as fits the needs of the student; 45-60 minutes of reading intervention strongly recommended)'
    }
  }

  return (
    <React.Fragment>
      {readOnly ? (
        <Card
          border={plan.supportTier === tier ? 'primary' : ''}
          className="h-100"
          style={{
            background: plan.supportTier === tier ? '#eaf4fa' : '#efefef',
          }}
        >
          <Card.Body>
            <span className="font-weight-bold float-left">Tier {tier}</span>{' '}
            {plan.supportTier === tier && (
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="float-right text-primary"
              />
            )}
            <div className="mt-2 float-left font-weight-light">
              {tierDescription()}
            </div>
          </Card.Body>
        </Card>
      ) : (
        <Card
          onClick={(e) => setPlan({ ...plan, supportTier: tier })}
          border={plan.supportTier === tier ? 'primary' : ''}
          className="h-100"
          style={{
            cursor: 'pointer',
            background: plan.supportTier === tier ? '#eaf4fa' : '',
          }}
        >
          <Card.Body>
            <span className="font-weight-bold float-left">Tier {tier}</span>{' '}
            {plan.supportTier === tier && (
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="float-right text-primary"
              />
            )}
            <div className="mt-2 float-left font-weight-light">
              {tierDescription()}
            </div>
          </Card.Body>
        </Card>
      )}
    </React.Fragment>
  )
}

export default TierCard
