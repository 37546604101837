import React from 'react'
import Table from 'react-bootstrap/Table'
import Page from 'containers/page'
import Title from 'helpers/title'

function Pathways() {
  return (
    <Page>
      <Title content={'Pathways'} />
      <Table className="mt-2">
        <tbody>
          <tr>
            <th>
              Pathway 1: Oklahoma State Testing Program (OSTP)
              <p className="font-weight-light ml-3">
                Meet RSA criteria on the Oklahoma State Testing Program (OSTP) A
                copy of the student's scores must be included in the permanent
                record
              </p>
            </th>
          </tr>
          <tr>
            <th>
              Pathway 2: Screening Assessments
              <p className="font-weight-light ml-3">
                Meet the end-of-year grade-level target for third grade on one
                of the screening assessments approved by the Oklahoma State
                Board of Education and written notification has been provided to
                the student’s parent/guardian
              </p>
            </th>
          </tr>
          <tr>
            <th>
              Pathway 3: Good-cause Exemption
              <div className="ml-3">
                <p className="font-weight-light">
                  Meet the requirements of one of the seven good-cause
                  exemptions:
                </p>
                <ul>
                  <li>
                    EL Proficiency:{' '}
                    <span className="font-weight-light">
                      Student is an English learner who has had less than two
                      years of instruction in English and is identified as
                      Limited English Proficient/English learner on an approved
                      screening tool. Student has an English Language Academic
                      Plan (ELAP) in place.
                    </span>
                  </li>
                  <li>
                    Alternate Assessment:{' '}
                    <span className="font-weight-light">
                      Student has an Individualized Education Plan (IEP) which
                      indicates the student is to be assessed with the Oklahoma
                      Alternate Assessment Program (OAAP).
                    </span>
                  </li>
                  <li>
                    Alternative Standardized Test:{' '}
                    <span className="font-weight-light">
                      Student has demonstrated an acceptable level of
                      performance (45th percentile or higher) on of the approved
                      alternative standardized reading test (i.e, Iowa Test of
                      Basic Skills, Stanford Achievement Test, Tenth Edition
                      (SAT-10) or TerraNova) and documentation of test results
                      are included in the permanent record.
                    </span>
                  </li>
                  <li>
                    Portfolio:{' '}
                    <span className="font-weight-light">
                      Student has demonstrated reading sufficiency through a
                      teacher-developed portfolio. Completed portfolio checklist
                      is included in the student’s permanent record.
                    </span>
                  </li>
                  <li>
                    IEP Goals:{' '}
                    <span className="font-weight-light">
                      Student participated in the OSTP, has an Individualized
                      Education Plan (IEP) that reflects intensive remediation
                      in reading, and has made adequate progress toward IEP
                      goals
                    </span>
                  </li>
                  <li>
                    Previously Retained:{' '}
                    <span className="font-weight-light">
                      Student has received intensive remediation in reading for
                      two or more years and has been previously retained for
                      academic reasons
                    </span>
                  </li>
                  <li>
                    Exceptional Emergency:{' '}
                    <span className="font-weight-light">
                      Student is facing an exceptional emergency circumstance
                      and exemption from testing has been approved by the Office
                      of Assessment. Documentation is included in student’s
                      permanent record.
                    </span>
                  </li>
                </ul>
              </div>
            </th>
          </tr>
          <tr>
            <th>
              Pathway 4: Promote with Probation
              <p className="font-weight-light ml-3">
                Unanimous decision by the Student Reading Proficiency Team
                (SRPT) to promote with probation. Documentation in student’s
                permanent record includes date of meeting, members of SRPT
                including the students third-grade teacher, fourth-grade
                teacher, parent/guardian, and reading specialist (if available).
              </p>
            </th>
          </tr>
        </tbody>
      </Table>
    </Page>
  )
}

export default Pathways
