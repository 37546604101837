import React, { useContext } from 'react'
import Page from 'containers/page'
import Title from 'helpers/title'
import Table from 'react-bootstrap/Table'
import { useQuery } from 'react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { audience } from 'api/helpers'
import { fetchEndOfYear } from 'api/report-end-of-years'
import { displayGradeLevel } from 'helpers/presenters'
import UserContext from 'context/user-context'
import SchoolContext from 'context/school-context'
import SchoolSelect from 'schools/school-select'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tooltip from 'react-bootstrap/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

function EndOfYear() {
  const { getAccessTokenSilently } = useAuth0()
  const currentUser = useContext(UserContext)
  const { currentSchool } = useContext(SchoolContext)
  const { isSuccess, data } = useQuery(
    [`report_end_of_year`, currentSchool.id],
    async () => {
      const params = { school_id: currentSchool.id }
      const token = await getAccessTokenSilently({ audience })
      const data = await fetchEndOfYear({ token, params })
      return data
    }
  )

  return (
    <Page>
      <Row>
        <Col sm={9}>
          <Title
            content={'End of Year Report'}
            subcontent={'Use this page to help fill out the state report'}
            style={{ cursor: 'pointer' }}
          />
        </Col>
        <Col sm={3}>
          {currentUser.schoolCount > 1 ? (
            <SchoolSelect style={{ cursor: 'pointer' }} />
          ) : null}
        </Col>
      </Row>
      <hr className="mt-1 mb-4" style={{ cursor: 'pointer' }} />
      <Table bordered size="sm">
        <thead>
          <tr>
            <th>Grade </th>
            <th>
              Initial (BOY)
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={
                  <Tooltip>
                    The number of students reported as below benchmark on your
                    BOY Report.
                  </Tooltip>
                }
              >
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  style={{ cursor: 'pointer' }}
                  className="ml-1"
                />
              </OverlayTrigger>
            </th>
            <th>
              Entered Program
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={
                  <Tooltip>
                    The number of students identified as below benchmark on an
                    approved assessment who moved to the school site after the
                    BOY report was submitted.
                  </Tooltip>
                }
              >
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  style={{ cursor: 'pointer' }}
                  className="ml-1"
                />
              </OverlayTrigger>
            </th>
            <th>
              Exited Program
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={
                  <Tooltip>
                    The number of students who were identified as below
                    benchmark and moved away after the BOY report was submitted
                  </Tooltip>
                }
              >
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  style={{ cursor: 'pointer' }}
                  className="ml-1"
                />
              </OverlayTrigger>
            </th>
            <th>
              Completed Program - On Level
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={
                  <Tooltip>
                    The number of studeents who completed the program at the
                    eend of the year and are at or above benchmark
                  </Tooltip>
                }
              >
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  style={{ cursor: 'pointer' }}
                  className="ml-1"
                />
              </OverlayTrigger>
            </th>
            <th>
              Completed Program - Not On Level
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={
                  <Tooltip>
                    The number of students who completed the program at the end
                    of the year and remain below benchmark. (System calculated)
                  </Tooltip>
                }
              >
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  style={{ cursor: 'pointer' }}
                  className="ml-1"
                />
              </OverlayTrigger>
            </th>
          </tr>
        </thead>
        <tbody>
          {isSuccess &&
            data.map((d) => (
              <tr key={`${d.school_id}-${d.grade_level}-eoy-row`}>
                <td>{displayGradeLevel(d.grade_level)}</td>
                <td>{d.beginning_below}</td>
                <td>{d.entered}</td>
                <td>{d.exited}</td>
                <td>{d.eoy_above}</td>
                <td>
                  {d.beginning_below + d.entered - d.exited - d.eoy_above}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Page>
  )
}

export default EndOfYear
