import moment from 'moment'

export const currentSchoolYear = () => {
  const date = moment()
  const month = date.month()
  if (month > 7) {
    return date.year() + 1
  } else {
    return date.year()
  }
}

export const currentTerm = () => {
  const date = moment()
  const month = date.month()
  if (month > 7) {
    return 'fall'
  } else {
    return 'spring'
  }
}

export const currentTestingWindow = () => {
  const date = moment()
  const month = date.month()
  if (month > 7 && month < 12) {
    return 'fall'
  } else if (month === 12 || month < 4) {
    return 'winter'
  } else {
    return 'spring'
  }
}
