import React from 'react'
import Form from 'react-bootstrap/Form'

function Wengage() {
  return (
    <div>
      <Form.Group>
        <Form.Control type="text" placeholder="Wengage URL (https://ok.wengage.com/YourSchool)" />
      </Form.Group>
      <Form.Group>
        <Form.Control type="text" placeholder="User Lookup Value" />
      </Form.Group>
    </div>
  )
}

export default Wengage
