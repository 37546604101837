import { audience, baseUrl } from 'api/helpers'

export const fetchStudentPathways = async (studentId, token) => {
  const url = `${baseUrl}/students/${studentId}/student_pathways`
  const response = await fetch(url, {
    audience,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const data = await response.json()
  return data
}

export async function updateStudentPathway({ studentPathway, token }) {
  const url = `${baseUrl}/student_pathways/${studentPathway.id}`
  const response = await fetch(url, {
    method: 'PATCH',
    audience,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(studentPathway),
  })
  const data = await response.json()
  return data
}

export async function createStudentPathway({ studentPathway, token }) {
  const url = `${baseUrl}/students/${studentPathway.student_id}/student_pathways`
  const response = await fetch(url, {
    method: 'POST',
    audience,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(studentPathway),
  })
  const data = await response.json()
  return data
}

export async function destroyStudentPathway({ id, token }) {
  const url = `${baseUrl}/student_pathways/${id}`
  const response = await fetch(url, {
    method: 'DELETE',
    audience,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  const data = await response.json()
  return data
}
