import React, { useState } from 'react'
import Page from 'containers/page'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import General from './general'
import Users from './users'
import Uploads from './uploads'
import Schools from './schools'
import Sis from './sis'
import { toInteger, split } from 'lodash'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from 'react-query'
import { audience } from 'api/helpers'
import { fetchDistrict } from 'api/districts'
import LoadingStatus from 'helpers/loading-status'
import Badge from 'react-bootstrap/Badge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

function Settings() {
  const [activeTab, setActiveTab] = useState('users')
  const path = window.location.pathname
  const districtId = toInteger(split(path, '/')[2])

  const { getAccessTokenSilently } = useAuth0()
  const {
    isSuccess,
    isLoading,
    data: district,
  } = useQuery([`district/${districtId}`], async () => {
    const token = await getAccessTokenSilently({ audience })
    const data = await fetchDistrict(districtId, token)
    return data
  })

  return (
    <Page>
      {isLoading && <LoadingStatus status={isLoading} />}
      {isSuccess && (
        <>
          <div className="mt-5 mb-3">
            <a href="/districts">Districts</a>
            <span className="ml-2 text-muted">
              / <span className="ml-1 mt-2">{district.name}</span>
            </span>
            {district.is_customer ? (
              <Badge
                className={'text-success ml-2'}
                style={{ background: '#ecf6f1' }}
                pill
              >
                {<FontAwesomeIcon icon={faCheck} />} Customer
              </Badge>
            ) : (
              <Badge
                variant="white"
                className={'text-danger ml-2'}
                pill
                style={{ background: '#fdecea' }}
              >
                {<FontAwesomeIcon icon={faTimes} />} Not Customer
              </Badge>
            )}
          </div>
          <hr className="mt-1 mb-4" />

          <Row>
            <Col sm={12}>
              <Button
                className="ml-1"
                style={{ outline: 'none', boxShadow: 'none' }}
                onClick={() => setActiveTab('users')}
                variant={activeTab === 'users' ? 'link bg-white' : 'light'}
              >
                Users
              </Button>
              <Button
                style={{ outline: 'none', boxShadow: 'none' }}
                onClick={() => setActiveTab('general')}
                variant={activeTab === 'general' ? 'link bg-white' : 'light'}
              >
                General
              </Button>
              <Button
                className="ml-1"
                style={{ outline: 'none', boxShadow: 'none' }}
                onClick={() => setActiveTab('sis')}
                variant={activeTab === 'sis' ? 'link bg-white' : 'light'}
              >
                SIS
              </Button>
              <Button
                className="ml-1"
                style={{ outline: 'none', boxShadow: 'none' }}
                onClick={() => setActiveTab('schools')}
                variant={activeTab === 'schools' ? 'link bg-white' : 'light'}
              >
                Schools
              </Button>

              <Button
                className="ml-1"
                style={{ outline: 'none', boxShadow: 'none' }}
                onClick={() => setActiveTab('uploads')}
                variant={activeTab === 'uploads' ? 'link bg-white' : 'light'}
              >
                Uploads
              </Button>
            </Col>
          </Row>

          {activeTab === 'general' && isSuccess ? (
            <General district={district} />
          ) : null}
          {activeTab === 'sis' && isSuccess ? (
            <Sis district={district} />
          ) : null}
          {activeTab === 'users' && isSuccess ? (
            <Users district={district} />
          ) : null}
          {activeTab === 'schools' && isSuccess ? (
            <Schools district={district} />
          ) : null}
          {activeTab === 'uploads' && isSuccess ? (
            <Uploads district={district} />
          ) : null}
        </>
      )}
    </Page>
  )
}

export default Settings
