import React from 'react'
import Badge from 'react-bootstrap/Badge'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

/* TODO: Convert and move to badges */

export const riskColor = (percent) => {
  if (percent === null) {
    return (
      <OverlayTrigger
        key="top"
        placement="top"
        trigger={['hover', 'focus']}
        overlay={
          <Tooltip id={'tooltip'}>
            Not Applicable: No percentile attached
          </Tooltip>
        }
      >
        <Badge
          pill
          className={`text-info lead`}
          variant="white"
          style={{ cursor: 'pointer', background: '#eaeaea' }}
        >
          <span>N/A</span>
        </Badge>
      </OverlayTrigger>
    )
  }
  if (percent <= 10) {
    return (
      <OverlayTrigger
        key="top"
        placement="top"
        trigger={['hover', 'focus']}
        overlay={
          <Tooltip id={'tooltip'}>
            Critical: Student scored in the 10th percentile
          </Tooltip>
        }
      >
        <Badge
          variant="white"
          className={'text-danger'}
          pill
          style={{ background: '#fdecea', cursor: 'pointer' }}
        >
          <span>{percent}%</span>
        </Badge>
      </OverlayTrigger>
    )
  } else if (percent <= 25) {
    return (
      <OverlayTrigger
        key="top"
        placement="top"
        trigger={['hover', 'focus']}
        overlay={
          <Tooltip id={'tooltip'}>
            High Risk: Student scored between the 10th - 25th percentile
          </Tooltip>
        }
      >

        <Badge
          pill
          variant="white"
          style={{ cursor: 'pointer', background: '#fdf5ea', color: '#f4a236' }}
        >
          <span>{percent}%</span>
        </Badge>
      </OverlayTrigger>
    )
  } else if (percent < 40) {
    return (
      <OverlayTrigger
        key="top"
        placement="top"
        trigger={['hover', 'focus']}
        overlay={
          <Tooltip id={'tooltip'}>
            At Risk: Student scored between the 25th - 40th percentile
          </Tooltip>
        }
      >
      <Badge
        pill
        className={`text-warning lead`}
        variant="white"
        style={{ cursor: 'pointer', background: '#fcf7e6' }}
      >
          <span>{percent}%</span>
        </Badge>
      </OverlayTrigger>
    )
  } else if (percent >= 40) {
    return (
      <OverlayTrigger
        key="top"
        placement="top"
        trigger={['hover', 'focus']}
        overlay={
          <Tooltip id={'tooltip'}>
            Not At Risk: Student scored above the 40th percentile
          </Tooltip>
        }
      >
      <Badge
        pill
        variant="white"
        className={'text-success lead'}
        style={{background: '#ecf6f1', cursor: 'pointer'}}
      >
          <span>{percent}%</span>
        </Badge>
      </OverlayTrigger>
    )
  }
}

export const riskPercent = (percent) => {
  if (percent <= 10) {
    return (
      <span className="text-danger">
        {percent}%
      </span>
    )
  } else if (percent <= 25) {
    return (
      <span className="text-warning">
        {percent}%
      </span>
    )
  } else if (percent < 40) {
    return (
      <span className="text-muted">
        {percent}%
      </span>
    )
  } else {
    return <span>{percent}%</span>
  }
}
