import React, { useState } from 'react'
import UserContext from 'context/user-context'
import SchoolContext from 'context/school-context'
import AuthContext from './auth-context'

function AppProviders({ currentUser, children, token }) {
  const [currentSchool, setCurrentSchool] = useState(currentUser.defaultSchool)

  return (
    <AuthContext.Provider value={token}>
    <UserContext.Provider value={currentUser}>
      <SchoolContext.Provider value={{ currentSchool, setCurrentSchool }}>
        {children}
      </SchoolContext.Provider>
    </UserContext.Provider>
    </AuthContext.Provider>
  )
}
export default AppProviders
