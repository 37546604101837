import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Page from 'containers/page'
import Title from 'helpers/title'
import Form from 'react-bootstrap/Form'
import { currentSchoolYear, currentTestingWindow } from 'helpers/school-dates'
import Table from 'react-bootstrap/Table'
import SchoolYearDropdown from 'shared/forms/dropdowns/school-year'
import TestingWindowDropdown from 'shared/forms/dropdowns/testing-window'
import useReportTestingWindow from 'api/hooks/use-report-testing-window'
import TableFooter from 'shared/tables/footer'
import StudentSearch from 'shared/forms/search'
import FirstExemptionBadge from 'shared/badges/first-exemption'
import NeedAppBadge from 'shared/badges/need-app'
import AboveBenchmarkBadge from 'shared/badges/above-benchmark'
import { titleize } from 'helpers/utils'

function TestingWindow() {
  const [year, setYear] = useState(currentSchoolYear())
  const [testingWindow, setTestingWindow] = useState(currentTestingWindow)
  const [status, setStatus] = useState('')
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(25)

  const handleChange = (e) => {
    setSearch(e.target.value)
    setPage(1)
  }

  const { isSuccess, data } = useReportTestingWindow({
    year: year,
    testing_window: testingWindow,
    status: status,
    search: search,
    page: page,
    perPage: perPage,
  })

  useEffect(() => setPage(1), [testingWindow, search, year, status])

  return (
    <Page>
      <Title content={'Testing Window'} />
      <hr className="mt-1 mb-4" />
      <Row className="mb-2">
        <Col>
          <Form inline>
            <StudentSearch search={search} handleChange={handleChange} />
            <SchoolYearDropdown year={year} setYear={setYear} />

            <TestingWindowDropdown
              testingWindow={testingWindow}
              setTestingWindow={setTestingWindow}
            />

            <Form.Group className="mr-2">
              <Form.Control
                as="select"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">All Students</option>
                <option value="new-exemptions">
                  🎉 Students who hit an exemption for the first time
                </option>
                <option value="need-app">
                  📃 Students who now need an IPRI/APP
                </option>
                <option value="no-app">
                  📈 Students who moved above benchmark
                </option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <hr />

      <Row>
        <Col sm={12}>
          {status === '' && (
            <p className="font-weight-light text-muted">
              All students who tested during the {titleize(testingWindow)}{' '}
              {year} testing window
            </p>
          )}
          {status === 'new-exemptions' && (
            <p className="font-weight-light text-muted">
              List of students who hit the exemption score for the first time
              during the {titleize(testingWindow)} {year} testing window
            </p>
          )}
          {status === 'need-app' && (
            <p className="font-weight-light text-muted">
              List of students who now need an APP/IPRI based on the assessment
              score from the {titleize(testingWindow)} {year} testing window
            </p>
          )}
          {status === 'no-app' && (
            <p className="font-weight-light text-muted">
              Students who previously did not have a score or previously scored
              below the benchmark that scored above the benchmark during the{' '}
              {titleize(testingWindow)} {year} testing window
            </p>
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <Table bordered condensed responsive>
            <thead>
              <tr>
                <th>First</th>
                <th>Last</th>
                <th>Alerts</th>
                <th>Score</th>
                <th>Benchmark</th>
                <th>Percentile</th>
              </tr>
            </thead>
            <tbody>
              {isSuccess &&
                data.data.map((d) => (
                  <tr key={`student-${d.student_id}`}>
                    <td>
                      <a
                        href={`/students/${d.student_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {d.first_name}
                      </a>
                    </td>
                    <td>{d.last_name}</td>
                    <td>
                      {d.first_exemption_score && <FirstExemptionBadge />}
                      {d.moved_below_benchmark && <NeedAppBadge />}
                      {d.first_exemption_score === false &&
                        d.moved_above_benchmark && <AboveBenchmarkBadge />}
                    </td>
                    <td>{d.assessment_score}</td>
                    <td>{d.benchmark_score}</td>
                    <td>{d.percentile}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {isSuccess && (
            <TableFooter
              data={data}
              perPage={perPage}
              setPerPage={setPerPage}
              page={page}
              setPage={setPage}
            />
          )}
        </Col>
      </Row>
    </Page>
  )
}

export default TestingWindow
