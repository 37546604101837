import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import { audience } from 'api/helpers'
import { useQuery, useQueryClient, useMutation } from 'react-query'
import { useAuth0 } from '@auth0/auth0-react'
import {
  fetchAcademicPlanInterventions,
  destroyAcademicPlanIntervention,
} from 'api/academic_plan_interventions'
import RequiredField from 'helpers/required-field'
import { formatDate } from 'helpers/datetime'
import moment from 'moment'
import InterventionModal from './intervention-modal'
import ConfirmDelete from 'helpers/confirm-delete'

function AcademicPlanInterventions({ plan, readOnly }) {
  const queryClient = useQueryClient()
  const { getAccessTokenSilently } = useAuth0()
  const { isSuccess, data: interventions } = useQuery(
    `academic_progress_plans/${plan.id}/interventions`,
    async () => {
      const token = await getAccessTokenSilently({ audience })
      const data = await fetchAcademicPlanInterventions({
        planId: plan.id,
        token,
      })
      return data
    }
  )

  const deleteInterventionMutation = useMutation(
    ({ id, token }) => destroyAcademicPlanIntervention({ id, token }),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(
          `academic_progress_plans/${plan.id}/interventions`
        )
      },
    }
  )

  const handleDelete = async (id) => {
    const token = await getAccessTokenSilently({ audience })
    deleteInterventionMutation.mutate({
      id: id,
      token: token,
    })
    queryClient.invalidateQueries(
      `academic_progress_plans/${plan.id}/interventions`
    )
  }

  return (
    <>
      <Row className="mt-4">
        <Col sm={3}>
          <h5>
            Intervention Plan <RequiredField />
          </h5>
        </Col>
        <Col sm={9}>
          <Table bordered="bordered" size="sm">
            <thead>
              <tr>
                <th>Date</th>
                <th>Tier</th>
                <th>Instructional Routine / Intervention</th>
                <th>Interventionist</th>
                <th>Duration</th>
                <th>Frequency</th>
                <th className="text-center" style={{ minWidth: '100px' }}>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {isSuccess &&
                interventions.map((i) => (
                  <tr key={`interventions-${i.id}`}>
                    <td>{formatDate(moment(i.date))}</td>
                    <td className="text-center">{i.tier}</td>
                    <td className="text-center">{i.name}</td>
                    <td className="text-center">{i.interventionist}</td>
                    <td className="text-center">{i.minutes} mins</td>
                    <td className="text-center">{i.frequency}x / week</td>
                    <td className="text-center">
                      <InterventionModal
                        plan={plan}
                        oldIntervention={i}
                        readOnly={readOnly}
                      />

                      <ConfirmDelete
                        id={i.id}
                        handleDelete={handleDelete}
                        readOnly={readOnly}
                      />
                    </td>
                  </tr>
                ))}
              <tr>
                <td colSpan={6} className="text-center">
                  <InterventionModal plan={plan} readOnly={readOnly} />
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  )
}

export default AcademicPlanInterventions
