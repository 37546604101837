import { audience, baseUrl } from 'api/helpers'

export const fetchStudentAcademicPlans = async ({ studentId, token }) => {
  const url = `${baseUrl}/students/${studentId}/academic_progress_plans`
  const response = await fetch(url, {
    audience,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const data = await response.json()
  return data
}

export const fetchStudentAcademicPlan = async ({ planId, token }) => {
  const url = `${baseUrl}/academic_progress_plans/${planId}`
  const response = await fetch(url, {
    audience,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const data = await response.json()
  return data
}


export async function createStudentAcademicPlan({ params, token }) {
  const url = `${baseUrl}/students/${params.student_id}/academic_progress_plans`
  const response = await fetch(url, {
    method: 'POST',
    audience,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
  const data = await response.json()
  return data
}

export async function updateStudentAcademicPlan({ params, token }) {
  const url = `${baseUrl}/academic_progress_plans/${params.id}`
  const response = await fetch(url, {
    method: 'PATCH',
    audience,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({academic_progress_plan: params}),
  })
  const data = await response.json()
  return data
}

export async function destroyStudentAcademicPlan({ id, token }) {
  const url = `${baseUrl}/academic_progress_plans/${id}`
  const response = await fetch(url, {
    method: 'DELETE',
    audience,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  const data = await response.json()
  return data
}
