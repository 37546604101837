import React, { useState } from 'react'
import Badge from 'react-bootstrap/Badge'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
import MeetingModal from 'students/meeting-modal'

function SrptBadge({ student }) {
  const [showMeetingModal, setShowMeetingModal] = useState(false)

  return (
    <>
    <Badge
      variant='warning'
      pill
      className='text-secondary'
      style={{ background: '#fcf7e6', cursor: 'pointer', border: '1px solid #e2af0b' }}
      onClick={() => setShowMeetingModal(true)}
    >
      <FontAwesomeIcon
        icon={faExclamationTriangle}
        className="text-warning"
      />{' '}
      SRPT Needed
    </Badge>
    {showMeetingModal && (
      <MeetingModal
        showModal={showMeetingModal}
        handleClose={() => setShowMeetingModal(false)}
        student={student}
      />
    )}
    </>
  )
}

export default SrptBadge
