import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'

const getReportTestingWindow = async(token, params) => {
  const url = 'report_testing_windows'

  const data = await apiGet({ url, token, params })
  return data
}

export default function useReportTestingWindow(params) {
  const token = useContext(AuthContext)

  return useQuery(['report_testing_windows', params], () => getReportTestingWindow(token, params))
}
