import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Select, { components } from 'react-select'
import useStandards from 'api/hooks/use-standards'
import { titleize, mapObjectsToOptionsWithSubLabel } from 'helpers/utils'
import { useMutation, useQueryClient } from 'react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { audience } from 'api/helpers'
import { createDocument } from 'api/documents'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'

function UploadModal({ student, showModal, handleClose }) {
  const { getAccessTokenSilently } = useAuth0()
  const queryClient = useQueryClient()
  const { isSuccess, data } = useStandards()

  const standardsOptions =
    !!data &&
    mapObjectsToOptionsWithSubLabel(data, 'abbreviation', 'id', 'content')
  const [file, setFile] = useState('')
  const [category, setCategory] = useState('')
  const [year, setYear] = useState('')
  const [standards, setStandards] = useState([])
  const [filename, setFilename] = useState('')

  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        <h6 className="text-primary">
          {props.data.label}:{' '}
          <small className="text-secondary">
            <span className="text-primary">
              {titleize(props.data.category)}
            </span>
            {' - '}
            {props.data.subLabel}
          </small>
        </h6>
      </components.Option>
    )
  }

  const getFormData = () => {
    const data = new FormData()
    const name = filename === '' ? file.name : filename
    data.append('document[file]', file, name)
    data.append('document[filename]', name)
    data.append('document[student_id]', student.id)
    data.append('document[category]', category.value)
    data.append('document[year]', year.value)
    data.append(
      'document[standards]',
      standards.map((s) => s.value)
    )
    return data
  }

  const categoryOptions = [
    { value: 'academic_progress_plan', label: 'Academic Progress Plan' },
    { value: 'work_sample', label: 'Portfolio: Work Sample' },
    { value: 'exemption_letter', label: 'Exemption Letter' },
    { value: 'screening_assessment', label: 'Screening Assessment' },
    { value: 'other', label: 'Other' },
  ]

  /* TODO: Change to dynamic year dropdown */
  const yearOptions = [
    { value: 2022, label: '2021-2022' },
    { value: 2021, label: '2020-2021' },
    { value: 2020, label: '2019-2020' },
    { value: 2019, label: '2018-2019' },
    { value: 2018, label: '2017-2018' },
  ]

  const saveDocumentMutation = useMutation(
    (params, token) => createDocument(params, token),
    {
      onSuccess: () => {
        toast.success('Document saved')
        queryClient.invalidateQueries(`students/${student.id}/documents`)
      },
    }
  )

  const handleSubmit = async (e) => {
    const token = await getAccessTokenSilently({ audience })
    const data = getFormData()
    saveDocumentMutation.mutate({ params: data, token: token })
    clearFormData()
    handleClose()
  }

  const clearFormData = () => {
    setCategory('')
    setStandards([])
    setFile('')
    setFilename('')
    document.getElementById('file-name').value = ''
    document.getElementById('file-input').value = ''
  }

  const updateFile = (e) => {
    setFile(e.target.files[0])
  }

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Upload Document</Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body>
          {!!student && (
            <>
              <Form.Group>
                <Form.Label>Category</Form.Label>
                <Select
                  placeholder="Select Document Type..."
                  isClearable={true}
                  isSearchable={false}
                  options={categoryOptions}
                  value={category}
                  onChange={(selected) => setCategory(selected)}
                  name="status"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#efefef',
                      primary: '#3598D2',
                    },
                  })}
                />
              </Form.Group>
              <label>Attach File</label>
              <Form.Group>
                <input
                  name="file-input"
                  id="file-input"
                  type="file"
                  onChange={(e) => updateFile(e)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="text-muted">
                  Filename -{' '}
                  <span className="font-weight-light small">Optional</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Filename"
                  id="file-name"
                  value={filename}
                  onChange={(e) => setFilename(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  School Year -{' '}
                  <span className="font-weight-light small">Optional</span>
                </Form.Label>
                <Select
                  placeholder="Select School Year..."
                  isClearable={true}
                  isSearchable={false}
                  options={yearOptions}
                  value={year}
                  onChange={(selected) => setYear(selected)}
                  name="status"
                />
              </Form.Group>
              {isSuccess && category.value === 'work_sample' && (
                <Form.Group>
                  <label>Tag Standards</label>
                  <Select
                    isMulti
                    name="colors"
                    options={standardsOptions}
                    onChange={(selected) => setStandards(selected)}
                    components={{ Option: CustomOption }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#efefef',
                        primary: '#3598D2',
                      },
                    })}
                  />
                </Form.Group>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            <FontAwesomeIcon icon={faSave} /> Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default UploadModal
