import React, { useState } from 'react'
import Badge from 'react-bootstrap/Badge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import MeetingModal from 'students/meeting-modal'

function SrptCompleteBadge({ student }) {
  const [showMeetingModal, setShowMeetingModal] = useState(false)
  return (
    <>
      <Badge
        variant="light"
        pill
        className="text-secondary"
        style={{ cursor: 'pointer' }}
        onClick={() => setShowMeetingModal(true)}
      >
        <FontAwesomeIcon icon={faCheck} className="text-success" /> SRPT
        Complete
      </Badge>
      {showMeetingModal && (
        <MeetingModal
          showModal={showMeetingModal}
          handleClose={() => setShowMeetingModal(false)}
          student={student}
          oldMeeting={student.meetings[0]}
        />
      )}
    </>
  )
}

export default SrptCompleteBadge
