import { audience, baseUrl } from 'api/helpers'

export async function fetchStudentDocuments({studentId, token}) {
  const url = `${baseUrl}/students/${studentId}/documents`
  const response = await fetch(url, {
    audience,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const data = await response.json()
  return data
}

export async function createDocument({ params, token }) {
  const url = `${baseUrl}/students/${params.get(
    'document[student_id]'
  )}/documents`
  const response = await fetch(url, {
    method: 'POST',
    audience,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: params,
  })
  const data = await response.json()
  return data
}

export async function destroyDocument({ id, token }) {
  const url = `${baseUrl}/documents/${id}`
  const response = await fetch(url, {
    method: 'DELETE',
    audience,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  const data = await response.json()
  return data
}
