import { audience, baseUrl } from 'api/helpers'

export async function fetchAcademicPlanSupports({ plan, token }) {
  const url = `${baseUrl}/academic_progress_plans/${plan.id}/supports`
  const response = await fetch(url, {
    audience,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const data = await response.json()
  return data
}

export async function createAcademicPlanSupport({ params, plan, token }) {
  const url = `${baseUrl}/academic_progress_plans/${plan.id}/supports`
  const response = await fetch(url, {
    method: 'POST',
    audience,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({support: params}),
  })
  const data = await response.json()
  return data
}

export async function updateAcademicPlanSupport({ params, token }) {
  const url = `${baseUrl}/supports/${params.id}`
  const response = await fetch(url, {
    method: 'PATCH',
    audience,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: params,
  })
  const data = await response.json()
  return data
}

export async function destroyAcademicPlanSupport({ id, token }) {
  const url = `${baseUrl}/supports/${id}`
  const response = await fetch(url, {
    method: 'DELETE',
    audience,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  const data = await response.json()
  return data
}
