import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

function Dyslexia({ plan, setPlan, readOnly }) {

  return (
    <React.Fragment>
      <Row>
        <Col sm={3}>
          <h5>Dyslexia</h5>
          <p className="font-weight-light">
            The student took the following screening assessment for
            characteristics of dyslexia
          </p>
        </Col>
        <Col sm={9}>
          <Row>
            <Col>
              <Form.Check
                type="radio"
                label="Acadience and PAST"
                name="dyslexiaScreener"
                checked={plan.dyslexiaScreener === 'acadience'}
                disabled={readOnly}
                onChange={(e) =>
                  setPlan({
                    ...plan,
                    dyslexiaScreener: 'acadience',
                  })
                }
              />
            </Col>
            <Col>
              <Form.Check
                type="radio"
                label="IStation and PAST"
                name="dyslexiaScreener"
                checked={plan.dyslexiaScreener === 'istation'}
                disabled={readOnly}
                onChange={(e) =>
                  setPlan({
                    ...plan,
                    dyslexiaScreener: 'istation',
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {' '}
              <Form.Check
                type="radio"
                name="dyslexiaScreener"
                label="mClass DIBELS 8th Edition and PAST"
                checked={plan.dyslexiaScreener === 'mclass'}
                disabled={readOnly}
                onChange={(e) =>
                  setPlan({
                    ...plan,
                    dyslexiaScreener: 'mclass',
                  })
                }
              />
            </Col>
            <Col>
              {' '}
              <Form.Check
                type="radio"
                label="Amira"
                name="dyslexiaScreener"
                checked={plan.dyslexiaScreener === 'amira'}
                disabled={readOnly}
                onChange={(e) =>
                  setPlan({
                    ...plan,
                    dyslexiaScreener: 'amira',
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {' '}
              <Form.Check
                type="radio"
                label="MAP Reading Fluency"
                name="dyslexiaScreener"
                checked={plan.dyslexiaScreener === 'map'}
                disabled={readOnly}
                onChange={(e) =>
                  setPlan({
                    ...plan,
                    dyslexiaScreener: 'map',
                  })
                }
              />
            </Col>
            <Col>
              {' '}
              <Form.Check
                type="radio"
                label="STAR CBM"
                name="dyslexiaScreener"
                checked={plan.dyslexiaScreener === 'star'}
                disabled={readOnly}
                onChange={(e) =>
                  setPlan({
                    ...plan,
                    dyslexiaScreener: 'star',
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {' '}
              <Form.Check
                type="radio"
                label="FastBridge and PAST"
                name="dyslexiaScreener"
                checked={plan.dyslexiaScreener === 'fastbridge'}
                disabled={readOnly}
                onChange={(e) =>
                  setPlan({
                    ...plan,
                    dyslexiaScreener: 'fastbridge',
                  })
                }
              />
            </Col>
            <Col>
              {' '}
              <Form.Check
                type="radio"
                label="Scored above benchmark – screener not required"
                name="dyslexiaScreener"
                checked={plan.dyslexiaScreener === 'scored_above_benchmark'}
                disabled={readOnly}
                onChange={(e) =>
                  setPlan({
                    ...plan,
                    dyslexiaScreener: 'scored_above_benchmark',
                  })
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm={3}>
          <p className="font-weight-light">
            Results of Screening for Characteristics of Dyslexia
          </p>
        </Col>
        <Col sm={9}>
          Data indicates student is at-risk for characteristics of dyslexia at
          this time:
          <Row className="my-2">
            <Col>
              <Form.Check
                type="radio"
                label="At Risk"
                name="dyslexia_at_risk"
                checked={plan.dyslexiaAtRisk === 'at_risk'}
                disabled={readOnly}
                onChange={(e) =>
                  setPlan({
                    ...plan,
                    dyslexiaAtRisk: 'at_risk',
                  })
                }
              />
            </Col>
            <Col>
              <Form.Check
                type="radio"
                label="Not At Risk"
                name="dyslexia_at_risk"
                checked={plan.dyslexiaAtRisk === 'not_at_risk'}
                disabled={readOnly}
                onChange={(e) =>
                  setPlan({
                    ...plan,
                    dyslexiaAtRisk: 'not_at_risk',
                  })
                }
              />
            </Col>
          </Row>
          <p className="text-muted mt-2 small">
            This is a reminder that this screening is not a diagnosis of
            dyslexia. This data indicates the student needs instruction in word
            recognition skills (e.g. phonemic awareness and/or phonics/spelling)
            through the intervention process. This doees not mean the student is
            automatically eligible for or needs an individualized Education
            Program (IEP) or Section 504 plan under the individuals with
            Disabilities Education Act (IDEA)
          </p>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm={3}>
          <p className="font-weight-light">Outside Services</p>
        </Col>
        <Col sm={9}>
          <Form.Check
            type="checkbox"
            name="dyslexiaOutsideServices"
            label="The family has indicated that the student is receiving tutoring outside of school services to address characteristics of dyslexia."
            checked={plan.dyslexiaOutsideServices}
            disabled={readOnly}
            onChange={(e) =>
              setPlan({
                ...plan,
                dyslexiaOutsideServices: !plan.dyslexiaOutsideServices,
              })
            }
          />
          <p className="text-muted mt-2 small">
            Indicating this is for communication purposes only, and should not
            take the place of instructional supports or seervices provided by
            the school. Under federal law, all students are entitled to a free
            and appropriate public education.{' '}
            <a
              href="https://www.ecfr.gov/current/title-34/subtitle-B/chapter-III/part-300/subpart-B/subject-group-ECFR4c69ab8d340f516/section-300.101"
              target="_blank"
            >
              34 CFR § 300.101
            </a>
          </p>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Dyslexia
