import React, { useState } from 'react'
import Table from 'react-bootstrap/Table'
import { fetchDistrictUsers } from 'api/district-users'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from 'react-query'
import { audience } from 'api/helpers'
import LoadingStatus from 'helpers/loading-status'
import { titleize } from 'helpers/utils'
import TableFooter from 'shared/tables/footer'
import Badge from 'react-bootstrap/Badge'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSkull, faUnlink } from '@fortawesome/free-solid-svg-icons'
import { isNull } from 'lodash'
import NewUserModal from './new-user-modal'
import { Button } from 'react-bootstrap'
import { ButtonGroup } from 'react-bootstrap'
import Search from 'shared/forms/search'

function Users({ district }) {
  const { getAccessTokenSilently } = useAuth0()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(25)
  const [active, setActive] = useState('all')
  const [search, setSearch] = useState('')

  const params = {
    page: page,
    perPage: perPage,
    active: active,
    search: search,
  }

  const {
    isSuccess,
    isLoading,
    data: users,
  } = useQuery([`district/${district.id}/users`, params], async () => {
    const token = await getAccessTokenSilently({ audience })
    const data = await fetchDistrictUsers({
      districtId: district.id,
      token,
      params: params,
    })
    return data
  })

  return (
    <div className="mt-4">
      <Row className="mb-2">
        <Col>
          <Search search={search} handleChange={(e) => setSearch(e.target.value)} />
        </Col>
        <Col>
          
          <NewUserModal districtId={district.id} schools={district.schools} />

          <ButtonGroup className="float-right mr-2">
            <Button
              onClick={() => setActive('all')}
              variant={active === 'all' ? 'primary' : 'outline-primary'}
              size="sm"
            >
              All
            </Button>
            <Button
              onClick={() => setActive('active')}
              variant={active === 'active' ? 'primary' : 'outline-primary'}
              size="sm"
            >
              Active
            </Button>
            <Button
              onClick={() => setActive('inactive')}
              variant={active === 'inactive' ? 'primary' : 'outline-primary'}
              size="sm"
            >
              Inactive
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <Table bordered size="sm">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Students Count</th>
            <th>Role</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan="5">
                <LoadingStatus status={isLoading} />
              </td>
            </tr>
          )}
          {isSuccess && users.data.length === 0 && (
            <tr>
              <td className="text-center" colSpan="5">
                No Users Found
              </td>
            </tr>
          )}
          {isSuccess &&
            users.data.map((u) => (
              <tr key={`user-${u.id}`}>
                <td>
                  {u.first_name} {u.last_name}{' '}
                  <small className="text-muted ml-1">{u.id}</small>
                  {u.active ? (
                    <Badge
                      variant="white"
                      className={'text-success ml-1 bg-light'}
                      pill
                      style={{ background: '#ecf6f1' }}
                    >
                      Active
                    </Badge>
                  ) : (
                    <Badge
                      variant="white"
                      className={'text-secondary ml-1 bg-light'}
                      pill
                      style={{ background: '#f4f5f7' }}
                    >
                      Inactive
                    </Badge>
                  )}
                  {u.schools.length === 0 && (
                    <Badge
                      variant="white"
                      className={'text-danger ml-1'}
                      pill
                      style={{ background: '#fdecea', cursor: 'pointer' }}
                    >
                      <FontAwesomeIcon icon={faSkull} /> No Schools
                    </Badge>
                  )}
                  {u.role === 'teacher' && isNull(u.teacher) && (
                    <Badge
                      variant="white"
                      className={'text-danger ml-1'}
                      pill
                      style={{ background: '#fdecea', cursor: 'pointer' }}
                    >
                      <FontAwesomeIcon icon={faUnlink} /> Missing Teacher
                    </Badge>
                  )}
                  <div>
                    {u.grade_levels.length > 0 &&
                      u.grade_levels.map((g) => (
                        <Badge
                          variant="light"
                          className="text-info ml-1"
                          pill
                          key={`grade-level-${g.id}`}
                        >
                          {g.abbreviation}
                        </Badge>
                      ))}
                  </div>
                </td>
                <td>
                  <span className="text-muted font-weight-light">
                    {u.email}
                  </span>
                </td>
                <td className="text-muted font-weight-light">
                  {u.role === 'teacher' ? u.students_count : 'N/A'}
                </td>
                <td className="text-muted font-weight-light">
                  {titleize(u.role)}
                </td>
                <td>
                  <a
                    className="btn btn-sm btn-link"
                    href={`/users/${u.id}`}
                  >
                    View
                  </a>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {isSuccess && (
        <TableFooter
          data={users}
          perPage={perPage}
          setPerPage={setPerPage}
          page={page}
          setPage={setPage}
        />
      )}
    </div>
  )
}

export default Users
