import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import { isNull } from 'lodash'
import ApprovalModal from './approval-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faStamp, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

function ApprovalButton({ student }) {
  const [show, setShow] = useState(false)

  if (isNull(student.student_pathway)) {
    return (
      <OverlayTrigger
        key="top"
        placement="top"
        trigger={['hover', 'focus']}
        overlay={
          <Tooltip id={'tooltip'}>
            You must select a pathway before approving
          </Tooltip>
        }
      >
        <span
          className="font-weight-light text-muted"
          style={{ cursor: 'pointer' }}
        >
          <FontAwesomeIcon icon={faTimes} className="text-danger" /> No Decision
        </span>
      </OverlayTrigger>
    )
  } else if (student.student_pathway && student.approvals?.length === 0) {
    return (
      <>
        <Button data-testid='approve-button-trigger' variant="light" size="sm" onClick={() => setShow(true)}>
          <FontAwesomeIcon icon={faStamp} className="text-primary" /> Approve
        </Button>
        <ApprovalModal show={show} setShow={setShow} student={student} />
      </>
    )
  } else if (student.student_pathway && student.approvals?.length > 0) {
    return (
      <>
        <Button
          variant="light"
          size="sm"
          className="font-weight-light text-muted"
          onClick={() => setShow(true)}
        >
          <FontAwesomeIcon icon={faThumbsUp} className="text-success" />{' '}
          Approved
        </Button>
        <ApprovalModal
          show={show}
          setShow={setShow}
          student={student}
          oldApproval={student.approvals[0]}
        />
      </>
    )
  }
}

export default ApprovalButton
