import React from 'react'

function Title({ content, subcontent }) {
  return (
    <h4 className="mt-5 mb-3 text-info">
      {content}
      <small className="ml-2">{subcontent}</small>
    </h4>
  )
}

export default Title
