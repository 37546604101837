import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Badge from 'react-bootstrap/Badge'
import Tooltip from 'react-bootstrap/Tooltip'

function FirstExemption() {
  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="top"
      overlay={
        <Tooltip>
          Student hit exemption score for the first time. Send home the exemption letter.
        </Tooltip>
      }
    >
      <Badge
        pill
        variant="light"
        className="text-info"
        style={{ cursor: 'pointer' }}
      >
        🎉 Exemption Score
      </Badge>
    </OverlayTrigger>

  )
}

export default FirstExemption
