import React from 'react'
import Form from 'react-bootstrap/Form'

function SchoolYear({ year, setYear }) {
  return (
    <Form.Group className="mr-2">
      <Form.Control
        as="select"
        value={year}
        onChange={(e) => setYear(e.target.value)}
      >
        <option>Select Year</option>
        <option value={2022}>2021-2022</option>
        <option value={2023}>2022-2023</option>
        <option value={2024}>2023-2024</option>
      </Form.Control>
    </Form.Group>
  )
}

export default SchoolYear
