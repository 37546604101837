import React, { useState } from 'react'
import Page from 'containers/page'
import Title from 'helpers/title'
import Table from 'react-bootstrap/Table'
import { useQuery } from 'react-query'
import { fetchDistricts } from 'api/districts'
import { useAuth0 } from '@auth0/auth0-react'
import { audience } from 'api/helpers'
import TableFooter from 'shared/tables/footer'
import LoadingStatus from 'helpers/loading-status'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSearch,
  faCheckCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import Form from 'react-bootstrap/Form'

function Districts() {
  const { getAccessTokenSilently } = useAuth0()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(25)
  const [search, setSearch] = useState('')
  const [isCustomer, setIsCustomer] = useState(false)

  const params = {
    page: page,
    perPage: perPage,
    search: search,
    isCustomer: isCustomer,
  }

  const {
    isSuccess,
    isLoading,
    data: districts,
  } = useQuery(['districts', params], async () => {
    const token = await getAccessTokenSilently({ audience })
    const data = await fetchDistricts({ params, token })
    return data
  })

  return (
    <Page>
      <Title content="Districts" />
      <hr />
      <Row>
        <Col sm={4}>
          <Form.Group>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="District Name"
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col>
          <Form.Check
            type="checkbox"
            className="mt-2"
            onChange={(_) => setIsCustomer(!isCustomer)}
            label="OK RSA Customer"
          />
        </Col>
      </Row>

      <Table bordered className="shadow-sm">
        <thead>
          <tr>
            <th>Name</th>
            <th className="text-center">Schools</th>
            <th className="text-center">State ID</th>
            <th className="text-center">Customer</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={4}>
                <LoadingStatus status={true} />{' '}
              </td>
            </tr>
          )}
          {isSuccess &&
            districts.data.map((d) => (
              <tr>
                <td>
                  <a href={`/districts/${d.id}/settings`}>{d.name}</a>
                </td>
                <td className="text-center">{d.schools_count}</td>
                <td className="text-center">{d.state_id}</td>
                <td className="text-center">
                  {d.is_customer ? (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-success"
                    />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} className="text-danger" />
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {isSuccess && (
        <TableFooter
          data={districts}
          perPage={perPage}
          setPerPage={setPerPage}
          page={page}
          setPage={setPage}
        />
      )}
      <div className="my-5 py-5"></div>
    </Page>
  )
}

export default Districts
