import React from 'react'
import Table from 'react-bootstrap/Table'

function Schools({ district }) {
  return (
    <div className="mt-4">
      <Table bordered responsive size="sm">
        <thead>
          <tr>
            <th>Name</th>
            <th>State ID</th>
            <th>Students</th>
          </tr>
        </thead>
        <tbody>
          {district.schools.map((s) => (
            <tr>
              <td>{s.name}</td>
              <td>{s.state_id}</td>
              <td>{s.students_count}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default Schools
