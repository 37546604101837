import React, { useState, useEffect } from 'react'
import Districts from './admins/districts'
import Schools from './schools'
import School from './schools/show'
import Students from './students'
import Student from './students/show'
import Settings from './admins/districts/settings/index'
import User from './admins/districts/settings/users/show'
import TestingWindow from 'reports/testing-window'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import AppProviders from './context'
import { useQuery } from 'react-query'
import Pathways from 'documentation/pathways'
import { useAuth0 } from '@auth0/auth0-react'
import { authUser } from 'api/users'
import Page from 'containers/page'
import LoadingStatus from 'helpers/loading-status'
import { audience } from 'api/helpers'
import AcademicProgressPlan from 'students/academic-progress-plan/show'
import BeginningOfYear from 'reports/beginning-of-year'
import EndOfYear from 'reports/end-of-year'
import Promotion from 'reports/promotion'
import Approval from 'reports/approval'
import { isNull } from 'lodash'

function AuthenticatedApp() {
  const { getAccessTokenSilently, isLoading } = useAuth0()
  const [token, setToken] = useState(null)

  useEffect(() => {
    ;(async () => {
      const accessToken = await getAccessTokenSilently({ audience })
      setToken(accessToken)
    })()
  })

  const { user } = useAuth0()
  const {
    isLoading: isUserLoading,
    isSuccess,
    data: currentUser,
  } = useQuery([`user-${user.email}`], async () => {
    const token = await getAccessTokenSilently({ audience })
    const data = await authUser({ email: user.email, token })
    return data
  })

  if (isLoading || isNull(token) || isUserLoading) {
    return (
      <Page>
        <LoadingStatus status={isLoading} />
      </Page>
    )
  }

  if (isSuccess) {
    return (
      <AppProviders currentUser={currentUser} token={token}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/">
              {currentUser.role === 'school_admin' ? (
                <Redirect to={`/schools/${currentUser.defaultSchool.id}`} />
              ) : (
                <Redirect to={'/students'} />
              )}
            </Route>
            <Route path="/schools" exact={true} component={Schools} />
            <Route path="/districts" exact={true} component={Districts} />
            <Route path="/districts/:id/settings" component={Settings} />
            <Route path="/users/:userId" component={User} />
            <Route path="/schools/:id" component={School} />
            <Route path="/academic-plan/:id" component={AcademicProgressPlan} />
            <Route path="/students" exact={true} component={Students} />
            <Route path="/students/:id" component={Student} />
            <Route path="/pathways" exact={true} component={Pathways} />
            <Route
              path="/reports/beginning"
              exact={true}
              component={BeginningOfYear}
            />
            <Route path="/reports/end" exact={true} component={EndOfYear} />
            <Route
              path="/reports/promotion"
              exact={true}
              component={Promotion}
            />
            <Route path="/approval" exact={true} component={Approval} />
            <Route
              path="/reports/testing-window"
              exact={true}
              component={TestingWindow}
            />
          </Switch>
        </BrowserRouter>
      </AppProviders>
    )
  }
}

export default AuthenticatedApp
