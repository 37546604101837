export const hasDistrictLevelAccess = (role) => {
  return ['admin', 'district_admin'].includes(role)
}

export const hasSchoolLevelAccess = (role) => {
  return ['admin', 'district_admin', 'school_admin'].includes(role)
}

export const isSuperAdmin = (role) => {
  return ['admin'].includes(role)
}
