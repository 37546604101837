import React from 'react'
import Table from 'react-bootstrap/Table'
import { formatDateTime } from 'helpers/datetime'
import Badge from 'react-bootstrap/Badge'
import { titleize } from 'helpers/utils'
import Dropdown from 'react-bootstrap/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileDownload,
  faTrash,
  faFilePdf,
  faFile,
  faFileImage,
  faFileWord,
  faEllipsisV,
  faSort,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons'
import { baseUrl, audience } from 'api/helpers'
import { destroyDocument } from 'api/documents'
import { useMutation, useQueryClient, useQuery } from 'react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { fetchStudentDocuments } from 'api/documents'
import { toast } from 'react-toastify'
import LoadingStatus from 'helpers/loading-status'

function DocumentsTable({ student }) {
  const { getAccessTokenSilently } = useAuth0()
  const queryClient = useQueryClient()

  const { isSuccess, isLoading, data: documents } = useQuery(
    `students/${student.id}/documents`,
    async () => {
      const token = await getAccessTokenSilently({ audience })
      const data = await fetchStudentDocuments({ studentId: student.id, token })
      return data
    }
  )

  /* TODO: Move to students/:id/documents endpoint */

  const determineIcon = (filetype) => {
    if (filetype === 'image/png') {
      return faFileImage
    } else if (filetype === 'application/pdf') {
      return faFilePdf
    } else if (filetype === 'application/msword') {
      return faFileWord
    } else if (true) {
      return faFile // TODO: Add different types: faFileSignature, faFile
    }
  }

  const destroyDocumentMutation = useMutation(
    ({ id, token }) => destroyDocument({ id, token }),
    {
      onSuccess: () => {
        toast.info('Document deleted')
        queryClient.invalidateQueries(`students/${student.id}/documents`)
      },
    }
  )

  const deleteDocument = async (id) => {
    const token = await getAccessTokenSilently({ audience })
    destroyDocumentMutation.mutate({
      id: id,
      token: token,
    })
  }

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
      href="#"
      ref={ref}
      className="btn btn-light"
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </button>
  ))

  return (
    <Table className="border bg-white mb-5 shadow-sm" responsive>
      <thead>
        <tr>
          <th className="border-bottom-0 text-info"></th>
          <th className="border-bottom-0 text-info">
            Name{' '}
            <span className="float-right text-primary">
              <FontAwesomeIcon icon={faSortUp} />
            </span>
          </th>
          <th className="border-bottom-0 text-info">
            Category{' '}
            <span className="float-right text-muted">
              <FontAwesomeIcon icon={faSort} />
            </span>
          </th>
          <th className="border-bottom-0 text-info">
            Uploaded By{' '}
            <span className="float-right text-muted">
              <FontAwesomeIcon icon={faSort} />
            </span>
          </th>
          <th className="border-bottom-0 text-info">
            Date{' '}
            <span className="float-right text-muted">
              <FontAwesomeIcon icon={faSort} />
            </span>
          </th>
          <th className="border-bottom-0 text-info">Actions</th>
        </tr>
      </thead>
      <tbody className="mb-5">
        {isLoading && (
          <tr>
            <td colSpan="6">
              <LoadingStatus status={isLoading} />
            </td>
          </tr>
        )}

        {isSuccess && documents.length === 0 && (
          <tr>
            <td colSpan="6" className="text-center">
              This student does not have any documents uploaded
            </td>
          </tr>
        )}

        {isSuccess &&
          documents.map((documentObject) => (
            <tr key={`document-row-${documentObject.id}`}>
              <td className="text-center">
                <FontAwesomeIcon
                  icon={determineIcon(documentObject.filetype)}
                  className="text-info"
                />
              </td>
              <td>
                {titleize(documentObject.filename)}
                {!!documentObject.standards.length &&
                  documentObject.standards.map((s) => (
                    <Badge
                      pill
                      variant="light"
                      className="m-1"
                      key={`row-${documentObject}-standard-${s.id}`}
                    >
                      {s.abbreviation}
                    </Badge>
                  ))}
              </td>
              <td>{titleize(documentObject.category)}</td>
              <td>{documentObject.user}</td>
              <td>{formatDateTime(documentObject.created_at)}</td>
              <td>
                <Dropdown drop="left">
                  <Dropdown.Toggle
                    as={CustomToggle}
                    id="dropdown-custom-components"
                  >
                    <FontAwesomeIcon icon={faEllipsisV} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <a
                      href={`${baseUrl}/${documentObject.url}`}
                      className="dropdown-item"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faFileDownload} /> Download
                    </a>

                    <Dropdown.Item
                      style={{ cursor: 'pointer' }}
                      onClick={() => deleteDocument(documentObject.id)}
                    >
                      <FontAwesomeIcon icon={faTrash} /> Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  )
}

export default DocumentsTable
