import React from 'react'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import GradeLevelAccess from './grade-level-access'
import UserSchoolAction from './user-school-action'

function Schools({ params, schools = [] }) {
  return (
    <Form.Group>
      <Form.Label>Schools</Form.Label>
      <Table bordered size="sm">
        <thead>
          <tr>
            <th>Add/Remove</th>
            <th>School Name</th>
            <th>Grade Access</th>
          </tr>
        </thead>
        <tbody>
          {params.school_options.map((s) => (
            <tr key={`school-row-${s.id}`}>
              <td>
                <UserSchoolAction schoolId={s.id} userId={params.id} params={params} />
              </td>
              <td>{s.name}</td>
              <td>
                {params.grade_level_options.map((gl) => (
                  <GradeLevelAccess key={`school-${s.id}-grade-level-${gl.id}`} params={params} school={s} gradeLevel={gl} />
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Form.Group>
  )
}

export default Schools
