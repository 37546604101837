import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { isNull } from 'lodash'
import RsaStatusBadge from 'students/badges/rsa-status'
import { pathwayText } from 'helpers/pathway-text'
import UpdateModal from 'students/update-modal'
import { useQueryClient } from 'react-query'

function StatusBox({ student }) {
  const [hover, setHover] = useState(false)
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const queryClient = useQueryClient()

  const handleCloseUpdate = () => {
    queryClient.invalidateQueries(`students`)
    setShowUpdateModal(false)
  }

  return (
    <>
      <Card
        className={`shadow-sm ${hover ? 'border border-primary' : ''}`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => setShowUpdateModal(true)}
        style={{
          cursor: 'pointer',
          backgroundColor: hover ? '#eaf4fa' : '#FFF',
        }}
      >
        <Card.Body>
          <div className="mb-2">
            {' '}
            <strong className="text-info bold">Current RSA Status</strong>{' '}
            <span className="h5 ml-2">
              <RsaStatusBadge studentPathway={student.student_pathway} />
            </span>
            {hover && (
              <span className="float-right">
                <FontAwesomeIcon icon={faEdit} className="text-primary" />
              </span>
            )}
          </div>
          {!isNull(student.student_pathway) && (
            <>
              <div className="font-weight-light">
                Pathway {student.student_pathway.pathway_id}:{' '}
                {pathwayText(
                  student.student_pathway.pathway_id,
                  student.student_pathway.exemption_id
                )}
              </div>
            </>
          )}
        </Card.Body>
      </Card>

      {showUpdateModal && (
        <UpdateModal
          showModal={showUpdateModal}
          handleClose={handleCloseUpdate}
          student={student}
        />
      )}
    </>
  )
}

export default StatusBox
