import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'

const getStandards = async(token) => {
  const url = 'standards'

  const data = await apiGet({ url, token })
  return data
}

export default function useStandards() {
  const token = useContext(AuthContext)

  return useQuery('standards', () => getStandards(token))
}