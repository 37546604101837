import React, { useContext } from 'react'
import { fetchSchoolAcademicPlans } from 'api/school_academic_plans'
import { useQuery } from 'react-query'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import AcademicPlansTable from './table'
import AcademicPlanProgressBars from './progress-bars'
import { useAuth0 } from '@auth0/auth0-react'
import { audience } from 'api/helpers'
import LoadingStatus from 'helpers/loading-status'
import ErrorStatus from 'helpers/error-status'
import SchoolContext from 'context/school-context'

function SchoolAcademicPlan() {
  const { currentSchool } = useContext(SchoolContext)
  const schoolId = currentSchool.id
  const { getAccessTokenSilently } = useAuth0()
  const { isSuccess, isLoading, isError, data } = useQuery(
    [`school_academic_plans`, schoolId],
    async () => {
      const token = await getAccessTokenSilently({ audience })
      const data = await fetchSchoolAcademicPlans(schoolId, token)
      return data
    }
  )

  if (isLoading) {
    return <LoadingStatus status={isLoading} />
  }

  if (isError) {
    return <ErrorStatus errorMessage="Unable to load school data" />
  }

  if (isSuccess) {
    return (
      <div>
        <Row>
          <Col sm={6}>
            <AcademicPlanProgressBars isSuccess={isSuccess} data={data} />
          </Col>
          <Col sm={6}>
            <AcademicPlansTable isSuccess={isSuccess} data={data} />
          </Col>
        </Row>
      </div>
    )
  }
}

export default SchoolAcademicPlan
