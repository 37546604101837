import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Badge from 'react-bootstrap/Badge'
import Tooltip from 'react-bootstrap/Tooltip'

function EllBadge() {
  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="top"
      overlay={
        <Tooltip>
          Student may qualify for EL Proficiency good cause exemption
        </Tooltip>
      }
    >
      <Badge
        pill
        variant="light"
        className="text-info ml-1"
        style={{ cursor: 'pointer' }}
      >
        EL
      </Badge>
    </OverlayTrigger>
  )
}

export default EllBadge
