import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Pagination from 'react-bootstrap/Pagination'

function TableFooter({ data, perPage, setPerPage, page, setPage }) {
  return (
    <Row>
      <Col sm={2}>
        <Form inline>
          <Form.Group>
            <Form.Label className="text-right font-weight-light mr-2">
              Per Page
            </Form.Label>
            <Form.Control
              as="select"
              value={perPage}
              onChange={(e) => setPerPage(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </Form.Control>
          </Form.Group>
        </Form>
      </Col>
      <Col sm={2}></Col>
      <Col className="d-flex justify-content-center">
        <Pagination>
          <Pagination.First onClick={() => setPage(1)} disabled={page === 1} />
          <Pagination.Prev
            onClick={() => setPage(page - 1)}
            disabled={page === 1}
          />
          {page - 2 > 0 ? (
            <Pagination.Item onClick={() => setPage(page - 2)}>
              {page - 2}
            </Pagination.Item>
          ) : (
            ''
          )}
          {page - 1 > 0 ? (
            <Pagination.Item onClick={() => setPage(page - 1)}>
              {page - 1}
            </Pagination.Item>
          ) : (
            ''
          )}
          <Pagination.Item active>{page}</Pagination.Item>
          {page + 1 <= data.meta.pages ? (
            <Pagination.Item onClick={() => setPage(page + 1)}>
              {page + 1}
            </Pagination.Item>
          ) : (
            ''
          )}
          {page + 2 <= data.meta.pages ? (
            <Pagination.Item onClick={() => setPage(page + 2)}>
              {page + 2}
            </Pagination.Item>
          ) : (
            ''
          )}
          <Pagination.Next
            onClick={() => setPage(page + 1)}
            disabled={data.meta.pages <= page}
          />
          <Pagination.Last
            onClick={() => setPage(data.meta.pages)}
            disabled={data.meta.pages <= page}
          />
        </Pagination>
      </Col>
      <Col>
        <span className="float-right">
          Showing {(page - 1) * perPage + 1} to{' '}
          {data.meta.total < perPage ? data.meta.total : page * perPage} of{' '}
          {data.meta.total}
        </span>
      </Col>
    </Row>
  )
}

export default TableFooter
