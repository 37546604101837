import React from 'react'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"

function Search({ search, handleChange }) {
  return (
    <Form.Group className='mr-2'>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text id="basic-addon1">
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          type="text"
          value={search}
          onChange={(e) => handleChange(e)}
          placeholder="Student Name"
        />
      </InputGroup>
    </Form.Group>
  )
}

export default Search
