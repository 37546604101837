import { audience, baseUrl } from 'api/helpers'

export async function createMeeting({ params, token }) {
  const url = `${baseUrl}/students/${params.student_id}/meetings`
  const response = await fetch(url, {
    method: 'POST',
    audience,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({meeting: params}),
  })
  const data = await response.json()
  return data
}

export async function updateMeeting({ params, token }) {
  const url = `${baseUrl}/meetings/${params.id}`
  const response = await fetch(url, {
    method: 'PATCH',
    audience,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({meeting: params}),
  })
  const data = await response.json()
  return data
}

export async function destroyMeeting({ id, token }) {
  const url = `${baseUrl}/meetings/${id}`
  const response = await fetch(url, {
    method: 'DELETE',
    audience,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  const data = await response.json()
  return data
}