import React from 'react'

function LongPathway({ id, exemptionId }) {
  if (id === 1) {
    return (
      <>
        <strong className='mb-2'>Oklahoma State Testing Program (OSTP)</strong>{' '}
        <p className='font-weight-light text-muted'>
          Meet RSA criteria on the Oklahoma State Testing Program (OSTP) A copy
          of the student’s scores should be included in the permanent record.
        </p>
      </>
    )
  } else if (id === 2) {
    return (
      <>
        <strong className='mb-2'>Screening Assessments</strong> <p className='font-weight-light text-muted'>Meet the end-of-year
        grade-level target for third grade on one of the screening assessments
        approved by the Oklahoma State Board of Education and written
        notification has been provided to the student’s parent/guardian.
        Documentation of the name of the assessment and the date the target was
        reached may be included in the permanent record.</p>
      </>
    )
  } else if (id === 3) {
    if (exemptionId === 1) {
      return (
        <>
          <strong className='mb-2'>Good-cause exemption - EL Proficiency</strong> <p className='font-weight-light text-muted'>Student is an
          English learner who has had less than two years of instruction in
          English and is identified as Limited English Proficient/English
          learner on an approved screening tool. Student has an English Language
          Academic Plan (ELAP) in place.</p>
        </>
      )
    } else if (exemptionId === 2) {
      return (
        <>
          <strong className='mb-2'>Good-cause exemption - Alternate Assessment</strong> <p className='font-weight-light text-muted'>Student
          has an Individualized Education Plan (IEP) which indicates the student
          is to be assessed with the Oklahoma Alternate Assessment Program
          (OAAP).</p>
        </>
      )
    } else if (exemptionId === 3) {
      return (
        <>
          <strong className='mb-2'>
            Good-cause exemption - Alternative Standardized Test{' '}
          </strong>{' '}
          <p className='font-weight-light text-muted'>Student has demonstrated an acceptable level of performance (45th
          percentile or higher) on of the approved alternative standardized
          reading test (i.e, Iowa Test of Basic Skills, Stanford Achievement
          Test, Tenth Edition (SAT-10) or TerraNova) and documentation of test
          results are included in the permanent record.</p>
        </>
      )
    } else if (exemptionId === 4) {
      return (
        <>
          <strong className='mb-2'>Good-cause exemption - Portfolio</strong> <p className='font-weight-light text-muted'>Student has
          demonstrated reading sufficiency through a teacher-developed
          portfolio. Completed portfolio checklist is included in the student’s
          permanent record.</p>
        </>
      )
    } else if (exemptionId === 5) {
      return (
        <>
          <strong className='mb-2'>Good-cause exemption - IEP Goals</strong> <p className='font-weight-light text-muted'>Student
          participated in the OSTP, has an Individualized Education Plan (IEP)
          that reflects intensive remediation in reading, and has made adequate
          progress toward IEP goals.</p>
        </>
      )
    } else if (exemptionId === 6) {
      return (
        <>
          <strong className='mb-2'>Good-cause exemption - Previously Retained</strong> <p className='font-weight-light text-muted'>Student
          has received intensive remediation in reading for two or more years
          and has been previously retained for academic reasons.</p>
        </>
      )
    } else if (exemptionId === 7) {
      return (
        <>
          <strong className='mb-2'>Good-cause exemption - Exceptional Emergency</strong>{' '}
          <p className='font-weight-light text-muted'>Student is facing an exceptional emergency circumstance and exemption
          from testing has been approved by the Office of Assessment.
          Documentation is included in student’s permanent record.</p>
        </>
      )
    } else {
      return (
        <>
          <strong className='mb-2'>
            Good-cause exemption - Specific exemption not selected{' '}
          </strong>
        </>
      )
    }
  } else if (id === 4) {
    return (
      <>
        <strong className='mb-2'>Probationally Promote</strong> <p className='font-weight-light text-muted'>Unanimous decision by the
        Student Reading Proficiency Team (SRPT) to promote with probation.
        Documentation in student’s permanent record includes date of meeting,
        members of SRPT including the students third-grade teacher, fourth-grade
        teacher, parent/guardian, and reading specialist (if available).</p>
      </>
    )
  } else if (id === 5) {
    return (
      <>
        <strong className='mb-2'>Retain</strong> <p className='font-weight-light text-muted'>Under the rules of the Reading Sufficiency Act,
        the SRPT has determined this student will be retained in third grade,
        either through unanimous decision to retain or by not reaching a
        unanimous decision to promote. Written notification of decision to
        retain has been sent to the parent or guardian of the student.{' '}</p>
      </>
    )
  } else {
    return 'Not Exempt'
  }
}

export default LongPathway
