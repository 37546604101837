import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useCreateUser } from 'api/hooks/use-users'
import UserForm from './form'

function NewUserModal({ schools, districtId }) {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const emptyState = {
    email: '',
    auth0_user_id: '',
    first_name: '',
    last_name: '',
    position_title: '',
    district_id: districtId,
    role: '',
    active: false
  }

  const [params, setParams] = useState(emptyState)

  const {
    isSuccess: isMutationSuccess,
    mutate,
    data,
  } = useCreateUser({
    params: params
  })


    
  return (
    <React.Fragment>
      <Button
        variant="light"
        size="sm"
        className="float-right"
        onClick={handleShow}
      >
        <FontAwesomeIcon icon={faPlus} className="text-primary" /> Add User
      </Button>
      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UserForm formType="New" params={params} setParams={setParams} schools={schools} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => mutate()}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default NewUserModal
