import { audience, baseUrl } from 'api/helpers'

export const fetchReportBoy = async ({ token, params }) => {
  const url = `${baseUrl}/report_beginning_of_years?school_id=${params.school_id}`
  const response = await fetch(url, {
    audience,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const data = await response.json()
  return data
}
