import React from 'react'
import { Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import useStandards from 'api/hooks/use-standards'
import ErrorStatus from 'helpers/error-status'
import LoadingStatus from 'helpers/loading-status'
import { countBy } from 'lodash'

function PortfolioTable({ student }) {
  const { isLoading, isError, isSuccess, data: standards } = useStandards()

  const documentCountOfStandard = (standardId) => {
    const standards = student.documents
      .map((d) => d.standards && d.standards.map((s) => s.id))
      .flat()
    const countByStandardId = countBy(standards)
    const standardsCount = countByStandardId[standardId] || 0
    return standardsCount
  }

  const documentClassColor = (standardId) => {
    const count = documentCountOfStandard(standardId)
    return count > 3 ? 'text-success' : 'text-danger'
  }

  const tableRowIcon = (standardId) => {
    const count = documentCountOfStandard(standardId)
    return count > 3 ? faCheck : faTimes
  }

  if (isLoading) {
    return <LoadingStatus status={isLoading} />
  }

  if (isError) {
    return <ErrorStatus errorMessage="Unable to load school" />
  }

  return (
    <div>
      <Table responsive className="mt-2 bg-white border shadow-sm">
        <thead>
          <tr>
            <th className="text-info border-bottom-0">Standards</th>
            <th
              className="text-info border-bottom-0 text-center"
              style={{ minWidth: '200px' }}
            >
              Sample Count
            </th>
          </tr>
          <tr>
            <th className="text-info border-bottom-0" colSpan={2}>
              Vocabulary Standard 4
            </th>
          </tr>
        </thead>
        <tbody>
          {isSuccess &&
            standards
              .filter((i) => i.category === 'vocabulary')
              .map((standard) => (
                <tr key={`standard-row-${standard.id}`}>
                  <td>
                    <strong>{standard.abbreviation}:</strong> {standard.content}
                  </td>
                  <td
                    className={`text-center ${documentClassColor(standard.id)}`}
                  >
                    <FontAwesomeIcon icon={tableRowIcon(standard.id)} />{' '}
                    {documentCountOfStandard(standard.id)} / 4
                  </td>
                </tr>
              ))}
        </tbody>
        <thead>
          <tr>
            <th className="text-info border-bottom-0" colSpan={2}>
              Reading and Writing Process: Comprehension Standard 2
            </th>
          </tr>
        </thead>
        <tbody>
          {isSuccess &&
            standards
              .filter((i) => i.category === 'reading_and_comprehension')
              .map((standard) => (
                <tr key={`standard-row-${standard.id}`}>
                  <td>
                    <strong>{standard.abbreviation}:</strong> {standard.content}
                  </td>
                  <td
                    className={`text-center ${documentClassColor(standard.id)}`}
                  >
                    <FontAwesomeIcon icon={tableRowIcon(standard.id)} />{' '}
                    {documentCountOfStandard(standard.id)} / 4
                  </td>
                </tr>
              ))}
        </tbody>
      </Table>
    </div>
  )
}

export default PortfolioTable
