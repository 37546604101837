import React, { useState } from 'react'
import Powerschool from './powerschool'
import Wengage from './wengage'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'

function Sis() {
  const [sis, setSis] = useState('')

  return (
    <Card className="mt-4">
      <Card.Body>
        <Row>
          <Col sm={3}>
            <span className="float-right font-weight-bold">
              SIS Integration
            </span>
          </Col>
          <Col sm={9}>
            <Form.Group>
              <Form.Control
                as="select"
                onChange={(e) => setSis(e.target.value)}
              >
                <option value="">Select SIS</option>
                <option value="powerschool">PowerSchool</option>
                <option value="wengage">Wengage</option>
                <option value="infinite_campus" disabled>
                  Infinite Campus
                </option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={3}></Col>
          <Col sm={9}>
            {sis === 'powerschool' && <Powerschool />}
            {sis === 'wengage' && <Wengage />}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default Sis
