import React, { useState, useContext } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Datetime from 'react-datetime'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faEdit } from '@fortawesome/free-solid-svg-icons'
import {
  createAcademicPlanIntervention,
  updateAcademicPlanIntervention,
} from 'api/academic_plan_interventions'
import { fetchDistrictInterventions } from 'api/district-interventions'
import { useMutation, useQueryClient, useQuery } from 'react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { audience } from 'api/helpers'
import { isNull } from 'lodash'
import { toast } from 'react-toastify'
import { formatDateSlash } from 'helpers/datetime'
import UserContext from 'context/user-context'
import moment from 'moment'

function InterventionModal({ plan, oldIntervention, readOnly }) {
  const queryClient = useQueryClient()
  const { getAccessTokenSilently } = useAuth0()
  const currentUser = useContext(UserContext)
  const [validated, setValidated] = useState(false)
  const districtId = currentUser.district.id
  const emptyState = {
    id: null,
    date: moment(),
    intervention_id: null,
    interventionist: '',
    minutes: '',
    frequency: '',
  }

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [intervention, setIntervention] = useState(
    oldIntervention || emptyState
  )

  const { isSuccess, data: interventionOptions } = useQuery(
    `districts/${districtId}/interventions`,
    async () => {
      const token = await getAccessTokenSilently({ audience })
      const data = await fetchDistrictInterventions(districtId, token)
      return data
    }
  )

  const invalidateQueriesAndForm = (_) => {
    queryClient.invalidateQueries(`academic_progress_plans/${plan.id}`)
    queryClient.invalidateQueries(
      `academic_progress_plans/${plan.id}/interventions`
    )
    setValidated(false)
  }

  const newIntervention = useMutation(
    ({ params, plan, token }) =>
      createAcademicPlanIntervention({ params, plan, token }),
    {
      onSuccess: async () => {
        invalidateQueriesAndForm()
      },
    }
  )

  const updateIntervention = useMutation(
    ({ params, plan, token }) =>
      updateAcademicPlanIntervention({ params, token }),
    {
      onSuccess: async () => {
        invalidateQueriesAndForm()
      },
    }
  )

  const createNewIntervention = async () => {
    const token = await getAccessTokenSilently({ audience })

    newIntervention.mutate({ params: intervention, plan: plan, token })
    toast.success('Added intervention to academic plan')
  }

  const updateOldIntervention = async () => {
    const token = await getAccessTokenSilently({ audience })

    updateIntervention.mutate({ params: intervention, plan: plan, token })
    toast.warning('Updated intervention')
  }

  const handleSubmit = (e) => {
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    } else {
      e.preventDefault()
      if (isNull(intervention.id)) {
        createNewIntervention()
        setIntervention(emptyState)
      } else {
        updateOldIntervention()
      }
      handleClose()
    }
    setValidated(true)
  }

  return (
    <React.Fragment>
      {isNull(intervention.id) ? (
        <Button
          className="text-info"
          variant="light"
          size="sm"
          onClick={handleShow}
          disabled={readOnly}
        >
          <FontAwesomeIcon icon={faPlus} /> Add Intervention
        </Button>
      ) : (
        <Button
          className="text-warning mx-2"
          variant="light"
          size="sm"
          onClick={handleShow}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      )}
      <Modal show={show} onHide={handleClose} size="lg">
        <Form
          className="needs-validation"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Intervention</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mt-2">
              <Col sm={3}>
                <span className="float-right mt-1">Date</span>
              </Col>
              <Col sm={6}>
                <Datetime
                  placeholder="Date of Intervention"
                  dateFormat="MM/DD/YYYY"
                  timeFormat={false}
                  value={formatDateSlash(intervention.date)}
                  onChange={(e) =>
                    setIntervention({ ...intervention, date: e })
                  }
                  inputProps={{ disabled: true }}
                  required={true}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col sm={3}>
                <span className="float-right mt-1">Intervention</span>
              </Col>
              <Col sm={6}>
                {isSuccess && interventionOptions.length > 0 && (
                  <Form.Control
                    as="select"
                    required
                    value={intervention.intervention_id}
                    disabled={readOnly}
                    onChange={(e) =>
                      setIntervention({
                        ...intervention,
                        intervention_id: e.target.value,
                      })
                    }
                  >
                    <option value="">Select Intervention</option>
                    <>
                      <optgroup label="Tier 2">
                        {interventionOptions
                          .filter((i) => i.tier === 2)
                          .map((i) => (
                            <option
                              value={i.id}
                              key={`intervention-option-${i.id}`}
                            >
                              {i.name}
                            </option>
                          ))}
                      </optgroup>
                      <optgroup label="Tier 3">
                        {interventionOptions
                          .filter((i) => i.tier === 3)
                          .map((i) => (
                            <option
                              value={i.id}
                              key={`intervention-option-${i.id}`}
                            >
                              {i.name}
                            </option>
                          ))}
                      </optgroup>
                    </>
                  </Form.Control>
                )}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col sm={3}>
                <span className="float-right mt-1">Interventionist</span>
              </Col>
              <Col sm={6}>
                <Form.Control
                  type="text"
                  placeholder="Who?"
                  required
                  disabled={readOnly}
                  value={intervention.interventionist}
                  onChange={(e) =>
                    setIntervention({
                      ...intervention,
                      interventionist: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col sm={3}>
                <span className="float-right mt-1">Duration</span>
              </Col>
              <Col sm={6}>
                <InputGroup>
                  <Form.Control
                    type="number"
                    placeholder="# of Minutes"
                    required
                    value={intervention.minutes}
                    disabled={readOnly}
                    onChange={(e) =>
                      setIntervention({
                        ...intervention,
                        minutes: e.target.value,
                      })
                    }
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>mins</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col sm={3}>
                <span className="float-right mt-1">Frequency</span>
              </Col>
              <Col sm={6}>
                <InputGroup>
                  <Form.Control
                    type="number"
                    placeholder="x times"
                    disabled={readOnly}
                    required
                    value={intervention.frequency}
                    onChange={(e) =>
                      setIntervention({
                        ...intervention,
                        frequency: e.target.value,
                      })
                    }
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>per week</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant={readOnly ? 'light' : 'primary'}
              type="submit"
              disabled={readOnly}
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default InterventionModal
