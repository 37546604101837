import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardCheck, faClipboardList } from '@fortawesome/free-solid-svg-icons'
import { faClipboard } from '@fortawesome/free-regular-svg-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Badge from 'react-bootstrap/Badge'
import { isNull } from 'lodash'

function ProgressPlanIcon({ student }) {
  const studentExempt =
    student.student_pathway !== null && student.student_pathway.pathway_id !== 4
      ? true
      : false

  const calculateStatus = () => {
    const score = isNull(student.winter) ? student.fall : student.winter
    if (studentExempt) {
      return 'not needed'
    } else if (isNull(score)) {
      return 'no test'
    } else if (isNull(score) && isNull(student.current_academic_plan)) {
      return 'missing'
    } else if (score.score >= score.benchmark.score) {
      return 'not needed'
    } else if (isNull(student.current_academic_plan)) {
      return 'missing'
    } else if (student.current_academic_plan.complete) {
      return 'complete'
    } else {
      return 'in progress'
    }
  }

  const status = calculateStatus()

  if (status === 'not needed') {
    return (
      <OverlayTrigger
        key="top"
        placement="top"
        trigger={['hover', 'focus']}
        overlay={
          <Tooltip id={`tooltip`}>
            Does not need an APP due to exemption or screener score above the benchmark
          </Tooltip>
        }
      >
      <Badge
        pill
        className={`text-info lead`}
        variant="white"
        style={{ cursor: 'pointer', background: '#eaeaea' }}
      >
        <span>N/A</span>
      </Badge>
      </OverlayTrigger>
    )
  }

  if (status === 'missing') {
    return (
      <OverlayTrigger
        key="top"
        placement="top"
        trigger={['hover', 'focus']}
        overlay={
          <Tooltip id={`tooltip`}>Student scored below benchmark for the Fall or Winter testing window and no academic progress plan is on file for this year</Tooltip>
        }
      >
        <Badge
          pill
          className={`text-danger`}
          variant="white"
          style={{ background: '#fdecea', cursor: 'pointer'}}
        >
          <FontAwesomeIcon icon={faClipboard} />
          {' Missing'}
        </Badge>
      </OverlayTrigger>
    )
  }

  if (status === 'no test') {
    return (
      <OverlayTrigger
        key="top"
        placement="top"
        trigger={['hover', 'focus']}
        overlay={
          <Tooltip id={`tooltip`}>
            No test on file for fall or winter testing window
          </Tooltip>
        }
      >
      <Badge
        pill
        className={`text-info lead`}
        variant="white"
        style={{ cursor: 'pointer', background: '#eaeaea' }}
      >
        <span>Not Tested</span>
      </Badge>
      </OverlayTrigger>
    )
  }

  if (status === 'complete') {
    return (
      <OverlayTrigger
        key="top"
        placement="top"
        trigger={['hover', 'focus']}
        overlay={
          <Tooltip id={`tooltip`}>Academic progress plan is complete</Tooltip>
        }
      >
        <Badge
          pill
          className={`text-success`}
          variant="white"
          style={{ cursor: 'pointer', background: '#ecf6f1' }}
        >
          <FontAwesomeIcon icon={faClipboardCheck} />
          {' Complete'}
        </Badge>
      </OverlayTrigger>
    )
  }

  if (status === 'in progress') {
    return (
      <OverlayTrigger
        key="top"
        placement="top"
        trigger={['hover', 'focus']}
        overlay={
          <Tooltip id={`tooltip`}>
            Academic plan is created but not all sections are complete
          </Tooltip>
        }
      >
        <Badge
          pill
          className={`text-warning`}
          variant="white"
          style={{ cursor: 'pointer',  background: '#fcf7e6' }}
        >
          <FontAwesomeIcon icon={faClipboardList} />
          {' In Progress'}
        </Badge>
      </OverlayTrigger>
    )
  }
}

export default ProgressPlanIcon
