import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Page from 'containers/page'
import Title from 'helpers/title'
import { getUser } from 'api/users'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery, useQueryClient } from 'react-query'
import { audience } from 'api/helpers'
import { useEffect } from 'react'
import { useUpdateUser } from 'api/hooks/use-users'
import UserForm from './form'
import { toInteger, split } from 'lodash'
import { toast } from 'react-toastify'

function User() {
  const { getAccessTokenSilently } = useAuth0()
  const path = window.location.pathname
  const userId = toInteger(split(path, '/')[2])
  
  const queryClient = useQueryClient()
  const emptyState = {
    id: null,
    email: '',
    auth0_user_id: '',
    first_name: '',
    last_name: '',
    position_title: '',
    district_id: null,
    role: '',
    active: false,
    school_ids: [],
  }

  const {
    isSuccess,
    isLoading,
    data: user,
  } = useQuery(
    ['user', userId],
    async () => {
      const token = await getAccessTokenSilently({ audience })
      const data = await getUser({ id: userId, token })
      return data
    },
    { onSuccess: (d) => setParams(d) }
  )

  const [params, setParams] = useState(user || emptyState)
  useEffect(() => {
    setParams(user)
  }, [isSuccess])

  const { isSuccess: isMutationSuccess, mutate } = useUpdateUser({
    params: params,
  })

  useEffect(() => {
    if (isSuccess) {
      toast.success('User updated successfully')
      queryClient.invalidateQueries(`district/${user.district_id}/users`)
    }
  }, [isMutationSuccess])

  return (
    <Page>
      {isSuccess && (
        <>
          <Title content={`Edit ${user.first_name} ${user.last_name}`} />
          <UserForm
            formType="Edit"
            params={params}
            setParams={setParams}
            user={user}
          />
        </>
      )}
      <Button variant="primary" onClick={() => mutate()}>
        Save
      </Button>
    </Page>
  )
}

export default User
