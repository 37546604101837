import { audience, baseUrl } from 'api/helpers'

export async function getUser({ id, token }) {
  const url = `${baseUrl}/users/${id}`

  const response = await fetch(url, {
    method: 'GET',
    audience,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  const data = await response.json()
  return data
}

export async function authUser({ email, token }) {
  const params = { email: email }
  const url = `${baseUrl}/users/auth`

  const response = await fetch(url, {
    method: 'POST',
    audience,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
  const data = await response.json()
  return data
}

export async function updateUser({ params, token }) {
  const url = `${baseUrl}/users/${params.id}`
  const response = await fetch(url, {
    method: 'PATCH',
    audience,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ user: params }),
  })
  const data = await response.json()
  return data
}
