import React from 'react'
import Table from 'react-bootstrap/Table'
import { displayGradeLevel } from 'helpers/presenters'

function AcademicPlansTable({ isSuccess, data }) {
  return (
    <Table className="border bg-white">
      <thead>
        <tr>
          <th className="text-info border-bottom-0">Grade</th>
          <th className="text-center text-info border-bottom-0">Missing</th>
          <th className="text-center text-info border-bottom-0">In Progress</th>
          <th className="text-center text-info border-bottom-0">Completed</th>
          <th className="text-center text-info border-bottom-0">Total</th>
        </tr>
      </thead>
      <tbody>
        {isSuccess && data.map((d) => (
        <tr key={`academic-plans-grade-${d.grade_level}`}>
          <th>{displayGradeLevel(d.grade_level)}</th>
          <td className="text-center">
            {d.apps_needed - d.apps_complete - d.in_progress}
          </td>
          <td className="text-center">
            {d.in_progress}
          </td>
          <td className="text-center">
            {d.apps_complete}
          </td>
          <td className="text-center">
            {d.apps_needed}
          </td>
        </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default AcademicPlansTable
