import { audience, baseUrl } from 'api/helpers'

export const fetchMeetingRoles = async ({ token }) => {
  const url = `${baseUrl}/meeting_roles`
  const response = await fetch(url, {
    audience,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const data = await response.json()
  return data
}
