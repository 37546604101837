import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import UploadModal from 'students/upload-modal'
import UpdateModal from 'students/update-modal'
import MeetingModal from 'students/meeting-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button'
import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'
import { useMutation, useQueryClient } from 'react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { audience } from 'api/helpers'
import { createStudentAcademicPlan } from 'api/academic_progress_plans'
import { currentSchoolYear } from 'helpers/school-dates'
import { toast } from 'react-toastify'
import { fetchPdf } from 'api/pdf'
import { isNull } from 'lodash'

function DocumentActions({ student, size }) {
  let history = useHistory()
  const queryClient = useQueryClient()
  const { getAccessTokenSilently } = useAuth0()
  const [showDocumentModal, setShowDocumentModal] = useState(false)
  const [showActions, setShowActions] = useState(false)
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [showMeetingModal, setShowMeetingModal] = useState(false)

  const handleCloseDocument = () => setShowDocumentModal(false)

  const handleShowDocument = () => {
    setShowActions(false)
    setShowDocumentModal(true)
  }
  const handleShowUpdate = () => {
    setShowActions(false)
    setShowUpdateModal(true)
  }

  const handleCloseUpdate = () => {
    queryClient.invalidateQueries(`students`)
    setShowUpdateModal(false)
  }

  const handleCloseMeeting = () => {
    setShowMeetingModal(false)
  }

  const createNewPlan = async () => {
    const token = await getAccessTokenSilently({ audience })
    const params = { year: currentSchoolYear(), student_id: student.id }

    newAcademicPlanMutation.mutate({ params, token })
    toast.success(
      <span>
        Academic Plan created!{' '}
        <a href={`/students/${student.id}#academic-progress-plan`}>
          Click here to view
        </a>
      </span>,
      {
        pauseOnHover: true,
      }
    )
    setShowActions(false)
  }

  const newAcademicPlanMutation = useMutation(
    ({ params, token }) => createStudentAcademicPlan({ params, token }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          `students/${student.id}/academic_progress_plans`
        )
        queryClient.invalidateQueries('students')
      },
    }
  )

  const downloadExemptionPdf = async () => {
    setShowActions(false)
    const token = await getAccessTokenSilently({ audience })
    const data = await fetchPdf({
      student,
      token,
      documentType: 'proficiency_notice',
    })
    return data
  }

  const redirectToAppPage = () => {
    return history.push(`/students/${student.id}#academic-progress-plan`)
  }

  const target = useRef(null)

  return (
    <>
      <Button
        className="btn btn-light text-info ml-2"
        size={size}
        data-toggle="dropdown"
        ref={target}
        onClick={() => setShowActions(!showActions)}
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span className="text-center text-primary">
          <FontAwesomeIcon icon={faPlus} />
        </span>{' '}
        <strong>Actions</strong>
      </Button>

      <Overlay
        target={target.current}
        show={showActions}
        placement="bottom-end"
      >
        <Popover
          id="popover-basic"
          style={{ maxHeight: 400, overflowY: 'auto' }}
        >
          <Dropdown.Header>Actions</Dropdown.Header>
          <Dropdown.Item onClick={handleShowUpdate}>
            Update Status
          </Dropdown.Item>
          <Dropdown.Item onClick={handleShowDocument}>
            Upload Document
          </Dropdown.Item>
          {isNull(student.current_academic_plan) ? (
            <Dropdown.Item onClick={() => createNewPlan()}>
              Create APP/IPRI
            </Dropdown.Item>
          ) : (
            <Dropdown.Item onClick={() => redirectToAppPage()}>
              View APP/IPRI
            </Dropdown.Item>
          )}
          {student.grade_level >= 3 && student.meetings.length === 0 ? (
            <Dropdown.Item
              onClick={() => {
                setShowActions(false)
                setShowMeetingModal(true)
              }}
            >
              Probationary Promotion (SRPT)
            </Dropdown.Item>
          ) : null}
          <Dropdown.Header>Generate Documents</Dropdown.Header>
          {student.is_exempt ? (
            <Dropdown.Item onClick={() => downloadExemptionPdf()}>
              Exemption Letter
            </Dropdown.Item>
          ) : (
            <Dropdown.Item disabled>
              Exemption Letter (not exempt)
            </Dropdown.Item>
          )}
        </Popover>
      </Overlay>

      {showDocumentModal && (
        <UploadModal
          showModal={showDocumentModal}
          handleClose={handleCloseDocument}
          student={student}
        />
      )}
      {showUpdateModal && (
        <UpdateModal
          showModal={showUpdateModal}
          handleClose={handleCloseUpdate}
          student={student}
        />
      )}

      {showMeetingModal && (
        <MeetingModal
          showModal={showMeetingModal}
          handleClose={handleCloseMeeting}
          student={student}
        />
      )}
    </>
  )
}

export default DocumentActions
