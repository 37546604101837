import React from 'react'
import DocumentsTable from './table'
import Portfolio from './portfolio'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function Documents({ student }) {
  return (
    <div>
      <Row>
        <Col md={6}>
          <Portfolio student={student} />
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>

      <DocumentsTable student={student} />
      <div className="py-5 my-5"></div>
    </div>
  )
}

export default Documents
