import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './custom.scss'
import { Auth0Provider } from '@auth0/auth0-react'
import { createBrowserHistory } from 'history';
import { audience } from 'api/helpers'

export const history = createBrowserHistory();

const onRedirectCallback = (appState) => {
  // Use the router's history module to replace the url
  history.replace(appState?.returnTo || window.location.pathname);
};

ReactDOM.render(
  <Auth0Provider
    domain="oklahomarsa.us.auth0.com"
    clientId="QlUHZ8j7kf7kR1nX02t9KTM8E68TARBM"
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    audience={audience}
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
